import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import jwt from 'jsonwebtoken';

const CHANGE = 'TOKEN_CHANGE';
let _tokenState = {};

class TokenStore extends EventEmitter {
  constructor() {
    super();
    Dispatcher.register(this._registerToActions.bind(this));
    if (localStorage.getItem('jwtToken')) {
      _tokenState = localStorage.getItem('jwtToken');
      if (!this.isAuthenticated()) {
        _tokenState = {};
      }
    }
  }

  _registerToActions(action) {
    switch (action.actionType) {
      case ActionTypes.UPDATE_TOKEN:
        this._updateToken(action.payload);
        break;
      case ActionTypes.CLEAR_TOKEN:
        this._clearToken();
        break;
      case ActionTypes.CHECK_TOKEN:
        this._checkToken();
        break;
      default: //Nothing to do..
        break;
    }
  }

  _updateToken(newToken) {
    _tokenState = newToken;
    localStorage.setItem('jwtToken', newToken);
    this.emit(CHANGE);
  }

  _clearToken() {
    _tokenState = {};
    localStorage.setItem('jwtToken', null);
    this.emit(CHANGE);
  }

  _checkToken() {
    // If the token is invalid, set the jwtToken to null and emit change
    if(!this.isAuthenticated()){
      alert("You have been logged out. Please login again.");
      localStorage.setItem('jwtToken', null);
      this.emit(CHANGE);
    }
  }

  getToken() {
    return _tokenState;
  }

  getUser() {
    try {
      var decodedToken = jwt.decode(_tokenState, { complete: true });
      return decodedToken.payload.sub;
    } catch (err) {
      return null;
    }
  }

  isAuthenticated() {
    try {
      var decodedToken = jwt.decode(_tokenState, { complete: true });
      var dateNow = Math.floor(new Date().getTime() / 1000);
      if (dateNow < decodedToken.payload.exp) {
        return true;
      }
    } catch (err) {
      return false;
    }
    return false;
  }

  addChangeListener(callback) {
    this.on(CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE, callback);
  }
}

export default new TokenStore();
