import React, { Component } from 'react';
import { Button, Col, Container, Row, Form, FormGroup, FormControl } from 'react-bootstrap';
import { ObservationReportViewer } from '../observations/ObservationReportViewer';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Action from '../../actions/action';
import Actions from '../../actions/action';
import Store from '../../stores/store';
import moment from 'moment';

export class ArchivedObservations extends Component{
    displayName = ArchivedObservations.name;
    constructor (props){
        super(props);

        this.state = {
            displayObservationViewer: true,
            parameterGroups: {},
            observationType: '',
            observation: null,
            previousReports: [],
            reports: [],
            validReport: false,
            locations: [],
            location: null,
            selectedValue: '',
            reportType: '',
            selectedStartDate: moment().startOf('day').toDate(),
            selectedEndDate: moment().endOf('day').toDate(),
        }

        this.onDataChange = this.onDataChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSelectChangeReportType = this.handleSelectChangeReportType.bind(this);
        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

    }

    componentWillMount() {
        Store.addChangeListener(this.onDataChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onDataChange);
    }

    componentDidMount() {
        const start = this.state.selectedStartDate;
        const end = this.state.selectedEndDate;
        if (start && end ){
          Action.getReports(start,end);
        }
        let archived = true;
        Action.getLocations(archived);
    }

    handleSelectChange(event) {
        const value = event.target.value;
        Actions.changeArchiveLocation(value);
        this.setState({selectedValue: value});
    }

    handleStartDateChange(event){
        const value = event;
        Action.getReports(value, this.state.selectedEndDate);
        this.setState({selectedStartDate: value});
    }

    handleEndDateChange(event){
      const value = event;
      Action.getReports(this.state.selectedStartDate, value);
      this.setState({selectedEndDate: value});
    }

    handleSelectChangeReportType(event){
      const value = event.target.value;
      this.setState({reportType: value});
    }
    
    onDataChange() {
        this.setState({
            displayObservationViewer: Store.getViewerState(),
            observation: Store.getObservation(),
            failingChecks: Store.getAnyFailingChecks(),
            failingCheckList: Store.getListOfFailingChecks(),
            observationType: Store.getObservationType(),
            parameterGroups: Store.getParameterGroups(),
            previousReports: Store.getPreviousReports(),
            previousObservations: Store.getPreviousObservations(),
            reports: Store.getReports(),
            validReport: Store.getValidReport(),
            locations: Store.getLocations(),
            location: Store.getArchiveLocation()
        })
    }
    
        render() {

            let reports = this.state.previousReports;
            let reportType = this.state.reportType;
            let selectedStartDate = this.state.selectedStartDate;
            let selectedEndDate = this.state.selectedEndDate;

            const filteredReports = reports.filter(report => {
                let locationMatch = report.locationId === this.state.selectedValue;
                let typeMatch = (report.type).toLowerCase() === reportType;

                let startDateMatch = true;
                if (selectedStartDate) {
                    let reportDate = new Date(report.dateTimeUtc);
                    startDateMatch = reportDate >= selectedStartDate;
                }

                let endDateMatch = true;
                if (selectedEndDate) {
                    let reportDate = new Date(report.dateTimeUtc);
                    endDateMatch = reportDate <= selectedEndDate;
                }

                return locationMatch && typeMatch && startDateMatch && endDateMatch;
            });

            const handleExportClick = () => {
              const content = filteredReports.map(report => report.report).join('\n');
        
              const blob = new Blob([content], { type: 'text/plain' });

              const url = window.URL.createObjectURL(blob);
        
              const a = document.createElement('a');
              a.href = url;
              a.download = `${this.state.selectedValue}`;
        
              document.body.appendChild(a);
              a.click();
        
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            };
            return (
                <div>
                  <Container fluid>
                    <Row>
                      <Col xs={12} md={12}>
                        <h1>Archived Observations</h1>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={3}>
                        <FormGroup className="locationSelection">
                          <Form.Text> Select a Location </Form.Text>
                          <Form.Control
                            name="location"
                            as="select"
                            onChange={this.handleSelectChange}
                            value={this.state.selectedValue}
                          >
                            <option key="" value="">
                              Select a Location
                            </option>
                            {this.state.locations.map((location) => (
                              <option key={location.id} value={location.id}>
                                {location.name}
                              </option>
                            ))}
                          </Form.Control>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup>
                            <Form.Text>Report Types</Form.Text>
                            <Form.Control
                                name="reportType"
                                as="select"
                                onChange={this.handleSelectChangeReportType}
                                value={this.state.reportType}
                            >
                                <option
                                    key=''
                                    value=''
                                >
                                    Select an observation type
                        </option>
                                <option
                                    key='metar'
                                    value='metar'
                                >
                                    METAR / SPECI
                        </option>
                                <option
                                    key='manmar'
                                    value='manmar'
                                >
                                    MANMAR
                                </option>

                                <option
                                    key='manice'
                                    value='manice'
                                >
                                    MANICE
                                </option>
                            </Form.Control>
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
                        <FormGroup>
                          <Form.Text> Start Date </Form.Text>
                          <DatePicker
                            selected={this.state.selectedStartDate}
                            onChange={this.handleStartDateChange}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select a Date"
                            utc
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
                        <FormGroup>
                          <Form.Text> End Date </Form.Text>
                          <DatePicker
                            selected={this.state.selectedEndDate}
                            onChange={this.handleEndDateChange}
                            //defaultValue={}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select a Date"
                            utc
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={2} className="d-flex align-items-center justify-content-center">
                              <Button onClick={handleExportClick}>Export</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={11}>
                        {filteredReports.map((report) => (
                          <div key={report.observationId}>
                            <p>{report.report}</p>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Container>
                </div>
              );
         }

}
