const metarParameters = [
    'twoMinuteMeanWindSpeed',
    'twoMinuteMeanWindDirection',
    'twoMinuteGustSpeed',
    'windEstimation',
    'cloudHeightAmt',
    'cloudTypeAmt',
    'metarWeather',
    'cloudTypeLevel1',
    'cloudAmountLevel1',
    'cloudHeightLevel1',
    'cloudOpacityLevel1',
    'cloudTypeLevel2',
    'cloudAmountLevel2',
    'cloudHeightLevel2',
    'cloudOpacityLevel2',
    'cloudTypeLevel3',
    'cloudAmountLevel3',
    'cloudHeightLevel3',
    'cloudOpacityLevel3',
    'cloudTypeLevel4',
    'cloudAmountLevel4',
    'cloudHeightLevel4',
    'cloudOpacityLevel4',
    'observerRemarks',
    'airTemperature',
    'airTemperatureTwelveHoursAgo',
    'wetbulbTemperature',
    'dewpointTemperature',
    'relativeHumidity',
    'waterTemperature',
    'visibility',
    'stationPressure',
    'meanSeaLevelPressure',
    'altimeter',
    'significantWaveHeight',
    'maximumWaveHeight',
    'meanPeriod',
    'twoMinuteVariableWindDirection',
    'blank_metar',
    'additionalRemarks'
];

const manmarParameters = [
    'tenMinuteMeanWindSpeed',
    'tenMinuteMeanWindDirection',
    'windEstimation',
    'manmarPresentWeather',
    'manmarPastWeatherCodeOne',
    'manmarPastWeatherCodeTwo',
    'cloudTypeLevel1',
    'cloudAmountLevel1',
    'cloudHeightLevel1',
    'cloudOpacityLevel1',
    'cloudTypeLevel2',
    'cloudAmountLevel2',
    'cloudHeightLevel2',
    'cloudOpacityLevel2',
    'cloudTypeLevel3',
    'cloudAmountLevel3',
    'cloudHeightLevel3',
    'cloudOpacityLevel3',
    'cloudTypeLevel4',
    'cloudAmountLevel4',
    'cloudHeightLevel4',
    'cloudOpacityLevel4',
    'totalCloudCover',
    'lowCloudType',
    'mediumCloudType',
    'highCloudType',
    'airTemperature',
    'airTemperatureTwelveHoursAgo',
    'dewpointTemperatureSign',
    'dewpointTemperature',
    'relativeHumidity',
    'wetbulbTemperatureSign',
    'wetbulbTemperature',
    'waterTemperature',
    'waterTemperatureSign',
    'cloudCover',
    'baseLowestCloud',
    'visibility',
    'stationPressure',
    'meanSeaLevelPressure',
    'threeHourPressureChange',
    'threeHourPressureCharacter',
    'windWavePeriod',
    'windWaveHeight',
    'significantWaveHeight',
    'maximumWaveHeight',
    'meanPeriod',
    'primarySwellDirection',
    'primarySwellPeriod',
    'primarySwellHeight',
    'secondarySwellDirection',
    'secondarySwellPeriod',
    'secondarySwellHeight',
    'iceAccretionType',
    'iceAccretionThickness',
    'iceAccretionRate',
    'seaIceConcentration',
    'seaIceStageOfDevelopment',
    'seaIceOrigin',
    'seaIceBearing',
    'seaIceThreeHourSituation',
    'blank_manmar',
    'additionalRemarks'
]

const maniceParameters = [
    'nationality',
    'source',
    'callsign',
    'platformIdentifier',
    'platformType',
    'consecutiveIcebergNumber',
    'dayOfMonth',
    'monthOfYear',
    'lastDigitOfYear',
    'callsignMeta',
    'icebergNumber',
    'icebergMobility',
    'icebergObservation',
    'timeInHoursGG',
    'timeInHoursgg',
    'icebergLatitude',
    'icebergLongitude',
    'concentration',
    'icebergSize',
    'icebergShape',
    'icebergLength',
    'length',
    'icebergHeight',
    'height',
    'icebergWidth',
    'width',
    'icebergDraft',
    'draft',
    'icebergDirection',
    'direction',
    'icebergSpeed',
    'speed',
    'icebergs',
    'maniceRemarks'
];

export default {
    metar: metarParameters,
    speci: metarParameters,
    manmar: manmarParameters,
    metarAndManmar: [...metarParameters, ...manmarParameters],
    manice: maniceParameters
}