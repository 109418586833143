import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';

class TokenActions {
  updateToken(token) {
    Dispatcher.dispatch({
      actionType: ActionTypes.UPDATE_TOKEN,
      payload: token
    });
  }

  clearToken() {
    Dispatcher.dispatch({
      actionType: ActionTypes.CLEAR_TOKEN
    });
  }

  checkToken() {
    Dispatcher.dispatch({
      actionType: ActionTypes.CHECK_TOKEN
    });
  }
}

export default new TokenActions();
