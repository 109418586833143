import React, { Component } from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Button, Form, FormGroup, FormControl, FormText, Modal, ModalDialog } from 'react-bootstrap';
import ManmarEncoderDecoder from '../../util/manmarEncoder';
import MetarEncoderDecoder from '../../util/metarEncoderDecoder';
import './ObservationReportEditor.css';
import Actions from '../../actions/action';
import TokenAction from '../../actions/tokenAction';
import observationParameters from '../../constants/observationParameters';

export class ObservationReportEditor extends Component {
    displayName = ObservationReportEditor.name;

    handleShow = () => {
        this.setState({ showModal: true });
      };
      
      handleClose = () => {
        this.setState({ showModal: false });
      };
          
    constructor(props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickSend = this.onClickSend.bind(this);
        ;

        this.state = {
            showModal: false,
            email: '',
            textareaValue: '',
          };
    }

    onTextChange(event) {
        const index = event.target.name;
        const value = event.target.value;
        const arrayCopy = [...this.props.reports];
        arrayCopy[index] = value;
        Actions.updateReport(
            arrayCopy
        );
    }

    onClickSend() {
        TokenAction.checkToken();
        const {email, textareaValue} = this.state;
        Actions.sendReports(this.props.reports, this.props.location, this.props.observation, email, textareaValue);
        Actions.switchObservationView();
        Actions.clearObservation();
    }

    onClickCancel() {
        Actions.switchObservationView();
        Actions.clearObservation();
    }

    render() {
        const reportTimeInPast = this.props.observation.dateTime ?
            moment.utc(this.props.observation.dateTime).isBefore(moment.utc()) :
            false;
        return (
            <div>
                <h1>Current Observations</h1>
                <Form>
                    {
                        this.props.reports.length === 0 &&
                            <div>No reports to edit. Select a datetime, and then select report type to generate a report.</div>
                    }
                    {
                        this.props.reports.map((report, index) => 
                            <FormGroup key={index}>
                                <FormControl
                                    onChange={this.onTextChange}
                                    name={index}
                                    as="textarea"
                                    rows={3}
                                    value={report}
                                    style={{ height: 200}}
                                />
                            </FormGroup>
                        )
                    }
                    {
                        reportTimeInPast === false && this.props.reports.length !== 0 && 
                            <div class = "Warning_Time">Cannot send future report.</div>
                    }
                    {
                        this.props.validReport === false && this.props.reports.length !== 0 &&
                            <div class = "Warning_Report">Cannot send invalid report.</div>   
                    }
                    {
                        this.props.failingChecks === true && this.props.reports.length !== 0 &&
                            <div class = "Warning_Checks">Cannot send reports due to failing checks.
                                <div>{   
                                    this.props.failingCheckList.map((key) =>
                                        <div>
                                            {observationParameters[key].name} check is failing.
                                        </div>
                                    )}
                                </div>
                            </div>   
                    }
                    <Button className="report-editor__button" onClick={this.onClickSend} disabled={!reportTimeInPast || !this.props.validReport|| this.props.failingChecks}>
                        Send Reports
                    </Button>
                    <Button className="report-editor__button" onClick={this.onClickCancel}>
                        Cancel
                    </Button>
                    <Button className="report-editor__button" onClick={this.handleShow} disabled={!reportTimeInPast}>
                        Force Send
                    </Button>
                    <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Force Send request</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Observer email address</Form.Label>
                            <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicTextarea">
                            <Form.Label>Reason</Form.Label>
                            <Form.Control
                            as="textarea"
                            rows={3}
                            value={this.state.textareaValue}
                            onChange={(e) => this.setState({ textareaValue: e.target.value })}
                            />
                        </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={this.onClickSend}>
                        Send
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </Form>
            </div>
            )
    }
}

