export default {
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    CLEAR_TOKEN: 'CLEAR_TOKEN',
    CHANGE_OBSERVATION_TYPE: 'CHANGE_OBSERVATION_TYPE',
    SWITCH_OBSERVATION_VIEW: 'SWITCH_OBSERVATION_VIEW',
    SEND_REPORTS: 'SEND_REPORTS',
    UPDATE_OBSERVATION: 'UPDATE_OBSERVATION',
    UPDATE_REPORTS: 'UPDATE_REPORTS',
    UPDATE_PREVIOUS_REPORTS: 'UPDATE_PREVIOUS_REPORTS',
    UPDATE_CHECKS: 'UPDATE_CHECKS',
    CLEAR_OBSERVATION: 'CLEAR_OBSERVATION',
    RETRIEVED_LOCATIONS: 'RETRIEVED_LOCATIONS',
    LOCATION_POSTED: 'LOCATION_POSTED',
    LOCATION_UPDATED: 'LOCATION_UPDATED',
    CHANGE_LOCATION: 'CHANGE_LOCATION',
    CHANGE_ARCHIVE_LOCATION: 'CHANGE_ARCHIVE_LOCATION',
    LOCATION_CLEAR: 'LOCATION_CLEAR',
    CHANGE_LOCATION_VIEW: 'CHANGE_LOCATION_VIEW'
};
