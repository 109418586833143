import React, { Component } from 'react';
import { Alert, Form, FormGroup, FormControl, Col, Button, Row } from 'react-bootstrap';
import './Login.css';
import queryString from 'query-string';
import TokenStore from '../../stores/tokenStore';


export class ResetPassword extends Component {
    displayName = ResetPassword.name;

    constructor(props) {
        super(props);

        const values = queryString.parse(this.props.location.search);
        const userEmail = this.checkAuthenticated() === true? this.getUserEmail() : values.email;
        const token = values.token;

        this.checkAuthenticated = this.checkAuthenticated.bind(this);
        this.attemptChangePassword = this.attemptChangePassword.bind(this);
        this.onCurrentPasswordChange = this.onCurrentPasswordChange.bind(this);
        this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
        this.onConfirmNewPasswordChange = this.onConfirmNewPasswordChange.bind(this);

        this.state = {
            displayErrorMessage: '',
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            passwordChanged: false,
            userEmail: userEmail,
            token: token,
            inputErrors: [],
            isAuthenticated: this.checkAuthenticated()
        };

        this.validationFunctions = [
            () => {

                if (this.state.isAuthenticated !== true) {
                    return '';
                }
                else {
                    return !this.state.currentPassword ? 'Current password not provided. Please input current password.' : '';
                }
            },
            () => {

                if (this.state.isAuthenticated !== true) {
                    return '';
                }
                else {
                    return (this.state.currentPassword === this.state.newPassword) ? 'New password cannot be the same as current password. Please enter a different new password.' : '';
                }
            },
            () => {
                return !this.state.newPassword ? 'New password not provided. Please input new password.' : '';
            },
            () => {
                return !this.state.confirmNewPassword ? 'New password unconfirmed. Please confirm new password.' : '';
            },
            () => {
                return !(this.state.newPassword === this.state.confirmNewPassword) ? 'Passwords do not match. Please re-enter new and confirm new passwords.' : '';
            },
            () => {
                return !(this.state.newPassword.length >= 8) ? 'New password must be 8 or more characters long. Please enter a longer password.' : '';
            },
            () => {
                return !(this.state.newPassword.search(/\d/) !== -1) ? 'New password must contain a number. Please add a number to your password.' : '';
            }
            ];
    }

    checkAuthenticated() {
        return TokenStore.isAuthenticated();
    }

    getUserEmail() {

        return TokenStore.getUser();
    }
   
    attemptChangePassword() {

        var errors = [];
        for (var i = 0; i < this.validationFunctions.length; i++) {
            let error = this.validationFunctions[i]();
            if (error !== '') {
                errors.push(error);
            }
        }

        if (errors.length === 0) {
            let passwordResetDetails = {
                email: this.state.userEmail,
                token: this.state.token,
                currentPassword: this.state.currentPassword,
                newPassword: this.state.newPassword
            };
            let apiCall = this.state.token !== undefined ? 'ResetPassword' : 'ChangePassword';
            let passwordAction = this.state.token !== undefined ? 'reset' : 'change';
            let passwordError = this.state.token !== undefined ? 'Invalid token.' : 'Incorrect current password.';

            fetch('api/Account/' + apiCall, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(passwordResetDetails)
            }).then(response => {
                this.setState({ displayErrorMessage: '',
                                inputErrors: [] });

                if (response.status !== 200) {
                    throw Error(response.status);
                }
                else {
                    this.setState({
                        displayErrorMessage: 'Password ' + passwordAction + ' successful.',
                        passwordChanged: true
                    });
                }
            }).catch(error => {
                if (error.message === '401') {
                    this.setState({
                        displayErrorMessage: 'Unable to ' + passwordAction + ' password. Account not registered in system.'
                    });
                }
                else if (error.message === '403') {
                    this.setState({
                        displayErrorMessage: 'Unable to ' + passwordAction + ' password. ' + passwordError // Invalid token or current password is wrong (Reset/Change)
                    });
                }
                else {
                    this.setState({
                        displayErrorMessage: 'An unexpected error code: ' + error.message + ' was returned from the server. Please try again.'
                    })
                }
            });
        } else {
            this.setState({ inputErrors: errors });
        }
    }

    onCurrentPasswordChange(event) {
      this.setState({ currentPassword: event.target.value });
    }

    onNewPasswordChange(event) {
        this.setState({ newPassword: event.target.value });
    }

    onConfirmNewPasswordChange(event) {
        this.setState({ confirmNewPassword: event.target.value });
    }

    static renderErrorBanner(errorsIssued) {
        if (errorsIssued.length === 0) {
            return null;
        }

        return (
            <div>
                <Alert bsStyle="danger">
                    {errorsIssued.map(error =>
                        <span>{error}{'\n'}</span>
                    )}
                </Alert>
            </div>);
    }

    static renderWarningBanner(warningIssued) {
      if (!warningIssued) {
        return null;
      }

      return (
        <div>
          <Alert bsStyle="danger">
            {warningIssued}
          </Alert>
        </div>
      );
    }

    render() {
        const inputErrors = ResetPassword.renderErrorBanner(this.state.inputErrors);
        const invalidInformation = ResetPassword.renderWarningBanner(this.state.displayErrorMessage);
        const showCurrentPassword = this.state.passwordChanged === false && this.state.isAuthenticated === true ? 'visible' : 'hidden';
        const showForm = this.state.passwordChanged === false && (this.state.isAuthenticated === true || this.state.token !== undefined) ? 'visible' : 'hidden';
        const title = this.state.isAuthenticated === false ? this.state.token !== undefined ? 'Reset your password.' : '' : 'Change your password.';

      return (
          <div className="loginForm">
          {inputErrors}
          {invalidInformation}
              <h4 style={{ visibility: showForm }}>{title}</h4>
              <form onSubmit={this.attemptChangePassword} style={{ visibility: showForm }}>
                  <Form horizontal onSubmit={this.attemptChangePassword}>
              <FormGroup controlId="formHorizontalPassword">
                <Row style={{ visibility: showCurrentPassword }}>
                  <Col sm={3}>
                    Current Password
                    </Col>
                  <Col sm={7}>
                    <FormControl type="password" placeholder="Current Password" value={this.state.currentPassword} onChange={this.onCurrentPasswordChange} />
                  </Col>
                </Row>
                </FormGroup>
                <FormGroup controlId="formHorizontalPassword">
                    <Row>
                        <Col sm={3}>
                            New Password
                        </Col>
                        <Col sm={7}>
                            <FormControl type="password" placeholder="New Password" value={this.state.newPassword} onChange={this.onNewPasswordChange} />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup controlId="formHorizontalPassword">
                    <Row>
                        <Col sm={3}>
                            Confirm New Password
                        </Col>
                        <Col sm={7}>
                            <FormControl type="password" placeholder="Confirm New Password" value={this.state.confirmNewPassword} onChange={this.onConfirmNewPasswordChange} />
                        </Col>
                    </Row>
                </FormGroup>
              <FormGroup>
                          <Row>
                              <Col md={3}>
                                  <span></span>
                              </Col>
                  <Col md={7}>
                    &nbsp;&nbsp;<Button onClick={this.attemptChangePassword}>Change Password</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </form>
        </div>
      );
    }
}
