import React, { Component } from 'react';
import { Alert, Form, FormGroup, FormControl, Col, Button, Row } from 'react-bootstrap';
import { RegistrationModal } from './RegistrationModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import './Login.css';
import queryString from 'query-string';

export class ConfirmEmail extends Component {
    displayName = ConfirmEmail.name;

    constructor(props) {
      super(props);

      const values = queryString.parse(this.props.location.search);
      const userEmail = values.email;
      const token = values.token;

      this.state = {
        showModal: false,
        showResetPasswordModal: false,
        displayErrorMessage: '',
        username: '',
        password: '',
        userEmail: userEmail,
        token: token,
        emailConfirmed: false
      };
    }

    async componentDidMount() {

        const { userEmail, token, emailConfirmed } = this.state;

        // If there is a token then the user is confirming their email for registration
        if (token !== undefined && emailConfirmed === false) {
            let confirmationDetails = {
                email: userEmail,
                token: token
            }

            fetch('api/Account/ConfirmEmail', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(confirmationDetails)
            }).then(response => {
                if (response.status === 200) {
                    this.setState({
                        displayErrorMessage: 'Your email has been confirmed and account activated. You can now login.',
                        token: undefined,
                        emailConfirmed: true
                    });
                }
                else if (response.status === 202) {
                    this.setState({
                        displayErrorMessage: 'Your email has been confirmed and account pending activation.',
                        token: undefined,
                        emailConfirmed: true
                    });
                }
                else {
                    throw Error(response.status);
                }
            }).catch(error => {
                if (!!error.message.match(/(4[0-9])/)) { // 4XX error returned
                    this.setState({
                        displayErrorMessage: 'Unable to confirm your email.',
                        token: undefined,
                        emailConfirmed: false
                    });
                }
                else if (!!error.message.match(/(5[0-9])/)) { // 5XX error returned
                    this.setState({
                        displayErrorMessage: 'An unexpected error occurred - Please try again later.',
                        token: undefined,
                        emailConfirmed: false
                    });
                }
                else {
                    this.setState({
                        displayErrorMessage: 'An unexpected error code: ' + error.message + ' was returned from the server. Please try again.',
                        token: undefined,
                        emailConfirmed: false
                    });
                }
            });
        }
        else {
            this.setState({
                displayErrorMessage: '',
                token: undefined,
                emailConfirmed: true
            });
        }
    }

    static renderWarningBanner(warningIssued) {
      if (!warningIssued) {
        return null;
      }

      return (
        <div>
          <Alert bsStyle="danger">
            {warningIssued}
          </Alert>
        </div>
      );
    }

    render() {
      const invalidInformation = ConfirmEmail.renderWarningBanner(this.state.displayErrorMessage);

      return (
        <div className="loginForm">
          {invalidInformation}
        </div>
      );
    }
}
