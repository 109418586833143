import React, { Component } from 'react';

export class Home extends Component {
    displayName = Home.name;

    render() {
      return (
        <div>
          <h2>Welcome to Wodin!</h2>
          <span>Wodin is a web application for reporting of observations. At this time, Wodin supports reporting of METAR and MANMAR. To report observations, please register for an account and sign-in.</span>
          <h3>Documentation</h3>
          <span>Documentation on MANOBS reporting can be found <a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/manobs-surface-observations.html" target="_blank" rel="noopener noreferrer">here.</a></span> < br/>
          <span>Documentation on MANAB reporting can be found <a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/manab-word-abbreviations-4th-edition.html" target="_blank" rel="noopener noreferrer">here.</a></span> < br />
          <span>Documentation on MANMAR reporting can be found <a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/marine-observations.html" target="_blank" rel="noopener noreferrer">here.</a></span> < br />
          <span>The cloud atlas can be found <a href="https://cloudatlas.wmo.int/en/home.html" target="_blank" rel="noopener noreferrer">here.</a></span>
        </div>
      );
    }
}
