import React, { Component } from 'react';
import DateTime from 'react-datetime';
import { Col, Container, Form, FormGroup, Row } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import './ObservationEditor.css';

import ObservationReportTypeMap from '../../constants/observationTypeReports';
import Actions from '../../actions/action';
import Store from '../../stores/store';
import { ObservationEditorGroup } from './ObservationEditorGroup';
import { ObservationReportEditor } from './ObservationReportEditor';
import { ObservationReportViewer } from './ObservationReportViewer'; 


export class ObservationEditor extends Component {
    displayName = ObservationEditor.name;

    constructor(props) {
        super(props);

        this.onDateTimeChange = this.onDateTimeChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    onDateTimeChange(newDate) {
        const _location = this.props.location;
        if ((newDate instanceof moment)) {
            Actions.updateObservation({
                dateTime: newDate.toISOString(),
                station: _location.metarId,
                manmarId: _location.manmarId,
                latitude: _location.latitude,
                longitude: _location.longitude,
                quadrant: 7,//no matching in location object, hard coded for now.
                stationHeight: _location.stationHeight,
                barometerHeight: _location.barometerHeight
            });
        }
    }

    handleSelectChange(event) {
        const value = event.target.value;
        Actions.changeObservationType(value);
    }

    render() {
        let filteredPreviousObservations ;
        //filter observations by type time
        //if METAR/SPECI, 15 hours old
        //if MANMAR, 24 hours old
        let filteredPreviousReports = this.props.previousReports.filter((report) => {
            return ((report.type === 'METAR' || report.type === 'SPECI') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 15) 
            || ((report.type === 'MANMAR') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 24) 
            || ((report.type === 'MANICE') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 24)
        });
        if(Object.keys(this.props.location).length !== 0) {
            filteredPreviousObservations = this.props.previousObservations.filter((x) => x.station === this.props.location.metarId || x.station === this.props.location.manmarId);
            //Filter observations by location
            filteredPreviousReports = filteredPreviousReports.filter((report) => {
                return report.locationId === this.props.location.id;
            });
        }
        let observationViewer = <span>No report type selected.</span>
        if (this.props.observationType) {
            const reportTypes = ObservationReportTypeMap[this.props.observationType];
            observationViewer = reportTypes.map((reportType, index) => {
                return <ObservationReportViewer
                    key={index}
                    previousReports={filteredPreviousReports}
                    reportType={reportType.name}
                    maxNumberOfReportsDisplayed={reportType.numberOfPreviousReports}
                />
            });
        }
        const dateTimeValue = this.props.observation.dateTime ? this.props.observation.dateTime : undefined;
        
        if (this.refs.datetime) {
            const propPassedDate = new moment.utc(this.props.observation.dateTime).format("YYYY-MM-DD HH:mm");
            if (propPassedDate != this.refs.datetime.state.inputValue) { //checking if date picker displayed date is not consistent
                this.refs.datetime.state.inputValue = propPassedDate;
                this.refs.datetime.state.selectedDate = new moment.utc(this.props.observation.dateTime);
            } 
        }

        let observationTitle;
        if(Object.keys(this.props.observation).length !== 0) {
            observationTitle = <h1>Edit Observation ({this.props.location.name})</h1>
        } else {
            observationTitle = <h1>New Observation ({this.props.location.name})</h1>
        }

        return (
            <div>
                <Container fluid>
                    <Row>
                        <Col md={7}>
                            {observationTitle}
                            <Form>
                                <Form.Row>
                                    {this.props.observationType &&
                                        <Col>
                                            <FormGroup>
                                                <Form.Text>Date and Time</Form.Text>
                                                <DateTime
                                                    ref={"datetime"}
                                                    onChange={this.onDateTimeChange}
                                                    dateFormat="YYYY-MM-DD"
                                                    defaultValue={dateTimeValue}
                                                    timeFormat="HH:mm"
                                                    utc
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                    
                                    <Col>
                                        <FormGroup>
                                            <Form.Text>Report Types</Form.Text>
                                            <Form.Control
                                                name="reportType"
                                                as="select"
                                                onChange={this.handleSelectChange}
                                                value={this.props.observationType ? this.props.observationType : ''}
                                            >
                                                <option
                                                    key=''
                                                    value=''
                                                >
                                                    Select an observation type
                                        </option>
                                                <option
                                                    key='metar'
                                                    value='metar'
                                                >
                                                    METAR
                                        </option>
                                                <option
                                                    key='speci'
                                                    value='speci'
                                                >
                                                    SPECI
                                        </option>
                                                <option
                                                    key='manmar'
                                                    value='manmar'
                                                >
                                                    MANMAR
                                                </option>
        
                                                <option
                                                    key='manice'
                                                    value='manice'
                                                >
                                                    MANICE
                                                </option>
                                                <option
                                                    key='metarAndManmar'
                                                    value='metarAndManmar'
                                                >
                                                    Both METAR and MANMAR
                                        </option>
                                            </Form.Control>
                                        </FormGroup>
                                    </Col>
                                    
                                </Form.Row>
                                <div className="observation-editor__container">
                                    {
                                        Object.keys(this.props.parameterGroups).map((groupKey) =>
                                            <ObservationEditorGroup
                                                key={groupKey}
                                                observation={this.props.observation}
                                                parameterKey={groupKey}
                                                parameterGroup={this.props.parameterGroups[groupKey]}
                                                previousObservations={filteredPreviousObservations}
                                                location={this.props.location}
                                            />
                                        )
                                    }
                                </div>
                            </Form>
                        </Col>
                        <Col md={5}>
                            <ObservationReportEditor
                                observation={this.props.observation}
                                failingChecks={this.props.failingChecks}
                                failingCheckList={this.props.failingCheckList}
                                observationType={this.props.observationType}
                                reports={this.props.reports}
                                location={this.props.location}
                                validReport={this.props.validReport}
                            />
                            <h1>Historical Observations</h1>
                            {observationViewer}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}