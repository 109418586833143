const parameterGroups = {
    winds: [
        'twoMinuteMeanWindDirection',
        'twoMinuteMeanWindSpeed',
        'twoMinuteGustSpeed',
        'twoMinuteVariableWindDirection',
        'tenMinuteMeanWindDirection',
        'tenMinuteMeanWindSpeed',
        'blank_manmar',
        'windEstimation',
    ],
    visibilityAndWeather: [
        'visibility',
        'metarWeather',
        'blank_manmar',
        'manmarPresentWeather',
        'manmarPastWeatherCodeOne',
        'manmarPastWeatherCodeTwo'
    ],
    skyCondition: [
        'cloudHeightLevel1',
        'cloudTypeLevel1',
        'cloudAmountLevel1',
        'cloudOpacityLevel1',
        'cloudHeightLevel2',
        'cloudTypeLevel2',
        'cloudAmountLevel2',
        'cloudOpacityLevel2',
        'cloudHeightLevel3',
        'cloudTypeLevel3',
        'cloudAmountLevel3',
        'cloudOpacityLevel3',
        'cloudHeightLevel4',
        'cloudTypeLevel4',
        'cloudAmountLevel4',
        'cloudOpacityLevel4',
        'cloudHeightAmt',
        'cloudTypeAmt',
        'blank_metar',
        'cloudCover',
        'baseLowestCloud',
        'totalCloudCover',
        'blank_manmar',
        'lowCloudType',
        'mediumCloudType',
        'highCloudType',
    ],
    temperatures: [
        'airTemperature',
        'wetbulbTemperature',
        'waterTemperature',
        'airTemperatureTwelveHoursAgo',
        'wetbulbTemperatureSign',
        'waterTemperatureSign',
        'relativeHumidity',
        'dewpointTemperature'
        
    ],
    pressure: [
        'stationPressure',
        'meanSeaLevelPressure',
        'altimeter',
        'blank_manmar',
        'threeHourPressureCharacter',
        'threeHourPressureChange'
    ],
    significantAndMaxWaveHeight: [
        'significantWaveHeight',
        'maximumWaveHeight',
        'meanPeriod'
    ],
    swells: [
        'primarySwellDirection',
        'primarySwellHeight',
        'primarySwellPeriod',
        'blank_manmar',
        'secondarySwellDirection',
        'secondarySwellHeight',
        'secondarySwellPeriod'
    ],
    windWaves: [
        'windWaveHeight',
        'windWavePeriod'
    ],
    iceAccretion: [
        'iceAccretionType',
        'iceAccretionThickness',
        'iceAccretionRate'
    ],
    seaIce: [
        'seaIceConcentration',
        'seaIceStageOfDevelopment',
        'seaIceOrigin',
        'seaIceBearing',
        'seaIceThreeHourSituation'
    ],
    icebergHeader: [
        'nationality',
        'source',
        'callsign',
        'platformIdentifier',
        'platformType',
        'consecutiveIcebergNumber',
        'dayOfMonth',
        'monthOfYear',
        'lastDigitOfYear',
    ],
    icebergMetaData: [
        'callsignMeta',
        'icebergNumber',
        'icebergMobility',
        'icebergObservation',
        'timeInHoursGG',
        'timeInHoursgg',
        'icebergLatitude',
        'icebergLongitude',
        'concentration',
        'maniceRemarks'
    ],
    icebergCharacteristics: [
        'icebergSize',
        'icebergShape',
        'icebergLength',
        'length',
        'icebergWidth',
        'width',
        'icebergHeight',
        'height',
        'icebergDraft',
        'draft',
        'icebergDirection',
        'direction',
        'icebergSpeed',
        'speed'
    ],
    additionalRemarks: [
        'observerRemarks',
    ]
}

export default parameterGroups;