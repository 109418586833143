import React, { Component } from 'react';
import { Alert, FormGroup, Modal,  FormControl, Col, Button, Row } from 'react-bootstrap';

export class RegistrationModal extends Component {
    displayName = RegistrationModal.name;

    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        inputErrors: [],
        displayErrorMessage: ''
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleRegister = this.handleRegister.bind(this);
      this.validateEmail = this.validateEmail.bind(this);

      this.validationFunctions = [
        () => {
          return !this.state.email ? 'No email provided. Please input email.' : '';
        },
        () => {
          return !this.state.password ? 'No password provided. Please input password.' : '';
        },
        () => {
          return !this.state.confirmPassword ? 'Password unconfirmed. Please confirm password.' : '';
        },
        () => {
          return !this.state.firstName ? 'No first name provided. Please input first name.' : '';
        },
        () => {
          return !this.state.lastName ? 'No last name provided. Please input last name.' : '';
        },
        () => {
          return !this.validateEmail(this.state.email) ? 'Invalid email provided. Pease input valid email.' : '';
        },
        () => {
          return !(this.state.password === this.state.confirmPassword) ? 'Passwords do not match. Please re-enter provided passwords.' : '';
        },
        () => {
          return !(this.state.password.length >= 8) ? 'Password must be 8 or more characters long. Please enter a longer password.' : '';
        },
        () => {
          return !(this.state.password.search(/\d/) !== -1) ? 'Password must contain a number. Please add a number to your password.' : '';
        }
      ];
    }

    // This isn't stealing - just borrowing
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    handleRegister() {
      var errors = [];
      for (var i = 0; i < this.validationFunctions.length; i++) {
        let error = this.validationFunctions[i]();
        if (error !== '') {
          errors.push(error);
        }
      }

      if (errors.length === 0) {
        let registrationDetails = {
          email: this.state.email,
          password: this.state.password,
          firstName: this.state.firstName,
          lastName: this.state.lastName
        };

        fetch('api/Account', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(registrationDetails)
        }).then(response => {
            this.setState({ displayErrorMessage: '' });
            this.setState({ inputErrors: [] });

            if (response.status !== 200) {
                throw Error(response.status);
            }
            else {
               this.props.handleClose();
           }
           }).catch(error => {
                if (error.message === '403') {
                        this.setState({
                            displayErrorMessage: 'Email address already registered in system. Please try again.'
                        });
                    }
                else {
                    this.setState({
                        displayErrorMessage: 'An unexpected error code: ' + error.message + ' was returned from the server. Please try again.',
                        password: ''
                    })
                }
            });
      } else {
        this.setState({ inputErrors: errors });
      }
    }

    handleChange(event) {
      this.setState({ [event.target.name]: event.target.value });
    }

    static renderErrorBanner(errorsIssued) {
      if (errorsIssued.length === 0) {
        return null;
      }

      return (
        <div>
          <Alert bsStyle="danger">
            {errorsIssued.map(error =>
              <span>{error}{'\n'}</span>
            )}
          </Alert>
        </div>);
    }

    static renderWarningBanner(warningIssued) {
        if (!warningIssued) {
            return null;
        }

        return (
            <div>
                <Alert bsStyle="danger">
                    {warningIssued}
                </Alert>
            </div>
        );
    }

    render() {
      const inputErrors = RegistrationModal.renderErrorBanner(this.state.inputErrors);
      const accountExists = RegistrationModal.renderWarningBanner(this.state.displayErrorMessage);
      return (
        <div>
          <Modal show="true">
            <Modal.Header closeButton>
              <Modal.Title>User Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {inputErrors}
              {accountExists}
              <form onSubmit={this.handleRegister} horizontal="true">
                <FormGroup controlId="formControlsText">
                  <Row>
                    <Col sm={4}>
                                        Email Address
                    </Col>
                    <Col sm={8}>
                      <FormControl type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="formControlsText">
                  <Row>
                    <Col sm={4}>
                                        First Name
                    </Col>
                    <Col sm={8}>
                      <FormControl type="text" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="formControlsText">
                  <Row>
                    <Col sm={4}>
                                        Last Name
                    </Col>
                    <Col sm={8}>
                      <FormControl type="text" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="formControlsText">
                  <Row>
                    <Col sm={4}>
                                        Password
                    </Col>
                    <Col sm={8}>
                      <FormControl type="password" name="password" value={this.state.password} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="formControlsText">
                  <Row>
                    <Col sm={4}>
                                        Re-Enter Password
                    </Col>
                    <Col sm={8}>
                      <FormControl type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleRegister}>Submit</Button>
              <Button onClick={this.props.handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
}
