import React, { Component } from 'react';
import { Alert, FormGroup, Modal,  FormControl, Col, Button, Row } from 'react-bootstrap';

export class ResetPasswordModal extends Component {
    displayName = ResetPasswordModal.name;

    constructor(props) {
      super(props);
      this.state = {
        email: '',
        inputErrors: [],
        displayErrorMessage: '',
        showForm: true
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleResetPassword = this.handleResetPassword.bind(this);
      this.validateEmail = this.validateEmail.bind(this);

      this.validationFunctions = [
        () => {
          return !this.state.email ? 'No email provided. Please input email.' : '';
        },
        () => {
            return !this.validateEmail(this.state.email) ? 'Invalid email provided. Pease input valid email.' : '';
        }
      ];
    }

    // This isn't stealing - just borrowing
    validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    handleResetPassword() {
      var errors = [];
      for (var i = 0; i < this.validationFunctions.length; i++) {
        let error = this.validationFunctions[i]();
        if (error !== '') {
          errors.push(error);
        }
      }

        if (errors.length === 0) {

        let passwordResetDetails = {
            Key: this.state.email
        };

          this.setState({ displayErrorMessage: '' });

          fetch('api/Account/SendPasswordResetLink', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
              body: JSON.stringify(passwordResetDetails)
            }).then(response => {
                this.setState({ displayErrorMessage: '' });
                this.setState({ inputErrors: [] });

                if (response.status !== 200) {
                    throw Error(response.status);
                }
                else {
                    this.setState({
                        displayErrorMessage: 'Reset password email sent.',
                        showForm: false
                    });
                }
            }).catch(error => {
                if (error.message === '403') {
                        this.setState({
                            displayErrorMessage: 'Email address is not registered in system. Please try again.'
                        });
                    }
                else {
                    this.setState({
                        displayErrorMessage: 'An unexpected error code: ' + error.message + ' was returned from the server. Please try again.',
                        password: ''
                    })
                }
            });
      } else {
        this.setState({ inputErrors: errors });
      }
    }

    handleChange(event) {
      this.setState({ [event.target.name]: event.target.value });
    }

    static renderErrorBanner(errorsIssued) {
      if (errorsIssued.length === 0) {
        return null;
      }

      return (
        <div>
          <Alert bsStyle="danger">
            {errorsIssued.map(error =>
              <span>{error}{'\n'}</span>
            )}
          </Alert>
        </div>);
    }

    static renderWarningBanner(warningIssued) {
        if (!warningIssued) {
            return null;
        }

        return (
            <div>
                <Alert bsStyle="danger">
                    {warningIssued}
                </Alert>
            </div>
        );
    }

    render() {
      const inputErrors = ResetPasswordModal.renderErrorBanner(this.state.inputErrors);
        const accountExists = ResetPasswordModal.renderWarningBanner(this.state.displayErrorMessage);
        const showResetPasswordForm = this.state.showForm ? 'visible' : 'hidden';
        const cancelButtonText = this.state.showForm ? 'Cancel' : 'Close';
      return (
        <div>
          <Modal show="true">
            <Modal.Header closeButton>
              <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {inputErrors}
                {accountExists}
                      <form onSubmit={this.handleResetPassword} horizontal="true">
                          <FormGroup controlId="formControlsText" style={{ visibility: showResetPasswordForm }}>
                  <Row>
                    <Col sm={4}>
                                        Email Address
                    </Col>
                    <Col sm={8}>
                      <FormControl type="text" name="email" value={this.state.email} onChange={this.handleChange} />
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.handleResetPassword} style={{ visibility: showResetPasswordForm }}>Reset Password</Button>
              <Button onClick={this.props.handleClose}>{cancelButtonText}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
}
