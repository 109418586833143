import React, { Component } from 'react';
import {Button, Col, Row } from 'react-bootstrap';
import Actions from '../../actions/action';

export class ObservationReportViewer extends Component {
    displayName = ObservationReportViewer.name;
    constructor(props) {
        super(props);

        this.onEditButtonClick = this.onEditButtonClick.bind(this);
    }

    render() {
        let reports = this.props.previousReports;
        if (this.props.reportType) {
            reports = reports.filter((x) => x.type === this.props.reportType);
        }
        if (this.props.maxNumberOfReportsDisplayed) {
            reports = reports.slice(0, this.props.maxNumberOfReportsDisplayed);
        }
        if (reports.length === 0) {
            return (null);
        }
        return (
            <div>
                {this.props.children ? this.props.children : <h2>{this.props.reportType}</h2>}
                {reports.map((report) =>
                    <Row key={report.observationId} className="observation-report-viewer__row">
                        <Col className="observation-report-viewer__edit-report" md="11">{report.report}</Col>
                        <Col md="1" className="observation-viewer__col">
                            <Button className="observation-viewer__new-observation" value={report.observationId} onClick={this.onEditButtonClick} size="sm">
                                Edit
                            </Button>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }

    onEditButtonClick(event) {
        const observationId = event.target.value;
        const relevantReport = this.props.previousReports.filter((x) => x.observationId === observationId)[0];
        Actions.changeLocation(relevantReport.locationId);
        Actions.switchObservationView(relevantReport);
    }
}