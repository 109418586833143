import React, { Component } from 'react';
import { Button, Col, Container, Row, Form, FormGroup } from 'react-bootstrap';
import { ObservationEditor } from './ObservationEditor';
import { ObservationViewer } from './ObservationViewer';
import Action from '../../actions/action';
import Actions from '../../actions/action';
import Store from '../../stores/store';

export class ObservationsPage extends Component {
    displayName = ObservationsPage.name;

    constructor(props) {
        super(props);

        this.state = {
            displayObservationViewer: true,
            parameterGroups: {},
            observationType: '',
            observation: null,
            previousReports: [],
            reports: [],
            validReport: false,
            locations: [],
            location: null,
        }

        this.onDataChange = this.onDataChange.bind(this);
    }

    componentWillMount() {
        Store.addChangeListener(this.onDataChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onDataChange);
    }

    componentDidMount() {
        Action.getReports();
        Action.getLocations();
    }

    onDataChange() {
        this.setState({
            displayObservationViewer: Store.getViewerState(),
            observation: Store.getObservation(),
            failingChecks: Store.getAnyFailingChecks(),
            failingCheckList: Store.getListOfFailingChecks(),
            observationType: Store.getObservationType(),
            parameterGroups: Store.getParameterGroups(),
            previousReports: Store.getPreviousReports(),
            previousObservations: Store.getPreviousObservations(),
            reports: Store.getReports(),
            validReport: Store.getValidReport(),
            locations: Store.getLocations(),
            location: Store.getLocation()
        })
    }

    render() {
        let selectedValue = '';
        const selectedLocation = this.state.location;
        if(selectedLocation !== null) {
            if (Object.keys(selectedLocation).length !== 0) {
                selectedValue = selectedLocation.id;
            }
        }
        
        return (
            <div>
                {
                        this.state.displayObservationViewer ?
                        <ObservationViewer
                            previousReports={this.state.previousReports}
                            locations={this.state.locations}
                            location={this.state.location}
                        /> :
                        <ObservationEditor
                            observation={this.state.observation}
                            failingChecks={this.state.failingChecks}
                            failingCheckList={this.state.failingCheckList}
                            observationType={this.state.observationType}
                            parameterGroups={this.state.parameterGroups}
                            previousObservations={this.state.previousObservations}
                            previousReports={this.state.previousReports}
                            reports={this.state.reports}
                            validReport={this.state.validReport}
                            locations={this.state.locations}
                            location={this.state.location}
                        />
                }
            </div>
        );
    }
}