import React, { Component } from 'react';
import { Button, Col, Container, Form, FormControl, Row, FormGroup } from 'react-bootstrap';
import Action from '../../actions/action';

export class LocationEditor extends Component {
    displayName = LocationEditor.name;

    constructor(props) {
        super(props);
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    onSubmitClick() {
        Action.postLocation(this.props.location);
        Action.getLocations();
        Action.switchLocationView();
        Action.clearLocation();
    }

    onCancelClick() {
        Action.clearLocation();
        Action.switchLocationView();
    }

    onPropertyChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        Action.updateLocation({
            [name]: value
        })
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <h2>Edit Location</h2>
                    <Form>
                        <Form.Row>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Location
                                    </Form.Text>
                                    <FormControl
                                        name="name"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.name}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Id
                                    </Form.Text>
                                    <FormControl
                                        name="id"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.id}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Latitude
                                    </Form.Text>
                                    <FormControl
                                        name="latitude"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.latitude}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Longitude
                                    </Form.Text>
                                    <FormControl
                                        name="longitude"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.longitude}
                                    />
                                </FormGroup>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Manmar Id
                                    </Form.Text>
                                    <FormControl
                                        name="manmarId"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.manmarId}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Metar Id
                                    </Form.Text>
                                    <FormControl
                                        name="metarId"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.metarId}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Barometer Height
                                    </Form.Text>
                                    <FormControl
                                        name="barometerHeight"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.barometerHeight}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Station Height
                                    </Form.Text>
                                    <FormControl
                                        name="stationHeight"
                                        type="text"
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.stationHeight}
                                    />
                                </FormGroup>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <FormGroup>
                                    <Form.Text>
                                        Recipient Emails
                                    </Form.Text>
                                    <FormControl
                                        type="email"
                                        multiple
                                        as="textarea"
                                        rows={4}
                                        name="receiptEmailAddress"
                                        placeholder=""
                                        onChange={this.onPropertyChange}
                                        value={this.props.location.receiptEmailAddress}
                                    />
                                </FormGroup>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <FormGroup>
                                <Button onClick={this.onSubmitClick}>Submit</Button>
                                <Button onClick={this.onCancelClick}>Close</Button>
                            </FormGroup>
                        </Form.Row>
                    </Form>
                </Container>
            </div>
        );
    }
}