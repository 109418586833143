import moment, { isMoment } from 'moment';

function isNumber(value) {
    return typeof value == 'number' && !isNaN(value);
}

function waveHeightToWaveCode(value) {
    return (value / 0.5);
}

function extractTemperatureField(value) {
    const signField = parseFloat(value[0]) % 2 === 0 ? 1 : -1;
    const matchedValue = value.match(/\d(?<value>\d{2,3})/);
    return signField * parseFloat(matchedValue.groups.value)/10;
}

const visibilityLookup = [
    [90, 0],
    [91, 55 / 2025],
    [92, 220 / 2025],
    [93, 550 / 2025],
    [94, 0.5],
    [95, 1],
    [96, 2],
    [97, 5],
    [98, 11],
    [99, 27],
];

const manmarSections = {
    metadata: {
        inputs: ['manmarId', 'dateTime', 'latitude', 'longitude', 'quadrant','windEstimation'],
        encoder: (manmarId, dateTime, latitude, longitude, quadrant, windEstimation) => {
            const momentDateTime = moment.utc(dateTime);
            const offshoreDateTimePrefix = `${momentDateTime.format('YYYYMM')} ${momentDateTime.format('DDHHmm')}`;
            let dTstring = "";
            if (windEstimation){
                dTstring = `${momentDateTime.format('DDHH')}3`;
            }
            else {
                dTstring = `${momentDateTime.format('DDHH')}4`;
            }
            const dateTimeString = dTstring;
            const latitudeString =(`99${((Math.abs(latitude) * 10)%1000).toFixed(0).padStart(3, '0')}`);
            const longitudeString = `${quadrant}${((Math.abs(longitude)* 10)%10000).toFixed(0).padStart(4, '0')}`;
            return `${offshoreDateTimePrefix} BBXX ${manmarId} ${dateTimeString} ${' '} ${latitudeString} ${longitudeString}`;
        },
        decoder: (match) => {
            const observation = {};
            const date = moment.utc(match.groups.date, 'YYYYMM');
            const time = moment.utc(match.groups.time, 'DDHHmm');
            observation.latitude = (parseInt(match.groups.latitude) / 10);
            observation.longitude = (parseInt(match.groups.longitude) / 10);
            observation.quadrant = parseInt(match.groups.quadrant);
            observation.manmarId = match.groups.id;
            observation.dateTime = date.set({
                date: time.date(),
                hour: time.hour(),
                minute: time.minute()
            }).toISOString();
            return observation;
        }
    },
    visibility: {
        inputs: ['baseLowestCloud', 'visibility'],
        encoder: (baseLowestCloud, visibility) => {
            if (!baseLowestCloud || !visibility) {
                return '41hVV';
            }
            const visibilityCode = visibilityLookup.reduce((closest, next) => {
                if (!closest) {
                    return next;
                }
                const oldDifference = Math.abs(closest[1] - visibility);
                const newDifference = Math.abs(next[1] - visibility);
                return newDifference < oldDifference ? next : closest;
            })
            return `41${baseLowestCloud}${visibilityCode[0]}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.heightCode) {
                observation.baseLowestCloud = match.groups.heightCode;
            }
            if (match.groups.visibilityCode) {
                const code = parseInt(match.groups.visibilityCode);
                observation.visibility = visibilityLookup.find((x) => {
                    return x[0] === code;
                })[1];
            }
            return observation;
        }
    },
    windsAndCloud: {
        inputs: ['cloudCover', 'tenMinuteMeanWindSpeed', 'tenMinuteMeanWindDirection'],
        encoder: (cloudCover, tenMinuteMeanWindSpeed, tenMinuteMeanWindDirection) => {
            if (!cloudCover || tenMinuteMeanWindSpeed === undefined) {
                return 'Nddff';
            }
            if (tenMinuteMeanWindSpeed <= 5 && tenMinuteMeanWindDirection === "VRB") {
                const windSpeedString = `${tenMinuteMeanWindSpeed.toFixed(0).padStart(2, '0')}`;
                return `${cloudCover}99${windSpeedString}`;
            }
            if (tenMinuteMeanWindDirection === "000" && tenMinuteMeanWindSpeed === 0){
                return `${cloudCover}9900`;
            }
            tenMinuteMeanWindSpeed = tenMinuteMeanWindSpeed > 99 ? 99 : tenMinuteMeanWindSpeed;
            const windSpeedString = `${tenMinuteMeanWindSpeed.toFixed(0).padStart(2, '0')}`;
            const windDirectionString = tenMinuteMeanWindDirection ?
                `${(tenMinuteMeanWindDirection / 10).toFixed(0).padStart(2, '0')}` :
                '99';
            return `${cloudCover}${windDirectionString}${windSpeedString}`;
        }, 
        decoder: (match) => {
            const observation = {};
            if (match.groups.cloudCover) {
                observation.cloudCover = parseFloat(match.groups.cloudCover);
            }
            if (match.groups.windDirection) {
                observation.tenMinuteMeanWindDirection = parseFloat(match.groups.windDirection) * 10;
            }
            if (match.groups.windSpeed) {
                observation.tenMinuteMeanWindSpeed = parseFloat(match.groups.windSpeed);
            }
            return observation;
        }
    },
    extremeWind: {
        inputs: ['tenMinuteMeanWindSpeed'],
        encoder: (tenMinuteMeanWindSpeed) => {
            if (!isNumber(tenMinuteMeanWindSpeed) || tenMinuteMeanWindSpeed < 100) {
                return '';
            }
            return `00${tenMinuteMeanWindSpeed.toFixed(0).padStart(3, '0')}`;
        },
        decoder: (match) => {
            return {};
        }
    },
    temperature: {
        inputs: ['airTemperature', 'dewpointTemperature'],
        encoder: (airTemperature, dewpointTemperature) => {
            if (!airTemperature || !dewpointTemperature) {
                return '1snTTT 2snTdTd/';
            }

            // Truncate dewpoint temperature to 0 decimal places of precision
            if (dewpointTemperature > 1) {
                dewpointTemperature = Math.round(dewpointTemperature);
            };
            const temperatureSign = airTemperature < 0 ? "1" : "0";
            const dewpointSign = dewpointTemperature <= 0 ? "1" : "0";
            const temperatureString = `1${temperatureSign}${(Math.abs(airTemperature) * 10 % 1000).toFixed(0).padStart(3, '0')}`;
            const dewpointString = `2${dewpointSign}${(Math.abs(dewpointTemperature) % 100).toFixed(0).padStart(2, '0')}/`;
            return `${temperatureString} ${dewpointString}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.temperature) {
                observation.airTemperature = extractTemperatureField(match.groups.temperature);
            }
            if (match.groups.dewpoint) {
                observation.dewpointTemperature = extractTemperatureField(match.groups.dewpoint) * 10;
            }
            return observation;
        }
    },
    pressure: {
        inputs: ['meanSeaLevelPressure', 'threeHourPressureCharacter', 'threeHourPressureChange'],
        encoder: (pressure, pressureCharacter, pressureChange) => {
            if (!isNumber(pressure) || !pressureCharacter) {
                return '4PPPP 5appp';
            }
            const pressureString = `4${(pressure * 10 % 10000).toFixed(0).padStart(4, '0')}`;
            let tendencyString = " ";
            if(pressureChange === "///"){
                pressureChange = 0;
                pressureCharacter = "/";
                tendencyString = `5////`;
                return `${pressureString} ${tendencyString}` 
            }
            else{
                pressureChange = Math.abs(pressureChange);
            }
            tendencyString = `5${pressureCharacter}${(pressureChange * 10 % 1000).toFixed(0).padStart(3, '0')}`;
            return `${pressureString} ${tendencyString}`
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.pressure) {
                const base = parseInt(match.groups.pressure) / 10;
                const root = base < 40 ? 1000 : 900;
                observation.meanSeaLevelPressure = base + root;
            }
            if (match.groups.pressureCharacter) {
                observation.threeHourPressureCharacter = parseInt(match.groups.pressureCharacter);
            }
            if (match.groups.pressureTendency) {
                observation.threeHourPressureChange = parseInt(match.groups.pressureTendency)/10.0;
            }
            return observation;
        }
    },
    weather: {
        inputs: ['manmarPresentWeather', 'manmarPastWeatherCodeOne', 'manmarPastWeatherCodeTwo'],
        encoder: (weather, pastWeatherCodeOne, pastWeatherCodeTwo) => {
            if (!weather || !pastWeatherCodeOne || !pastWeatherCodeTwo) {
                return '7wwW1W2';
            }
            const firstCode = parseInt(pastWeatherCodeOne) < parseInt(pastWeatherCodeTwo) ? pastWeatherCodeTwo : pastWeatherCodeOne;
            const secondCode = parseInt(pastWeatherCodeOne) < parseInt(pastWeatherCodeTwo) ? pastWeatherCodeOne : pastWeatherCodeTwo;
            return `7${weather.toString().padStart(2, '0')}${firstCode}${secondCode}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.weather) {
                observation.manmarPresentWeather = parseInt(match.groups.weather);
            }
            if (match.groups.pastWeatherOne) {
                observation.manmarPastWeatherCodeOne = match.groups.pastWeatherOne;
            }
            if (match.groups.pastWeatherTwo) {
                observation.manmarPastWeatherCodeTwo = match.groups.pastWeatherTwo;
            }
            return observation;
        }
    },
    cloud: {
        inputs: ['totalCloudCover', 'lowCloudType', 'mediumCloudType', 'highCloudType'],
        encoder: (totalCloudCover, lowCloudType, mediumCloudType, highCloudType) => {
            if (!totalCloudCover || !lowCloudType || !mediumCloudType || !highCloudType) {
                return '8NhClCmCh';
            }
            return `8${totalCloudCover}${lowCloudType}${mediumCloudType}${highCloudType}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.totalCloudCover) {
                observation.totalCloudCover = match.groups.totalCloudCover;
            }
            if (match.groups.lowCloudCode) {
                observation.lowCloudType = match.groups.lowCloudCode;
            }
            if (match.groups.mediumCloudCode) {
                observation.mediumCloudType = match.groups.mediumCloudCode;
            }
            if (match.groups.highCloudCode) {
                observation.highCloudType = match.groups.highCloudCode;
            }
            return observation;
        }
    },
    groupTwoStart: {
        inputs: [],
        encoder: () => {
            return '22200';
        },
        decoder: () => {
            // This contains no data - just filler
            return {};
        }
    },
    seaSurfaceTemperature: {
        inputs: ['waterTemperature', 'waterTemperatureSign'],
        encoder: (waterTemperature, waterTemperatureSign) => {
            if (!isNumber(waterTemperature) || !waterTemperatureSign) {
                return '0swTwTwTw';
            }
            const waterTemperatureString = `0${waterTemperatureSign}${(Math.abs(waterTemperature) * 10 % 1000).toFixed(0).padStart(3, '0')}`;
            return `${waterTemperatureString}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.waterTemperatureSign) {
                observation.waterTemperatureSign = parseInt(match.groups.waterTemperatureSign);
            }
            if (match.groups.waterTemperature) {
                observation.waterTemperature = extractTemperatureField(match.groups.waterTemperature);
            }
            return observation;
        }
    },
    waveGroup: {
        inputs: ['windWavePeriod', 'windWaveHeight', 'primarySwellDirection', 'primarySwellPeriod', 'primarySwellHeight', 'secondarySwellDirection', 'secondarySwellPeriod', 'secondarySwellHeight'],
        encoder: (windWavePeriod, windWaveHeight, primarySwellDirection, primarySwellPeriod, primarySwellHeight,  secondarySwellDirection, secondarySwellPeriod, secondarySwellHeight) => {
            if ((!isNumber(windWavePeriod) || !isNumber(windWaveHeight)) && (!isNumber(primarySwellPeriod) || !isNumber(primarySwellHeight))) {
                return '2PwPwHwHw 3dw1dw1dw2dw2 4Pw1Pw1Hw1Hw1 5Pw2Pw2Hw2Hw2';
            }

            // Swells only
            if (!isNumber(windWavePeriod) && !isNumber(windWaveHeight)) {
                const primarySwellDirectionString = isNumber(parseInt(primarySwellDirection)) ?
                    (parseInt(primarySwellDirection) / 10).toFixed(0).padStart(2, '0') :
                    '//';

                const secondarySwellDirectionString = isNumber(parseInt(secondarySwellDirection)) ?
                    (parseInt(secondarySwellDirection) / 10).toFixed(0).padStart(2, '0') :
                    '//';

                const primarySwellPeriodString = isNumber(primarySwellPeriod) ?
                    `${primarySwellPeriod.toFixed(0).padStart(2, '0')}` :
                    '//';
                const primarySwellHeightString = isNumber(primarySwellHeight) ?
                    `${waveHeightToWaveCode(primarySwellHeight).toFixed(0).padStart(2, '0')}` :
                    '//';

                const secondarySwellPeriodString = isNumber(secondarySwellPeriod) ?
                    `${secondarySwellPeriod.toFixed(0).padStart(2, '0')}` :
                    '';
                const secondarySwellHeightString = isNumber(secondarySwellHeight) ?
                    `${waveHeightToWaveCode(secondarySwellHeight).toFixed(0).padStart(2, '0')}` :
                    '';
                const secondarySwellString = secondarySwellPeriod !== '' ?
                    ` 5${secondarySwellPeriodString}${secondarySwellHeightString}` :
                    '';

                return `3${primarySwellDirectionString}${secondarySwellDirectionString} 4${primarySwellPeriodString}${primarySwellHeightString}${secondarySwellString}`;
            }
            // Wind wave only
            if (!isNumber(primarySwellPeriod) && !isNumber(primarySwellHeight)) {
                const windWavePeriodString = `${windWavePeriod.toFixed(0).padStart(2, '0')}`;
                const windWaveHeightString = `${waveHeightToWaveCode(windWaveHeight).toFixed(0).padStart(2, '0')}`;
                return `2${windWavePeriodString}${windWaveHeightString}`;
            }

            let windWavePeriodString = isNumber(windWavePeriod) ? `${windWavePeriod.toFixed(0).padStart(2, '0')}` :'//';
            
            let windWaveHeightString = isNumber(windWaveHeight) ? `${waveHeightToWaveCode(windWaveHeight).toFixed(0).padStart(2, '0')}` :'//';

            if (windWavePeriod === 0 && windWaveHeight === 0) {
                windWavePeriodString = '00';
                windWaveHeightString = '00';
            }
            const primarySwellDirectionString = isNumber(parseInt(primarySwellDirection)) ?
                (parseInt(primarySwellDirection) / 10).toFixed(0).padStart(2, '0') :
                '//';
            const secondarySwellDirectionString = isNumber(parseInt(secondarySwellDirection)) ?
                (parseInt(secondarySwellDirection) / 10).toFixed(0).padStart(2, '0') :
                '//';

            const primarySwellPeriodString = isNumber(primarySwellPeriod) ?
                `${primarySwellPeriod.toFixed(0).padStart(2, '0')}` :
                '//';
            const primarySwellHeightString = isNumber(primarySwellHeight) ?
                `${waveHeightToWaveCode(primarySwellHeight).toFixed(0).padStart(2, '0')}` :
                '//';

            const secondarySwellPeriodString = isNumber(secondarySwellPeriod) ?
                `${secondarySwellPeriod.toFixed(0).padStart(2, '0')}` :
                '';
            const secondarySwellHeightString = isNumber(secondarySwellHeight) ?
                `${waveHeightToWaveCode(secondarySwellHeight).toFixed(0).padStart(2, '0')}` :
                '';
            const secondarySwellString = isNumber(secondarySwellPeriod) && isNumber(secondarySwellHeight) ?
                ` 5${secondarySwellPeriodString}${secondarySwellHeightString}` :
                '';

            return `2${windWavePeriodString}${windWaveHeightString} 3${primarySwellDirectionString}${secondarySwellDirectionString} 4${primarySwellPeriodString}${primarySwellHeightString}${secondarySwellString}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.windWavePeriod) {
                observation.windWavePeriod = parseInt(match.groups.windWavePeriod);
            }
            if (match.groups.windWaveHeight) {
                observation.windWaveHeight = parseInt(match.groups.windWaveHeight) * 0.5;
            }
            if (match.groups.primarySwellDirection) {
                observation.primarySwellDirection = parseInt(match.groups.primarySwellDirection) * 10;
            }
            if (match.groups.secondarySwellDirection) {
                const value = parseInt(match.groups.secondarySwellDirection) * 10;
                if (isNumber(value)) {
                    observation.secondarySwellDirection = value;
                }
            }
            if (match.groups.primarySwellPeriod) {
                observation.primarySwellPeriod = parseInt(match.groups.primarySwellPeriod);
            }
            if (match.groups.primarySwellHeight) {
                observation.primarySwellHeight = parseInt(match.groups.primarySwellHeight) * 0.5;
            }
            if (match.groups.secondarySwellPeriod) {
                observation.secondarySwellPeriod = parseInt(match.groups.secondarySwellPeriod);
            }
            if (match.groups.secondarySwellHeight) {
                observation.secondarySwellHeight = parseInt(match.groups.secondarySwellHeight) * 0.5;
            }
            return observation;
        }
    },
    iceAccretionGroup: {
        inputs: ['iceAccretionType', 'iceAccretionThickness', 'iceAccretionRate'],
        encoder: (iceAccretionType, iceAccretionThickness, iceAccretionRate) => {
            if (!iceAccretionType || !iceAccretionThickness || !iceAccretionRate) {
                return '';
            }
            return `6${iceAccretionType}${iceAccretionThickness.padStart(2, '0')}${iceAccretionRate}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.iceAccretionType) {
                observation.iceAccretionType = parseInt(match.groups.iceAccretionType);
            }
            if (match.groups.iceAccretionThickness) {
                observation.iceAccretionThickness = parseInt(match.groups.iceAccretionThickness);
            }
            if (match.groups.iceAccretionRate) {
                observation.iceAccretionRate = parseInt(match.groups.iceAccretionRate);
            }
            return observation;
        }
    },
    significantWaveHeightGroup: {
        inputs: ['significantWaveHeight'],
        encoder: (significantWaveHeight) => {
            if (!isNumber(significantWaveHeight)) {
                return '';
            }
            return `70${(significantWaveHeight*10).toFixed(0).padStart(3, '0')}`
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.significantWaveHeight) {
                observation.significantWaveHeight = parseInt(match.groups.significantWaveHeight) / 10;
            }
            return observation;
        },
        disabled: true
    },
    wetBulbTemperatureGroup: {
        inputs: ['wetbulbTemperature', 'wetbulbTemperatureSign'],
        encoder: (wetbulbTemperature, wetbulbTemperatureSign) => {
            if (!isNumber(wetbulbTemperature) && !wetbulbTemperatureSign) {
                return '8swTbTbTb';
            }
            const wetbulbTemperatureString = `8${wetbulbTemperatureSign}${(Math.abs(wetbulbTemperature) * 10 % 1000).toFixed(0).padStart(3, '0')}`;
            return `${wetbulbTemperatureString}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.wetbulbTemperatureSign) {
                observation.wetbulbTemperatureSign = parseInt(observation.wetbulbTemperatureSign);
            }
            if (match.groups.wetbulbTemperature) {
                observation.wetbulbTemperature = extractTemperatureField(match.groups.wetbulbTemperature);
            }
            return observation;
        }
    },
    seaIceGroup: {
        inputs: ['seaIceConcentration', 'seaIceStageOfDevelopment', 'seaIceOrigin', 'seaIceBearing', 'seaIceThreeHourSituation'],
        encoder: (seaIceConcentration, seaIceStageOfDevelopment, seaIceOrigin, seaIceBearing, seaIceThreeHourSituation) => {
            if (!seaIceConcentration || !seaIceStageOfDevelopment || !seaIceOrigin || !seaIceBearing || !seaIceThreeHourSituation) {
                return '';
            }
            return `ICE ${seaIceConcentration}${seaIceStageOfDevelopment}${seaIceOrigin}${seaIceBearing}${seaIceThreeHourSituation}=`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.seaIceConcentration) {
                observation.seaIceConcentration = match.groups.seaIceConcentration;
            }
            if (match.groups.seaIceStageOfDevelopment) {
                observation.seaIceStageOfDevelopment = match.groups.seaIceStageOfDevelopment;
            }
            if (match.groups.seaIceOrigin) {
                observation.seaIceOrigin = match.groups.seaIceOrigin;
            }
            if (match.groups.seaIceBearing) {
                observation.seaIceBearing = match.groups.seaIceBearing;
            }
            if (match.groups.seaIceThreeHourSituation) {
                observation.seaIceThreeHourSituation = match.groups.seaIceThreeHourSituation;
            }
            return observation;
        }
    },
    end: {
        inputs:[],
        encoder: () => {
            return `=`;
        },
        decoder: (match) => {
            
        }
    }
}

export default class ManmarEncoder {
    encode(observation) {
        let sections = [];
        for (const key in manmarSections) {
            const currentSection = manmarSections[key];
            if (currentSection.disabled) {
                continue;
            }
            let encoder = currentSection.encoder;
            for (const arg of currentSection.inputs) {
                encoder = encoder.bind(this, observation[arg]);
            }
            sections.push(encoder());
        }
        return sections.join(' ').replace(/\s\s+/g, ' ').trim();
    }
    decode(manmarString) {
        //(?<cc>[A-F]{3})
        const manmarRegex = /((?<date>[0-9]{6}) (?<time>[0-9]{6})) BBXX (?<id>[A-Z,0-9]{4,6}) ((?<datetime>[0-9]{4})(3|4)) 99(?<latitude>[0-9]{3}) (?<quadrant>[0-9,\/]{1})(?<longitude>[0-9,\/]{4}) 41(?<heightCode>[0-9,\/]{1})(?<visibilityCode>[0-9,\/]{2}) (?<cloudCover>[0-9,\/]{1})(?<windDirection>[0-9,\/]{2})(?<windSpeed>[0-9,\/]{2})( 00(?<extremeWinds>[0-9,\/]{3}))? 1(?<temperature>[0-9,\/]{4}) 2(?<dewpoint>[0-9,\/]{4}) 4(?<pressure>[0-9,\/]{4}) 5(?<pressureCharacter>[0-9,\/]{1})(?<pressureTendency>[0-9,\/]{3}) 7(?<weather>[0-9,\/]{2})(?<pastWeatherOne>[0-9,\/]{1})(?<pastWeatherTwo>[0-9,\/]{1}) 8(?<totalCloudCover>[0-9,\/]{1})(?<lowCloudCode>[0-9,\/]{1})(?<mediumCloudCode>[0-9,\/]{1})(?<highCloudCode>[0-9,\/]{1}) 22200 0(?<waterTemperatureSign>[0-9,\/]{1})(?<waterTemperature>[0-9,\/]{3}) 2(?<windWavePeriod>[0-9,\/]{2})(?<windWaveHeight>[0-9,\/]{2})? (3(?<primarySwellDirection>[0-9,\/]{2})(?<secondarySwellDirection>[0-9,\/]{2}) )?(4(?<primarySwellPeriod>[0-9,\/]{2})(?<primarySwellHeight>[0-9,\/]{2}) )?(5(?<secondarySwellPeriod>[0-9,\/]{2})(?<secondarySwellHeight>[0-9,\/]{2}) )?(6(?<iceAccretionCause>[0-9,\/]{2})(?<iceAccretionThickness>[0-9,\/]{2})(?<iceAccretionRate>[0-9,\/]{2}) )?(70(?<significantWaveHeight>[0-9,\/]{3}) )?8(?<wetbulbTemperatureSign>[0-9,\/]{1})(?<wetbulbTemperature>[0-9,\/]{3})( ICE (?<seaIceConcentration>[0-9,\/]{2})(?<seaIceStageOfDevelopment>[0-9,\/]{2})(?<seaIceOrigin>[0-9,\/]{2})(?<seaIceBearing>[0-9,\/]{2})(?<seaIceSituation>[0-9,\/]{2}))?/;
        const match = manmarString.match(manmarRegex);
        let observations = {};
        for (const key in manmarSections) {
            const currentSection = manmarSections[key];
            if (currentSection.disabled) {
                continue;
            }
            const decoder = currentSection.decoder;
            observations = Object.assign({}, observations, decoder(match));
        }
        return observations;
    }
}