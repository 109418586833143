import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import './LocationViewer.css';
import TokenAction from '../../actions/tokenAction';
import Action from '../../actions/action';

export class LocationViewer extends Component {
    displayName = LocationViewer.name;

    constructor(props) {
        super(props);
        this.onEditLocationClick = this.onEditLocationClick.bind(this);
        this.onNewLocationClick = this.onNewLocationClick.bind(this);
    }

    onEditLocationClick(event) {
        const locationId = event.target.value;
        TokenAction.checkToken();
        Action.changeLocation(locationId);
        Action.switchLocationView();
    }

    onNewLocationClick() {
        TokenAction.checkToken();
        Action.newLocation();
        Action.switchLocationView();
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <h2>Locations</h2>
                    <div className="location-viewer__table">
                        <Row className="location-viewer__header">
                            <Col md={2}>Location Name</Col>
                            <Col md={1}>Id</Col>
                            <Col md={1}>Latitude</Col>
                            <Col md={1}>Longitude</Col>
                            <Col md={1}>MetarId</Col>
                            <Col md={1}>ManmarId</Col>
                            <Col md={1}>Station Height</Col>
                            <Col md={1}>Barometer Height</Col>
                            <Col md={1}>Actions</Col>
                        </Row>
                        {this.props.locations.map((location) =>
                            <Row key={location.id}>
                                <Col md={2}>{location.name}</Col>
                                <Col md={1}>{location.id}</Col>
                                <Col md={1}>{location.latitude}</Col>
                                <Col md={1}>{location.longitude}</Col>
                                <Col md={1}>{location.metarId}</Col>
                                <Col md={1}>{location.manmarId}</Col>
                                <Col md={1}>{location.stationHeight}</Col>
                                <Col md={1}>{location.barometerHeight}</Col>
                                <Col md={1}>
                                    <Button value={location.id} onClick={this.onEditLocationClick}>
                                        Edit
                                </Button>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <Button className="location-viewer__new-location" onClick={this.onNewLocationClick}>
                        New Location
                    </Button>
                </Container>
                
            </div>
        );
    }

    onEditLocationButtonClick() {

    }
}