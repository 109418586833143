import React, { Component } from 'react';
import { Button, Col, Container, Row, Form, FormGroup } from 'react-bootstrap';
import moment from 'moment';
import Actions from '../../actions/action';
import TokenAction from '../../actions/tokenAction';
import './ObservationViewer.css';
import './ObservationReportViewer';
import { ObservationReportViewer } from './ObservationReportViewer';
import ObservationReportTypeMap from '../../constants/observationTypeReports';

export class ObservationViewer extends Component {
    displayName = ObservationViewer.name;

    constructor(props) {
        super(props);

        this.isLatestReportForType = this.isLatestReportForType.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(event) {
        const value = event.target.value;
        Actions.changeLocation(value);
    }
    
    isLatestReportForType(report) {
        const reportType = report.type;
        const reportSite = report.locationId;
        const reportDateTime = moment.utc(report.dateTimeUtc);
        const cutoffDateTime = moment.utc().add('minutes', reportType === 'manmar' ? -150: -60);

        const relevantReports = this.props.previousReports.filter((report) => {
            return report.type === reportType && report.locationId === reportSite;
        });
        const maxDateTime = relevantReports.reduce((mostRecentDateTime, report) => {
            const nextDateTime = moment.utc(report.dateTimeUtc, 'YYYY-MM-DDTHH:mm:ss');
            if (!mostRecentDateTime) {
                return nextDateTime;
            }
            return nextDateTime.isAfter(nextDateTime) ? nextDateTime : mostRecentDateTime;
        }, null);
        return reportDateTime.isSame(maxDateTime) && reportDateTime.isAfter(cutoffDateTime);
    }

    render() {
        let selectedValue = '';
        //filter observations by type time
        //if METAR/SPECI, 15 hours old
        //if MANMAR, 24 hours old
        let filteredPreviousReports = this.props.previousReports.filter((report) => {
            return ((report.type === 'METAR' || report.type === 'SPECI') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 15) 
            || ((report.type === 'MANMAR') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 24)
            || ((report.type === 'MANICE') && moment.duration(moment().diff(report.dateTimeUtc)).asHours() <= 24)
        });

        const selectedLocation = this.props.location;
        if(selectedLocation !== null) {
            if (Object.keys(selectedLocation).length !== 0) {
                selectedValue = selectedLocation.id;
                //Filter observations by location
                filteredPreviousReports = filteredPreviousReports.filter((report) => {
                    return report.locationId === selectedValue
                });
            }
        }

        const reportTypes = ObservationReportTypeMap.all;
        const observationViewer = reportTypes.map((reportType, index) => {
            return <ObservationReportViewer
                key={index}
                previousReports={filteredPreviousReports}
                reportType={reportType.name}
                maxNumberOfReportsDisplayed={reportType.numberOfPreviousReports}
            />
        });
        
        return (
            <div>
                <Container fluid>
                    <Row>
                            <Col xs={3} md={2}><h1>Observations</h1></Col>
                            <Col xs={3} md={2}>
                                    <FormGroup className="locationSelection">
                                        <Form.Text> </Form.Text>
                                        <Form.Control
                                            name="location"
                                            as="select"
                                            onChange={this.handleSelectChange}
                                            value={selectedValue}
                                        >
                                            <option
                                                key=''
                                                value=''
                                            >
                                                Select a Location
                                                </option>
                                                {this.props.locations.map((location) =>
                                                <option 
                                                    key={location.id}
                                                    value={location.id}
                                                >
                                                    {location.name}
                                                </option>
                                            )}
                                        </Form.Control>
                                        <div class = "observation-viewer__new-observation">
                                            <Button type = "button" class="btn" onClick={this.onObservationButtonClick} disabled = {this.newLocation()}>
                                                Create New Observation
                                            </Button>
                                        </div>
                                    </FormGroup>
                            </Col>  
                        </Row>
                        {observationViewer}
                </Container>
            </div>
        );
    }

    onObservationButtonClick() {
        TokenAction.checkToken();
        Actions.switchObservationView();
    }

    newLocation(){
        const selectedLocation = this.props.location;
        if(selectedLocation !== null) {
            const location = selectedLocation.id;
            if(location === undefined){
                return true;
            }
            else{
                return false;
            }
        }
    }
}

