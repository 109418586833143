import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { ApplicationBody} from './ApplicationBody';
import { Footer } from './Footer';

import './Layout.css';

export class Layout extends Component {
    displayName = Layout.name

    render() {
      return (
        <div className='appLayout'>
          <NavMenu isAuthenticated={this.props.isAuthenticated} />
          <ApplicationBody>
            {this.props.children}
          </ApplicationBody>
          <Footer />
        </div>
      );
    }
}
