import moment from 'moment';

import ActionTypes from '../constants';
import Dispatcher from '../dispatcher';
import TokenStore from '../stores/tokenStore';

class Actions {
    changeObservationType(payload) {
        Dispatcher.dispatch({
            actionType: ActionTypes.CHANGE_OBSERVATION_TYPE,
            payload: payload
        });
    }
    switchObservationView(payload) {
        Dispatcher.dispatch({
            actionType: ActionTypes.SWITCH_OBSERVATION_VIEW,
            payload: payload
        });
    }

    getReports(start,end) {
        start = moment(start).startOf('day').add(-1, 'day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        end = moment(end).endOf('day').add(1,'day').format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        
        const isAuthenticated = TokenStore.isAuthenticated();
        if (isAuthenticated) {
            const token = TokenStore.getToken();
            fetch(`api/observations?startDateTime=${start}&endDateTime=${end}`, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(response => response.json())
            .then((data) => {
                Dispatcher.dispatch({
                    actionType: ActionTypes.UPDATE_PREVIOUS_REPORTS,
                    payload: data
                });
            })
        }
    }

    reportType(report){
        if (report.includes('BBXX')){
            return 'Manmar';
        }
        if (report.includes('IBCN4')){
            return 'Manice';
        }
        else {
            return 'Metar';
        }
    }

    sendReports(payload, location, observation, email, textareaValue) {
        const submitTime = moment.utc().format();
        const locationId = location.id;
        const user = TokenStore.getUser();
        const forceSendObserverEmail = email;
        const reason = textareaValue;
        const observationsToSend = payload.map((report) => {
            return {
                dateTimeUtc: submitTime,
                type: this.reportType(report),
                locationId: locationId,
                status: 'Sent',
                report: report,
                unformattedObservation: JSON.stringify(observation),
                observerEmail: user,
                forcedSendObserverEmail: forceSendObserverEmail,
                reason: reason
            };
        }); 

        const isAuthenticated = TokenStore.isAuthenticated();
        if (isAuthenticated) {
            var token = TokenStore.getToken();
            fetch('api/Observations', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(observationsToSend)
            }).then(response => {
                this.getReports();
            })
        }
    }

    updateObservation(payload) {
        Dispatcher.dispatch({
            actionType: ActionTypes.UPDATE_OBSERVATION,
            payload: payload
        });
    }

    updateReport(payload) {
        Dispatcher.dispatch({
            actionType: ActionTypes.UPDATE_REPORTS,
            payload: payload
        });
    }

    clearObservation() {
        Dispatcher.dispatch({
            actionType: ActionTypes.CLEAR_OBSERVATION,
            payload: null
        });
    }

    getLocations(archived) {
        var url = '';
        var name = TokenStore.getUser();
        if (archived){
            url = 'api/Locations/';
        }
        else{
            url = `api/Locations/${name}`;
        }
        const isAuthenticated = TokenStore.isAuthenticated();
        if (isAuthenticated) {
            const token = TokenStore.getToken();
            fetch(url, {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then(response => response.json())
            .then((data) => {
                Dispatcher.dispatch({
                    actionType: ActionTypes.RETRIEVED_LOCATIONS,
                    payload: data
                });
            });
        }
    }

    postLocation(location) {
        const isAuthenticated = TokenStore.isAuthenticated();
        if (isAuthenticated) {
            const token = TokenStore.getToken();
            fetch('api/Locations', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(location)
            }).then(response => {
                Dispatcher.dispatch({
                    actionType: ActionTypes.LOCATION_POSTED,
                    payload: location
                });
            });
        }
    }

    newLocation() {
        Dispatcher.dispatch({
            actionType: ActionTypes.LOCATION_CLEAR,
            payload: null
        });
    }

    changeLocation(locationId) {
        Dispatcher.dispatch({
            actionType: ActionTypes.CHANGE_LOCATION,
            payload: locationId
        })
    }

    changeArchiveLocation(locationId) {
        Dispatcher.dispatch({
            actionType: ActionTypes.CHANGE_ARCHIVE_LOCATION,
            payload: locationId
        })
    }

    updateLocation(update) {
        Dispatcher.dispatch({
            actionType: ActionTypes.UPDATE_LOCATION,
            payload: update
        });
    }

    clearLocation() {
        Dispatcher.dispatch({
            actionType: ActionTypes.LOCATION_CLEAR,
            payload: null
        });
    }

    switchLocationView() {
        Dispatcher.dispatch({
            actionType: ActionTypes.CHANGE_LOCATION_VIEW,
            payload: null
        })
    }
}

export default new Actions();