import React, { Component } from 'react';
import { Alert, Form, FormGroup, FormControl, Col, Button, Row } from 'react-bootstrap';
import { RegistrationModal } from './RegistrationModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import TokenActions from '../../actions/tokenAction';
import './Login.css';
import queryString from 'query-string';

export class Login extends Component {
    displayName = Login.name;

    constructor(props) {
      super(props);

      this.state = {
        showModal: false,
        showResetPasswordModal: false,
        displayErrorMessage: '',
        username: '',
        password: ''
      };

      this.handleShow = this.handleShow.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleShowResetPassword = this.handleShowResetPassword.bind(this);
      this.handleCloseResetPassword = this.handleCloseResetPassword.bind(this);
      this.attemptAuthenticate = this.attemptAuthenticate.bind(this);
      this.onUsernameChange = this.onUsernameChange.bind(this);
      this.onPasswordChange = this.onPasswordChange.bind(this);
    }

    attemptAuthenticate(event) {
      event.preventDefault();
      let authorizationDetails = {
        email: this.state.username,
        password: this.state.password
      };

      fetch('api/Authorization/login', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(authorizationDetails)
      }).then(response => {
        if (response.status !== 200) {
          throw Error(response.status);
        } else {
          return response.json();
        }
      }).then(token => {
        TokenActions.updateToken(token);
        this.setState({ displayErrorMessage: ''});
      }).catch(error => {
          if (!!error.message.match(/(4[0-9])/)) { // 4XX error returned
              this.setState({
                  displayErrorMessage: 'Invalid username or password. Please try again.',
                  password: ''
              });
          }
          else if (!!error.message.match(/(5[0-9])/)) { // 5XX error returned
              this.setState({
                  displayErrorMessage: 'An unexpected error occurred - Please try again later.',
                  password: ''
              });
          }
          else {
          this.setState({
            displayErrorMessage: 'An unexpected error code: ' + error.message + ' was returned from the server. Please try again.',
            password: ''
          });
        }
      });
    }

    onUsernameChange(event) {
      this.setState({ username: event.target.value });
    }

    onPasswordChange(event) {
      this.setState({ password: event.target.value });
    }

    handleClose() {
      this.setState({ showModal: false });
    }

    handleShow() {
      this.setState({ showModal: true });
    }

    handleCloseResetPassword() {
        this.setState({ showResetPasswordModal: false });
    }

    handleShowResetPassword() {
        this.setState({ showResetPasswordModal: true });
    }

    static renderWarningBanner(warningIssued) {
      if (!warningIssued) {
        return null;
      }

      return (
        <div>
          <Alert bsStyle="danger">
            {warningIssued}
          </Alert>
        </div>
      );
    }

    render() {
      const registrationModal = this.state.showModal ?
        <RegistrationModal handleClose={this.handleClose}/> :
        <div></div>;
      const resetPasswordModal = this.state.showResetPasswordModal ?
        <ResetPasswordModal handleClose={this.handleCloseResetPassword} /> :
        <div></div>;
      const invalidInformation = Login.renderWarningBanner(this.state.displayErrorMessage);

      return (
        <div className="loginForm">
          {invalidInformation}
          <h4>Please sign in.</h4>
          <form onSubmit={this.attemptAuthenticate}>
            <Form horizontal onSubmit={this.attemptAuthenticate}>
              <FormGroup controlId="formHorizontalEmail">
                <Row>
                  <Col sm={2}>
                    Email
                    </Col>
                  <Col sm={10}>
                    <FormControl type="email" placeholder="Email" value={this.state.email} onChange={this.onUsernameChange} />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup controlId="formHorizontalPassword">
                <Row>
                  <Col sm={2}>
                    Password
                    </Col>
                  <Col sm={10}>
                    <FormControl type="password" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange} />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md={4}>
                    &nbsp;&nbsp;<Button onClick={this.handleShowResetPassword}>Reset Password</Button>
                  </Col>
                  <Col md={4}>
                    <span></span>
                  </Col>
                  <Col md={2}>
                    <Button type="submit">Sign in</Button>
                  </Col>
                  <Col md={2}>
                    <Button onClick={this.handleShow}>Register</Button>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </form>
          {registrationModal}
          {resetPasswordModal}
        </div>
      );
    }
}
