import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { LocationsPage } from './components/locations/LocationsPage';
import { ObservationsPage } from './components/observations/ObservationsPage';
import { ResourcesPage } from './components/resources/ResourcesPage';
import { Login } from './components/authentication/Login';
import { ConfirmEmail } from './components/authentication/ConfirmEmail';
import { ResetPassword } from './components/authentication/ResetPassword';
import { ArchivedObservations } from './components/observationHistory/ArchivedObservations';
import TokenStore from './stores/tokenStore';

export default class App extends Component {
    displayName = App.name

    constructor(props) {
      super(props);
      this.checkAuthenticated = this.checkAuthenticated.bind(this);
      this.state = {
        isAuthenticated: this.checkAuthenticated()
      };
      this.onAuthenticationChange = this.onAuthenticationChange.bind(this);
    }

    onAuthenticationChange() {
      this.setState({
        isAuthenticated: this.checkAuthenticated()
      });
    }

    checkAuthenticated() {
      return TokenStore.isAuthenticated();
    }

    componentWillMount() {
      TokenStore.addChangeListener(this.onAuthenticationChange);
    }

    componentWillUnmount() {
      TokenStore.removeChangeListener(this.onAuthenticationChange);
    }

    render() {
      return (
        <Layout>
          <Route exact path='/' component={Home}/>
          <Route exact path='/observations' render={() => (
            this.state.isAuthenticated ? (
              <ObservationsPage />
            ) : (
              <Redirect to="/login" />
            )
          )}/>
          <Route path='/locations' render={() => (
            this.state.isAuthenticated ? (
              <LocationsPage />
            ) : (
              <Redirect to="/login" />
            )
          )}/>
          <Route path = '/archivedObservations' render ={() => (
            this.state.isAuthenticated ? (
              <ArchivedObservations />
            ) : (
              <Redirect to="/login" />
            )
          )}/>
          <Route path='/login' render={() => (
            this.state.isAuthenticated ? (
              <Redirect to="/observations" />
            ) : (
              <Login />
            )
          )}/>
          <Route exact path='/resources' render={() => (
            this.state.isAuthenticated ? (
              <ResourcesPage />
            ) : (
              <Redirect to="/login" />
            )
          )}/>
              <Route exact path='/confirmemail' component={ConfirmEmail} />
              <Route exact path='/resetpassword' component={ResetPassword} />
              <Route exact path='/changepassword' component={ResetPassword} />
        </Layout>
      );
    }
}
