import React, { Component } from 'react';
import Action from '../../actions/action';
import Store from '../../stores/store';
import { LocationViewer } from './LocationViewer';
import { LocationEditor } from './LocationEditor';

export class LocationsPage extends Component {
    displayName = LocationsPage.name;

    constructor(props) {
        super(props);

        this.state = {
            displayLocationViewer: true,
            locations: [],
            location: null
        }
        this.onDataChange = this.onDataChange.bind(this);
    }

    componentWillMount() {
        Store.addChangeListener(this.onDataChange);
    }

    componentWillUnmount() {
        Store.removeChangeListener(this.onDataChange);
    }

    componentDidMount() {
        Action.getLocations();
    }

    onDataChange() {
        this.setState({
            displayLocationViewer: Store.getLocationViewerState(),
            locations: Store.getLocations(),
            location: Store.getLocation()
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.displayLocationViewer ?
                        <LocationViewer
                            locations={this.state.locations}
                        /> :
                        <LocationEditor
                            location={this.state.location}
                        />
                }
            </div>
            )
    }
}