import Fraction from 'fraction.js';
import moment from 'moment';
import { Next } from 'react-bootstrap/PageItem';

function isNumber(value) {
    return typeof value == 'number';
}

const metarSections = {
    metadata: {
        inputs: ['dateTime', 'station', 'cc'],
        encoder: (dateTime, station, cc) => {
            const momentDateTime = moment.utc(dateTime);
            const reportType = momentDateTime.minute() === 0 ? 'METAR' : 'SPECI';
            const arr = ["CCA", "CCB", "CCC", "CCD", "CCE", "CCF"];
            const ccTmp = cc !== null && cc >= -1 ? cc + 1 : -1;
            let ccField ;
            if(cc === undefined) {
                return `${reportType} ${station} ${momentDateTime.format('DDHHmm')}Z `;
            } else {
                const ccField = arr[cc];
                return `${reportType} ${ccField} ${station} ${momentDateTime.format('DDHHmm')}Z `;
            }
        },
        decoder: (match) => {
            const arr = ["CCA", "CCB", "CCC", "CCD", "CCE", "CCF"];
            const observation = {};
            observation.station = match.groups.station;
            observation.dateTime = moment.utc(match.groups.dateTime, 'DDHHmm').toISOString();
            observation.cc = arr.indexOf(match.groups.cc) >= 0 ? arr.indexOf(match.groups.cc) : 0;
            return observation;
        }
    },
    winds: {
        inputs: ['twoMinuteMeanWindSpeed', 'twoMinuteMeanWindDirection', 'twoMinuteGustSpeed','twoMinuteVariableWindDirection'],
        encoder: (windSpeed, windDirection, gustSpeed, twoMinuteVariableWindDirection) => {
            if (!isNumber(windSpeed) || !isNumber(parseFloat(windDirection))) {
                return 'ddffGfmfmKT';
            }
    
            const gustString = isNumber(gustSpeed) ? `G${gustSpeed.toFixed(0).padStart(2, '0')}` : '';
            if(twoMinuteVariableWindDirection) {
                if(windDirection === "VRB"){
                    return `VRB${windSpeed.toFixed(0).padStart(2, '0')}${gustString}KT ${twoMinuteVariableWindDirection}`;
                }
                return `${windDirection}${windSpeed.toFixed(0).padStart(2, '0')}${gustString}KT ${twoMinuteVariableWindDirection}`;
            }
            return `${windDirection}${windSpeed.toFixed(0).padStart(2, '0')}${gustString}KT`;
            return '30015G25KT'
        },
        decoder: (match) => {
            const observation = {};
            observation.twoMinuteMeanWindSpeed = parseFloat(match.groups.twoMinuteMeanWindSpeed);
            observation.twoMinuteMeanWindDirection = parseFloat(match.groups.twoMinuteMeanWindDirection);
            if (match.groups.twoMinuteGustSpeed) {
                observation.twoMinuteGustSpeed = parseFloat(match.groups.twoMinuteGustSpeed);
            }
            if (match.groups.twoMinuteVariableWindDirection) {
                observation.twoMinuteVariableWindDirection = parseFloat(match.groups.twoMinuteVariableWindDirection);
            }
            return observation;
        }
    },
    visibility: {
        inputs: ['visibility'],
        encoder: (visibility) => {
            if (!visibility) {
                return 'VVVVNM';
            }
            const validValues = [
                0.125,
                0.25,
                0.375,
                0.5,
                0.625,
                0.75,
                1,
                1.25,
                1.5,
                1.75,
                2,
                2.25,
                2.5,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15
            ];
            const closestValue = validValues.reduce((currentClosest, nextValue) => {
                const oldDifference = Math.abs(currentClosest - visibility);
                const newDifference = Math.abs(nextValue - visibility);
                return newDifference < oldDifference ? nextValue : currentClosest;
            }, 0);
            const fraction = Fraction(closestValue % 1).toFraction();
            const wholeNumber = parseInt(closestValue / 1);
            if (wholeNumber) {
                return fraction !== "0" ?
                    `${wholeNumber} ${fraction}NM` :
                    `${wholeNumber}NM`;
            }
            return `${fraction}NM`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.visibility) {
                observation.visibility = Fraction(match.groups.visibility).valueOf();
            }
            return observation;
        }
    },
    skyAndWeather: {
        inputs: ['cloudHeightAmt', 'metarWeather'],
        encoder: (sky, weather) => {
            if (!sky && !weather) {
                return 'ww NsNsNshshshs and/or VVhshsh';
            }
            if (!weather) {
                return `${sky}`;
            }

            if (!sky) {
                return `${weather}`;
            }
            return `${weather} ${sky}`
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.cloudHeightAmt) {
                observation.cloudHeightAmt = match.groups.cloudHeightAmt.trim();
            }
            if (match.groups.metarWeather) {
                observation.metarWeather = match.groups.metarWeather.trim();
            }
            return observation;
        }
    },
    temperature: {
        inputs: ['airTemperature', 'dewpointTemperature'],
        encoder: (airTemperature, dewpointTemperature) => {
            if (airTemperature === undefined || dewpointTemperature === undefined) {
                return 'T’T’/T’dT’d';
            }
            const toTemperatureString = (value) => {
                const prefix = value < 0 ? 'M' : '';
                return `${prefix}${Math.abs(value).toFixed(0).padStart(2, '0')}`
            };
            const airTemperatureString = toTemperatureString(airTemperature);
            const dewPointString = toTemperatureString(dewpointTemperature);
            return `${airTemperatureString}/${dewPointString}`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.airTemperature) {
                const sign = match.groups.airTemperature.startsWith('M') ? -1 : 1;
                observation.airTemperature = sign * parseFloat(match.groups.airTemperature.replace('M', ''));
            }
            if (match.groups.dewpointTemperature) {
                const sign = match.groups.dewpointTemperature.startsWith('M') ? -1 : 1;
                observation.dewpointTemperature = sign * parseFloat(match.groups.dewpointTemperature.replace('M', ''));
            }
            return observation;
        }
    },
    pressure: {
        inputs: ['altimeter'],
        encoder: (altimeter) => {
            if (!isNumber(altimeter)) {
                return 'APhPhPhPh';
            }
            return `A${(altimeter*100%10000).toFixed(0)}`
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.altimeter) {
                observation.altimeter = parseFloat(match.groups.altimeter)/100;
            }
            return observation;
        }
    },
    remarks: {
        inputs: ['meanSeaLevelPressure', 'significantWaveHeight', 'observerRemarks', 'cloudTypeAmt', 'meanPeriod', 'maximumWaveHeight', 'waterTemperature'],
        encoder: (meanSeaLevelPressure, significantWaveHeight, observerRemarks, metarCloudRemark, meanPeriod, maximumWaveHeight, waterTemperature) => {
            if (!isNumber(meanSeaLevelPressure)) {
                return 'RMK (Cloud-type/Amount) HSIG:XM HMAX:XM TZ:XS SST:XC SLPY';
            }
            const significantWaveHeightString = isNumber(significantWaveHeight) ? ` HSIG:${significantWaveHeight.toFixed(1)}M` : '';
            const maximumWaveHeightString = isNumber(maximumWaveHeight) ? ` HMAX:${maximumWaveHeight.toFixed(1)}M` : '';
            const meanPeriodString = isNumber(meanPeriod) ? ` TZ:${meanPeriod.toFixed(0)}S` : '';
            const meanSeaLevelPressureString = (meanSeaLevelPressure * 10 % 1000).toFixed(0).padStart(3, '0');
            const metarCloudRemarkString = metarCloudRemark ? ` ${metarCloudRemark}` : '';
            const waterTemperatureString = isNumber(waterTemperature) ? ` SST:${waterTemperature.toFixed(1)}C` : '';
            if (observerRemarks === undefined){
                observerRemarks = "";
            }
            return `RMK ${metarCloudRemarkString} ${observerRemarks}${significantWaveHeightString}${maximumWaveHeightString}${meanPeriodString}${waterTemperatureString} SLP${meanSeaLevelPressureString}=`;
        },
        decoder: (match) => {
            const observation = {};
            if (match.groups.meanSeaLevelPressure) {
                const pressureRoot = parseFloat(match.groups.meanSeaLevelPressure) / 10;
                observation.meanSeaLevelPressure = pressureRoot + (pressureRoot > 40 ? 900 : 1000);
            }
            if (match.groups.significantWaveHeight) {
                observation.significantWaveHeight = parseFloat(match.groups.significantWaveHeight);
            }
            if (match.groups.maximumWaveHeight) {
                observation.maximumWaveHeight = parseFloat(match.groups.maximumWaveHeight);
            }
            if (match.groups.waterTemperature) {
                observation.waterTemperature = parseFloat(match.groups.waterTemperature);
            }
            if (match.groups.meanPeriod) {
                observation.meanPeriod = parseInt(match.groups.meanPeriod);
            }
            return observation;
        },
    }
};

export default class MetarEncoderDecoder {
    encode(observation) {
        let sections = [];
        for (const key in metarSections) {
            const currentSection = metarSections[key];
            let encoder = currentSection.encoder;
            for (const arg of currentSection.inputs) {
                encoder = encoder.bind(this, observation[arg]);
            }
            sections.push(encoder());
        }
        return sections.join(' ').replace(/\s\s+/g, ' ').trim();
    }

    decode(metarString) {
        const metarRegex = /^(?:METAR|SPECI)( (?<cc>(CC[A-F])|•|^$))? (?<station>[A-Z,0-9]{4,6}) (?<dateTime>[0-9]{6})Z (?<twoMinuteMeanWindDirection>[0-9,A-Z]{3})(?<twoMinuteMeanWindSpeed>[0-9]{2,3})G?(?<twoMinuteGustSpeed>[0-9]{2,3})?KT (?:(?<twoMinuteVariableWindDirection>[0-9,A-Z]{0,9}) )?(?<visibility>.*)NM (?<metarWeather>(.?(-|\+|VC|MI|BC|PR|DR|BL|SH|TS|FZ)*(DZ|RA|SN|SG|IC|PL|GR|GS|UP)*(BR|FG|FU|VA|DU|SA|HZ)?(PO|SQ|FC|SS|DS)?)*) ((?<cloudHeightAmt>((VV|SKC|BKN|SCT|FEW|OVC)(\d{0,4})|(CB|TCU))* )*)(?<airTemperature>[A-Z,0-9]{0,9})\/(?<dewpointTemperature>M?\d\d) A(?<altimeter>[0-9]{4}) (.* HSIG:(?<significantWaveHeight>[0-9]{1,2}\.\d)M)?(.* HMAX:(?<maximumWaveHeight>[0-9]{1,2}\.\d)M)?(.* TZ:(?<meanPeriod>\d{1,2})S)?(.* SST-(?<waterTemperature>\d{1,2}\.\d)C)?(.* SLP(?<meanSeaLevelPressure>\d\d\d))?/; 
        const match = metarString.match(metarRegex);
        let observations = {};
        for (const key in metarSections) {
            const currentSection = metarSections[key];
            const decoder = currentSection.decoder;
            observations = Object.assign({}, observations, decoder(match));
        }
        return observations;
    }
}