import React, { Component } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { IoIosEye, IoMdMap, IoIosLock, IoIosUnlock,IoIosBriefcase,IoIosArchive  } from 'react-icons/io';
import { LinkContainer } from 'react-router-bootstrap';
import './NavMenu.css';
import TokenActions from '../actions/tokenAction';
import TokenStore from '../stores/tokenStore';

export class NavMenu extends Component {
    displayName = NavMenu.name;

    constructor(props) {
      super(props);
        
      this.state = {
        isAuthenticated: this.checkAuthenticated()
      };
      this.checkAuthenticated = this.checkAuthenticated.bind(this);
      this.onAuthenticationChange = this.onAuthenticationChange.bind(this);
      this.onLogoutClick = this.onLogoutClick.bind(this);
    }

    onAuthenticationChange() {
      this.setState({
        isAuthenticated: this.checkAuthenticated()
      });
    }

    onLogoutClick() {
      TokenActions.clearToken();
    }

    checkAuthenticated() {
      return TokenStore.isAuthenticated();
    }

    componentWillMount() {
      TokenStore.addChangeListener(this.onAuthenticationChange);
    }

    componentWillUnmount() {
      TokenStore.removeChangeListener(this.onAuthenticationChange);
    }

    render() {
      return (
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand>
            Wodin
          </Navbar.Brand>
          {this.state.isAuthenticated &&
            <Nav className="mr-auto">
              <LinkContainer to={'/observations'} exact>
                <Nav.Link>
                  <IoIosEye /> Observations
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/locations'}>
                <Nav.Link>
                  <IoMdMap/> Locations
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to={'/resources'}>
                <Nav.Link>
                  <IoIosBriefcase/> Resources
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to ={'/archivedObservations'}>
                <Nav.Link>
                  <IoIosArchive /> Archives
                </Nav.Link>
              </LinkContainer>
            </Nav>
          }
            <Nav>
              {
                this.state.isAuthenticated ?
                  <Nav.Link onClick={this.onLogoutClick}>
                    <IoIosUnlock /> Sign Out
                  </Nav.Link> :
                  <LinkContainer to={'/login'} exact>
                    <Nav.Link>
                      <IoIosLock /> Sign In
                    </Nav.Link>
                  </LinkContainer>
              }
            </Nav>
        </Navbar>
      );
    }
}
