import React, { Component } from 'react';
import './ApplicationBody.css';

export class ApplicationBody extends Component {
    displayName = ApplicationBody.name;

    render() {
      return (
        <div className='wodinBody'>
          {this.props.children}
        </div>
      );
    }
}
