import { EventEmitter } from 'events';
import Dispatcher from '../dispatcher';
import ActionTypes from '../constants';
import ManmarEncoder from '../util/manmarEncoder';
import MetarEncoderDecoder from '../util/metarEncoderDecoder';
import ManiceEncoderDecoder from '../util/maniceEncoderDecoder';
import moment from 'moment';
import ParameterGroups from '../constants/parameterGroups';
import ObservationTypeParameterMap from '../constants/observationTypeParameterMap';
import ObservationParameters from '../constants/observationParameters';

const CHANGE = 'TOKEN_CHANGE';

function isNumber(value) {
    return typeof value == 'number';
}

function isText(value) {
    return typeof value == 'string';
}

function isCheck(value) {
    return typeof value == "object";
}

class Store extends EventEmitter {
    constructor() {
        super();
        Dispatcher.register(this._registerActions.bind(this));
        this.encoders = {
            'metar': [
                new MetarEncoderDecoder()
            ],
            'speci': [
                new MetarEncoderDecoder()
            ],
            'manmar': [
                new ManmarEncoder()
            ],
            'metarAndManmar': [
                new MetarEncoderDecoder(),
                new ManmarEncoder()
            ],
            'manice': [
                new ManiceEncoderDecoder()
            ]
        }

        this.decoderMap = {
            'METAR': new MetarEncoderDecoder(),
            'MANMAR': new ManmarEncoder(),
            'MANICE': new ManiceEncoderDecoder()
        };

        this.observationType = null;
        this.observation = {};
        this.currentFailingChecks = {};
        this.parameterGroups = {};
        this.previousReports = [];
        this.reports = [];
        this.viewingObservation = true;

        this.locations = [];
        this.location = {};
        this.archiveLocation = {};
        this.viewingLocation = true;
        this.validReports = false;
    }

    _registerActions(action) {
        switch (action.actionType) {
            case ActionTypes.UPDATE_OBSERVATION:
                return this.updateObservation(action.payload);
            case ActionTypes.CLEAR_OBSERVATION:
                return this.clearObservation();
            case ActionTypes.SWITCH_OBSERVATION_VIEW:
                return this.changeObservationView(action.payload);
            case ActionTypes.CHANGE_OBSERVATION_TYPE:
                return this.changeObservationType(action.payload);
            case ActionTypes.UPDATE_REPORTS:
                return this.updateReports(action.payload);
            case ActionTypes.UPDATE_PREVIOUS_REPORTS:
                return this.updatePreviousReports(action.payload);
            case ActionTypes.RETRIEVED_LOCATIONS:
                return this.updateLocations(action.payload);
            case ActionTypes.CHANGE_LOCATION_VIEW:
                return this.changeLocationView();
            case ActionTypes.UPDATE_LOCATION:
                return this.updateLocation(action.payload);
            case ActionTypes.CHANGE_LOCATION:
                return this.changeLocation(action.payload);
            case ActionTypes.LOCATION_CLEAR:
                return this.clearLocation();
            case ActionTypes.LOCATION_POSTED:
                return this.postLocation(action.payload);
            case ActionTypes.CHANGE_ARCHIVE_LOCATION:
                return this.changeArchiveLocation(action.payload);
            default:
                return;
        }
    }

    addChangeListener(callback) {
        this.on(CHANGE, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE, callback);
    }

    changeObservationType(payload) {
        this.observationType = payload;
        const observationTypeParameters = ObservationTypeParameterMap[this.observationType];


        const newObservation = typeof this.observation.dateTime == 'undefined' ? true : false;
        const datetime = newObservation ? moment().utcOffset(0) : new moment.utc(this.observation.dateTime);
        const currentMinute = datetime.minute();
        const formatedHour = currentMinute >= 5 && !payload.includes("speci") ? datetime.hour() + 1 : datetime.hour();
        const formatedDate = payload.includes("speci") ? datetime : datetime.set({ hour: formatedHour, minute: 0, second: 0, millisecond: 0 });
        var dateObj = new Date();
        var date = dateObj.getUTCDate();
        var month = dateObj.getUTCMonth() + 1;
        var year = dateObj.getUTCFullYear() % 100;
        var hour = dateObj.getUTCHours();
        var minutes = dateObj.getUTCMinutes();
        if (newObservation) {
            const obsObj = {
                dateTime: formatedDate,
                station: this.location.metarId,
                manmarId: this.location.manmarId,
                latitude: this.location.latitude,
                longitude: this.location.longitude,
                quadrant: 7,
                stationHeight: this.location.stationHeight,
                barometerHeight: this.location.barometerHeight,
                observationType: this.observationType,
                nationality: 'CN',
                source: 4,
                callsign: this.location.metarId,
                dayOfMonth: date,
                monthOfYear: month,
                lastDigitOfYear: year,
                timeInHoursGG: hour,
                timeInHoursgg: minutes
            };
            const initialState = this.getInitialState(this.location, formatedDate);
            this.observation = Object.assign({}, this.observation, obsObj, initialState);
        } else {
            this.observation.dateTime = formatedDate;
            this.observation.observationType = this.observationType;
        }

        this.parameterGroups = Object.keys(ParameterGroups).reduce((newGroup, nextKey) => {
            const currentParameterGroup = ParameterGroups[nextKey];
            const commonParameters = currentParameterGroup.filter((x) => {
                return !observationTypeParameters ? [] : observationTypeParameters.includes(x);
            })
            if (commonParameters.length !== 0) {
                newGroup[nextKey] = commonParameters;
            }
            return newGroup;
        }, {})

        const encodersToRun = (this.observationType in this.encoders ? this.encoders[this.observationType] : []);

        this.reports = encodersToRun.map((encoder) => {
            return encoder.encode(this.observation);
        });

        this.updateChecks();
        this.emit(CHANGE);
    }

    updateObservation(payload) {
        this.observation = Object.assign({}, this.observation, payload);
        if (payload.dateTime) {
            const initialState = this.getInitialState(this.location, payload.dateTime);
            this.observation = Object.assign({}, this.observation, initialState);
        }
        this.updateRelatedParameters(payload);
        this.updateChecks();
        
        const encodersToRun = (this.observationType in this.encoders ? this.encoders[this.observationType] : []);

        this.reports = encodersToRun.map((encoder) => {
            return encoder.encode(this.observation);
        });

        this.validReports = this.reports.length !== 0 && this.reports.reduce((allValid, nextReport) => {
            let isValid = false;
            for (var i = 0; i < encodersToRun.length; i++) {
                try {
                    encodersToRun[i].decode(nextReport);
                    isValid = true;
                } catch (err) {

                }
            }
            return isValid && allValid;
        }, true);

        this.emit(CHANGE);
    }

    updateChecks() {
        const parametersInObservationType = ObservationTypeParameterMap[this.observationType] ? ObservationTypeParameterMap[this.observationType] : [];
        for (const observationParameter in ObservationParameters) {
            if (parametersInObservationType.includes(observationParameter)) {
                const checks = ObservationParameters[observationParameter].checks;
                const value = this.observation[observationParameter];
                if (checks && (typeof value !== 'undefined')) {
                    if (value === '') {
                        this.currentFailingChecks[observationParameter] = false;
                    } 
                    else {
                        const failingCheck = Object.keys(checks).find((checkKey) => {
                            const check = checks[checkKey];
                            return !check(value, this.observation, this.getPreviousObservations());
                        });
                        this.currentFailingChecks[observationParameter] = !!failingCheck;
                    }
                } else if (this.currentFailingChecks[observationParameter]) {
                    this.currentFailingChecks[observationParameter] = undefined;
                }
            }
        }
    }

    updateRelatedParameters(payload) {
        const updatedParameters = Object.keys(payload);
        const parametersInObservationType = ObservationTypeParameterMap[this.observationType] ? ObservationTypeParameterMap[this.observationType] : [];
        for (const updatedParameter of updatedParameters) {
            if (parametersInObservationType.includes(updatedParameter)) {
                const parameterRelationships = ObservationParameters[updatedParameter].parameterRelationships;
                if (parameterRelationships) {
                    const value = payload[updatedParameter];
                    Object.keys(parameterRelationships).map((key) => {
                        const relationshipFunction = parameterRelationships[key];
                        const relatedParameterValue = relationshipFunction(value, this.observation);
                        const relatedParameter = ObservationParameters[key];
                        if (isNumber(relatedParameterValue)) {
                            const newValue = parseFloat(relatedParameterValue.toFixed(relatedParameter.displayPrecision));
                            this.observation[key] = newValue;
                            const childParameterInfo = ObservationParameters[key];
                            if (childParameterInfo.parameterRelationships) {
                                const newPayload = {
                                    [key] : newValue
                                };
                                this.updateRelatedParameters(newPayload);
                            }
                        }  // Else, if the related parameter is a text input and the value is a string
                        else if (isText(relatedParameterValue)){
                            this.observation[key] = relatedParameterValue;
                            const childParameterInfo = ObservationParameters[key];
                            const newPayload = {
                                [key] : relatedParameterValue
                            };
                            this.updateRelatedParameters(newPayload);
                        }
                        else if (isCheck(relatedParameterValue)){
                            this.observation[key] = relatedParameterValue;
                            const childParameterInfo = ObservationParameters[key];
                            const newPayload = {
                                [key] : relatedParameterValue
                            };
                            this.updateRelatedParameters(newPayload);
                        }
                    });
                }
            }
        }
    }

    updateReports(payload) {
        this.reports = payload;
        const encodersToRun = (this.observationType in this.encoders ? this.encoders[this.observationType] : []);
        this.validReports = this.reports.length !== 0 && this.reports.reduce((allValid, nextReport) => {
            let isValid = false;
            for (var i = 0; i < encodersToRun.length; i++) {
                try {
                    encodersToRun[i].decode(nextReport);
                    isValid = true;
                } catch (err) {
                    console.log(err);
                }
            }
            return isValid && allValid;
        }, true);

        this.emit(CHANGE);
    }

    updatePreviousReports(payload) {
        this.previousReports = payload;
        this.emit(CHANGE);
    }

    clearObservation() {
        this.observation = {};
        this.observationType = null;
        this.parameterGroups = [];
        this.reports = [];
        this.validReports = false;
        this.emit(CHANGE);
    }

    changeObservationView(payload) {
        if (payload) {
            this.observationType = payload.type.toLowerCase();
            this.observation = JSON.parse(payload.unformattedObservation);
            const observationTypeParameters = ObservationTypeParameterMap[this.observationType];
            if (typeof(this.observation.cc) === 'undefined'){
                this.observation.cc = 0;
            }
            else{
                this.observation.cc = this.observation.cc + 1;
            }
            this.parameterGroups = Object.keys(ParameterGroups).reduce((newGroup, nextKey) => {
                const currentParameterGroup = ParameterGroups[nextKey];
                const commonParameters = currentParameterGroup.filter((x) => {
                    return observationTypeParameters.includes(x);
                })
                if (commonParameters.length !== 0) {
                    newGroup[nextKey] = commonParameters;
                }
                return newGroup;
            }, {})

            const encodersToRun = (this.observationType in this.encoders ? this.encoders[this.observationType] : []);

            this.reports = encodersToRun.map((encoder) => {
                return encoder.encode(this.observation);
            });
            this.validReports = this.reports.length !== 0 && this.reports.reduce((allValid, nextReport) => {
                let isValid = false;
                for (var i = 0; i < encodersToRun.length; i++) {
                    try {
                        encodersToRun[i].decode(nextReport);
                        isValid = true;
                    } catch (err) {

                    }
                }
                return isValid && allValid;
            }, true);
        }
        this.viewingObservation = !this.viewingObservation;
        this.emit(CHANGE);
    }

    changeLocationView() {
        this.viewingLocation = !this.viewingLocation;
        this.emit(CHANGE);
    }

    changeLocation(locationId) {
        const possibleLocations = this.locations.filter((location) => {
            return location.id === locationId;
        });
        if (possibleLocations.length > 0) {
            this.location = possibleLocations[0];
            this.emit(CHANGE);
        }
    }

    changeArchiveLocation(locationId) {
        const possibleLocations = this.locations.filter((location) => {
            return location.id === locationId;
        });
        if (possibleLocations.length > 0) {
            this.archiveLocation = possibleLocations[0];
            this.emit(CHANGE);
        }
    }

    clearLocation() {
        this.location = {};
        this.emit(CHANGE);
    }

    updateLocations(locations) {
        this.locations = locations;
        this.emit(CHANGE);
    }

    updateLocation(location) {
        this.location = Object.assign({}, this.location, location);
        this.emit(CHANGE);
    }

    postLocation(location) {
        const existingLocation = Object.keys(this.locations).find((x) => this.locations[x].id === location.id);
        if (existingLocation != null) {
            this.locations[existingLocation] = location;
        } else {
            this.locations.push(location);
        }
    }

    getViewerState() {
        return this.viewingObservation;
    }

    getObservation() {
        return this.observation;
    }

    getAnyFailingChecks() {
        const failingCheck = Object.keys(this.currentFailingChecks).find((key) => {
            return this.currentFailingChecks[key];
        });
        return !!failingCheck;
    }

    getListOfFailingChecks() {
        return Object.keys(this.currentFailingChecks).map((key) => {
            return this.currentFailingChecks[key] ? key : null;
        }).filter((x) => x);
    }

    getReports() {
        return this.reports;
    }

    getValidReport() {
        return this.validReports;
    }

    getPreviousReports() {
        return this.previousReports;
    }

    getPreviousObservations() {
        return this.previousReports.map((previousReport) => {
            try {
                return this.decoderMap[previousReport.type].decode(previousReport.report);
            } catch (err) {
                return {};
            }
        });
    }

    getObservationType() {
        return this.observationType;
    }

    getParameterGroups() {
        return this.parameterGroups;
    }

    getLocations() {
        return this.locations;
    }

    getLocation() {
        return this.location;
    }

    getArchiveLocation() {
        return this.archiveLocation;
    }

    getLocationViewerState() {
        return this.viewingLocation;
    }

    // Add observations from UOM at some future point
    // For now, settle on just grabbing closest air temperature 12 hours ago
    getInitialState(location, observationDateTime) {
        const observations = this.previousReports.filter((x) => {
            return x.locationId === location.id;
        }).map((x) => {
            return JSON.parse(x.unformattedObservation);
        });
        let initialState = {
            airTemperatureTwelveHoursAgo: undefined,
            pressureThreehoursAgo: undefined,
            pressureLatestObs: undefined,
            hoursRate: undefined

        };
        let minDifference = null;
        // List of observations is sorted from most recent to oldest
        for (const observation of observations) {
            let temp = observations[0];
            let currentObservationDateTime = moment.utc(observation.dateTime, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
            const difference = Math.abs(moment.duration(currentObservationDateTime.diff(observationDateTime)).asHours());
            
            // Can just grab the first observation I find
            if (!initialState.pressureLatestObs) {
                initialState.hoursRate = Math.abs(moment.duration(currentObservationDateTime.diff(observationDateTime)).asHours());
                initialState.pressureLatestObs = temp.stationPressure;
            }
            if (difference > 9 && difference < 15) {
                if (!minDifference || difference < minDifference) {
                    difference = minDifference;
                    initialState.airTemperatureTwelveHoursAgo = observation.airTemperature;
                }
            }
            if (difference === 3) {
                initialState.pressureThreehoursAgo = observation.stationPressure;
            }
        }
        return initialState;
    }
}

export default new Store();