import React, { Component } from 'react';
import ObservationParameters from '../../constants/observationParameters';
import {Button, Form, FormControl } from 'react-bootstrap';
import Actions from '../../actions/action';
import './ObservationEditor.css';

function isNumber(value) {
    return typeof value == 'number';
}

export class ObservationInput extends Component {
    displayName = ObservationInput.name;

    constructor(props) {
        super(props);
        this.parameterInfo = ObservationParameters[this.props.parameter];
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleChange(event) {
        const key = event.target.name;
        const value = event.target.value;

        if (value === '') {
            Actions.updateObservation({
                [key]: undefined
            });
        } else {
            Actions.updateObservation({
                [key]: parseFloat(value)
            });
        }
    }

    handleTextChange(event) {
        const key = event.target.name;
        const value = event.target.value;

        Actions.updateObservation({
            [key]: value.toUpperCase(),
            station: this.props.location.metarId,
            manmarId: this.props.location.manmarId,
        });
    }

    handleSelectChange(event) {
        const key = event.target.name;
        const value = event.target.value;

        Actions.updateObservation({
            [key]: value ? value : undefined,
            station: this.props.location.metarId,
            manmarId: this.props.location.manmarId,
        });
    }

    handleCheckboxChange(event) {
        const key = event.target.id;
        const value = event.target.checked;

        Actions.updateObservation({
            [key]: value
        });        
    }

    getValidationLabel() {
        let value = this.props.observation[this.props.parameter];
        if ((value === '' || typeof value === 'undefined')) {
            if (this.parameterInfo.required) {
                return " " ;
            }
            return;
        }
        
        if (isNumber(this.props.observation[this.props.parameter]) && this.parameterInfo.inputType === 'number') {
            value = this.props.observation[this.props.parameter].toString();
        }

        if (this.parameterInfo.checks) {
            let failingCheck = Object.keys(this.parameterInfo.checks).find((checkKey) => {
                const check = this.parameterInfo.checks[checkKey];
                return !check(value, this.props.observation, this.props.previousObservations);
            });

            if (failingCheck) {
                return failingCheck;
            }

            if (this.parameterInfo.warnings) {
                failingCheck = Object.keys(this.parameterInfo.warnings).find((checkKey) => {
                    const check = this.parameterInfo.warnings[checkKey];
                    return !check(value, this.props.observation, this.props.previousObservations);
                });

                return failingCheck;
            }
        }
    }

    render() {
        let validationLabel = this.getValidationLabel();

        if (this.parameterInfo.inputType === 'text') {
            return (
                <div className="observation-editor__item" key={this.props.parameter}>
                    <Form.Text>{this.parameterInfo.name}</Form.Text>
                    <FormControl
                        name={this.props.parameter}
                        type="text"
                        onChange={this.handleTextChange}
                        style={{
                            textTransform: 'uppercase'
                        }}
                        isInvalid={!!validationLabel}
                        value={this.props.value}
                    />
                    <Form.Control.Feedback type="invalid">
                        {validationLabel}
                    </Form.Control.Feedback>
                </div>
                )
        }
        if (this.parameterInfo.inputType === 'checkbox') {
            return (
                <div className="observation-editor__item" key={this.props.parameter}>
                <Form.Check
                  type="checkbox"
                  id={this.props.parameter}
                  label={this.parameterInfo.name}
                  checked={this.props.value}
                  onChange={this.handleCheckboxChange}
                  isInvalid={!!validationLabel}
                />
                <Form.Control.Feedback type="invalid">
                  {validationLabel}
                </Form.Control.Feedback>
              </div>
                )
        }
        if (this.parameterInfo.inputType === 'select') {
            return (
                    <div className="observation-editor__item" key={this.props.parameter}>
                        <Form.Text>{this.parameterInfo.name}</Form.Text>
                        <FormControl
                            as="select"
                            name={this.props.parameter}
                            type="text"
                            onChange={this.handleSelectChange}
                            isInvalid={!!validationLabel}
                            value={this.props.observation[this.props.parameter]}
                        >
                            <option key='' value=''>No value selected</option>
                            {
                                this.parameterInfo.options.map((option) =>
                                    <option key={option[0]} value={option[0]}>{option[1]}</option>
                                )
                            }
                        </FormControl>
                        <Form.Control.Feedback type="invalid">
                            {validationLabel}
                        </Form.Control.Feedback>
                    </div>
                )
        }

        if (this.parameterInfo.inputType === 'optgroup') {
            return (
                <div className="observation-editor__item" key={this.props.parameter}>
                    <label className="observation-editor__optgroup-label" htmlFor={this.props.parameter + "-select"}>{this.parameterInfo.name}</label>
                    <select className="observation-editor__optgroup" id={this.props.parameter + "-select"} name={this.props.parameter} onChange={this.handleSelectChange} defaultValue={this.props.observation[this.props.parameter]}>
                        <option key="" value="">No value selected</option>
                        {
                            Object.keys(this.parameterInfo.optgroups).map((optgroupName) => {
                                return <optgroup key={optgroupName} label={optgroupName}>
                                    {
                                        this.parameterInfo.optgroups[optgroupName].map((item) => {
                                            return <option key={item[0]} value={item[0]}>{item[1]}</option>
                                        })
                                    }
                                </optgroup>
                            })
                        }
                    </select>
                </div>
            )
        }

        if (this.parameterInfo.inputType === 'blank') {
            return (
                <div className="observation-editor__blank" key={this.props.parameter}>
                </div>
            )
        }

        const value = this.props.value != null ? this.props.value : '';
        return (
            <div className="observation-editor__item" key={this.props.parameter}>
                <Form.Text>{this.parameterInfo.name}</Form.Text>
                <FormControl
                    name={this.props.parameter}
                    type="number"
                    onChange={this.handleChange}
                    min={this.parameterInfo.minValue}
                    max={this.parameterInfo.maxValue}
                    isInvalid={!!validationLabel}
                    disabled={this.parameterInfo.derived}
                    value={value}
                    step="any"
                />
                <Form.Control.Feedback type="invalid">
                    {validationLabel}
                </Form.Control.Feedback>
            </div>
            )
    }

}