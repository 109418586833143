import React, { Component } from 'react';

export class ResourcesPage extends Component {
    displayName = ResourcesPage.name;

    render() {
      return (
        <div>
          <h3>Documentation</h3>
          <span><a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/manobs-surface-observations.html" target="_blank" rel="noopener noreferrer">Manual of Surface Weather Observation Standards (8th Edition)</a></span> < br/>
          <span><a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/manab-word-abbreviations-4th-edition.html" target="_blank" rel="noopener noreferrer">Manual of Word Abbreviations (4th edition)</a></span> < br />
          <span><a href="https://www.canada.ca/en/environment-climate-change/services/weather-manuals-documentation/marine-observations.html" target="_blank" rel="noopener noreferrer">Manual of marine weather observations (8th Edition)</a></span> < br />
          <span><a href="https://cloudatlas.wmo.int/en/home.html" target="_blank" rel="noopener noreferrer">WMO Cloud Atlas</a></span><br/>
          <span><a href="https://nimbus-public.s3.amazonaws.com/WebResources/documents/WMOCodeTable4678.pdf" target="_blank" rel="noopener noreferrer" >WMO Table 4678 - Significant present and forecast weather</a> </span>
        </div>
      );
    }
}