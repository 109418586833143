import moment from 'moment';


function isNumber(value) {
    return typeof value == 'number' && !isNaN(value);
}

function calculateMeanSeaLevelPressure(stationPressure, temperature, temperatureTwelveHoursAgo, heightMeters, latDegrees) {
    let geopotentialHeight = physicalToGeopotentialHeight(heightMeters, latDegrees);
    let virtualTemperatureK = calculateVirtualTemperatureK(temperature + 273.15, temperatureTwelveHoursAgo + 273.15, geopotentialHeight);
    let mslp = stationPressure * Math.pow(Math.E, 9.81222 * 0.00341636 * geopotentialHeight / (virtualTemperatureK));
    return mslp;
}

function calculateVirtualTemperatureK(temperatureK, temperatureKTwelveHoursAgo, stationHeightGpm) {
    let averageTemperatureK = (temperatureK + temperatureKTwelveHoursAgo) / 2;
    let humidityCorrection = calculateHumidityCorrection(averageTemperatureK, stationHeightGpm);
    let plateauCorrection = calculatePlateauCorrection(averageTemperatureK);
    let lapseRateCorrection = calculateLapseRateCorrection(stationHeightGpm);
    return averageTemperatureK + humidityCorrection + plateauCorrection + lapseRateCorrection;
}

function calculateLapseRateCorrection(stationHeightGpm) {
    return -stationHeightGpm * -0.0065 / 2;
}

// Get these at some point
function calculatePlateauCorrection(averageTemperatureK) {
    const averageTemperatureC = averageTemperatureK - 273.15;
    const plateauCorrectionCoefficient2 = 0;
    const plateauCorrectionCoefficient1 = 0;
    const plateauCorrectionCoefficient0 = 0;

    return plateauCorrectionCoefficient2 * averageTemperatureC * averageTemperatureC +
        averageTemperatureC * plateauCorrectionCoefficient1 +
        plateauCorrectionCoefficient0;
}

function calculateHumidityCorrection(averageTemperatureK, stationHeightGpm) {
    const humidityCorrectionCoefficient2 = 2.8322e-9;
    const humidityCorrectionCoefficient1 = 2.225e-5;
    const humidityCorrectionCoefficient0 = 0.10743;
    const correction = humidityCorrectionCoefficient2 * stationHeightGpm * stationHeightGpm +
        humidityCorrectionCoefficient1 * stationHeightGpm +
        humidityCorrectionCoefficient0;
    const e = calculateMSCVapourPressure(averageTemperatureK);
    return correction * e;
}

function calculateMSCVapourPressure(averageTemperatureK) {
    const averageTemperatureC = averageTemperatureK - 273.15;
    const mscVapourPressureCoefficient2 = -0.00014;
    const mscVapourPressureCoefficient1 = 0.0116;
    const mscVapourPressureCoefficient0 = 0.279;
    const coefficient = mscVapourPressureCoefficient2 * averageTemperatureC * averageTemperatureC +
        mscVapourPressureCoefficient1 * averageTemperatureC +
        mscVapourPressureCoefficient0;

    return Math.pow(averageTemperatureK, coefficient);
}

function physicalToGeopotentialHeight(heightMeters, latDegrees) {
    const latRadians = latDegrees * Math.PI / 180;
    const cos2lat = Math.cos(2 * latRadians);
    const cos4lat = Math.cos(4 * latRadians);

    const gravityGradient = 3.085462 * 10 ** -6 + (2.27 * 10 ** -9) * cos2lat - (2 * 10 ** -12) * cos4lat;

    const gravity = 9.80616 * (1 - 0.0026373 * cos2lat + 0.0000059 * cos2lat * cos2lat);
    const r = 2 * gravity / gravityGradient;
    const geoheight = gravity * r / 9.8 * (heightMeters / (r + heightMeters));
    return geoheight;
}

function calculateSatVapourPresOverWaterPa(temperatureK) {
    const dryBulbCelsius = temperatureK - 273.15;
    const es = 6.1078 * Math.exp(19.8 * dryBulbCelsius / (273. + dryBulbCelsius));
    return 100 * es; // convert to pascals
}

function calculateDewpointCelsius(relativeHumidity, temperatureC) {
    const temperatureK = temperatureC + 273.15;
    const coef = 19.8;
    const factor = 610.78;
    const es = calculateSatVapourPresOverWaterPa(temperatureK);
    const e = relativeHumidity * es / 100;
    const temperature = Math.log(e / factor);
    const dpt = parseFloat((273. * temperature / (coef - temperature)).toFixed(1));
    if (dpt > 0 && dpt < 1){
        return dpt;
    }
    else{
        return dpt.toFixed(1);
    }
}

function SatVapourPresOverIceMb(temperatureC) {
    return 6.1078 * Math.exp(21.875 * temperatureC / (265.5 + temperatureC));
}

function SatVapourPresOverWaterMb(temperatureC) {
    return 6.1078 * Math.exp(17.27 * temperatureC / (273.3 + temperatureC));
}

function calculateRelativeHumidity(dryBulbC, wetBulbC, meanSeaLevelPressureMB) {
    let ew = 0;
    let a = 0;
    if (wetBulbC < 0) {
        a = 0.00056759;
        ew = SatVapourPresOverIceMb(wetBulbC);
    } else {
        a = 0.00064309;
        ew = SatVapourPresOverWaterMb(wetBulbC);
    }
    const et = SatVapourPresOverWaterMb(dryBulbC);
    const ed = ew - a * meanSeaLevelPressureMB * (dryBulbC - wetBulbC);
    return 100 * ed / et;
}

function rangeCheck(min, max, value) {
    // Check this..
    return min <= value && value <= max;
}

function precisionCheck(regex, value) {
    return !!value && !!(value.toString().match(regex));
}

function temporalLimitCheck(limits, parameter, value, observation, previousObservations) {
    if (previousObservations.length > 0) {
        const startDateTime = moment.utc(observation);
        for (let i = 0; i < 6; i++) {
            const previousHourObservation = previousObservations.find((ob) => { 
                const timeDifference = Math.abs(startDateTime.diff(moment.utc(ob.dateTime), 'hours'));
                return parseInt(timeDifference) == (i+1);
            });
            if (typeof previousHourObservation === 'undefined') { continue; }
            if (previousHourObservation.hasOwnProperty(parameter)) {
                const valueDifference = Math.abs(parseFloat(value) - parseFloat(previousHourObservation[parameter]));
                if (valueDifference > limits[i]) {
                    return false;
                }
            }
        }
    }
    return true;
}

function validWeatherCodeCheck(value, collection) {
    return (value in collection);
}

function windShift(observation, previousObservation) {
    const previousObservationTime = moment.utc(previousObservation.dateTime);
    const timeDifferenceInMinutes = Math.abs(moment.utc(observation.dateTime).diff(previousObservationTime, 'minutes'));
    if (!previousObservation.hasOwnProperty('twoMinuteMeanWindDirection')) { return false; }
    const difference = Math.abs((parseInt(observation.twoMinuteMeanWindDirection) - parseInt(previousObservation.twoMinuteMeanWindDirection)) % 360);
    const windDirectionChange = difference > 180 ? 360 - difference : difference;
    
    if (parseFloat(previousObservation.twoMinuteMeanWindSpeed) > 10 && windDirectionChange > 45 && timeDifferenceInMinutes < 15) {
        return true;
    }
    return false;
}

function visibilitySpeciRequired(currentVisibility, previousObservation) {
    const visibilityChanges = [6, 3, 1.5, 1, 0.75, 0.5, 0.25, 0]; //visibility changes that require a SPECI report

    if (!previousObservation.hasOwnProperty('visibility')) { return false; }
    for (let i = 0; i < visibilityChanges.length; i++) {
        const speciValue = parseFloat(visibilityChanges[i]);
        if (parseFloat(previousObservation.visibility) > speciValue && parseFloat(currentVisibility) < speciValue) { //checking if visibility decreased past limits specified
            return true;
        } else if (parseFloat(previousObservation.visibility) < speciValue && parseFloat(currentVisibility) >= speciValue) { //checking if visibility increased to or past limits specified
            return true;
        }
    }
    return false;
}

function ceilingSpeciRequired(value, previousObservation) {
    const ceilingChanges = [1500, 1000, 500, 300, 200, 100]; //ceiling changes that require a SPECI report
    const previousSkyValue = previousObservation.cloudHeightAmt;
    const decodedpreviousCloudHeight = parseInt(previousSkyValue.substring(previousSkyValue.length - 3)) * 100; //extracting last three numbers from sky value to get cloud height in ft
    const currentdecodedCloudHeight = parseInt(value.substring(value.length - 3)) * 100;

    for (let i = 0; i < ceilingChanges.length; i++) {
        const speciValue = parseInt(ceilingChanges[i]);

        if (decodedpreviousCloudHeight > speciValue && currentdecodedCloudHeight < speciValue) {
            return true;
        } else if (decodedpreviousCloudHeight < speciValue && currentdecodedCloudHeight >= speciValue) {
            return true;
        }
    }
    return false;
}

function flagLargeValueChange(parameter, maxChangeAllowed, timeIntervalMinutes, observation, previousObservation) {

    const previousDateTime = moment.utc(previousObservation.dateTime);
    const currentDateTime = moment.utc(observation.dateTime);
    const timeDifference = Math.abs(currentDateTime.diff(previousDateTime,'minutes'));
    const parameterChange = Math.abs(parseFloat(observation[parameter]) - parseFloat(previousObservation[parameter]));

    if (timeIntervalMinutes != null) {
        if (parameterChange > maxChangeAllowed && timeDifference <= timeIntervalMinutes) {
            return true;
        }
    } else if (parameterChange > maxChangeAllowed) {
        return true;
    }
    return false;
}

function calculateWindWaveHeight(significantWaveHeightValue, primarySwellHeightValue, secondarySwellHeightValue) {
    if (isNaN(secondarySwellHeightValue)) {
        secondarySwellHeightValue = 0;
    }
    const temp = Math.sqrt((Math.pow(significantWaveHeightValue,2)) - (Math.pow(primarySwellHeightValue,2)) - (Math.pow(secondarySwellHeightValue,2)));
    return temp;
}
//Used for latitudes and longitudes in MANICE
function digitsCount(n) {
    var count = 0;
    if (n >= 1){
        ++count;
    } 
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
    return count;
  }
function cloudHeightAmtCalc(observation){
    //totalAmount =  The total amount of cloud that is being considered at each level
    var totalAmount = 0;
    const amountArr = ["FEW","FEW","FEW","SCT","SCT","BKN","BKN","BKN","OVC"];
    var amount1 = parseInt(observation.cloudAmountLevel1);
    var type1 = observation.cloudTypeLevel1;
    //trace will record the previous Cloud type in order to bring in the Trace clouds functionality
    var trace = "";
    var height1 = observation.cloudHeightLevel1/100;
    if (digitsCount(height1) == 2){
        height1 = "0" + height1;
    }
    if (digitsCount(height1) == 1){
        height1 = "00" + height1;
    }
    var rem = "";
    if (type1 === "SN" || type1 === "FG" ){
        totalAmount = totalAmount + amount1; 
        rem = "VV" + height1;
    }
    else if (type1 === "BR"){
        rem = "VV" + height1;        
    }
    else if (amount1 === -1){
        amount1 = 0;
        totalAmount = totalAmount + amount1;
        rem = "FEW" + height1;
    }
    else {
        totalAmount = totalAmount + amount1;
        if(totalAmount === 0){
            rem = "SKC";
        }
        else if(totalAmount === 1 || totalAmount === 2){
            rem = "FEW"+ height1;
        }
        else if(totalAmount === 3 || totalAmount === 4){
            rem = "SCT"+ height1;
        }
        else if(totalAmount === 5 || totalAmount === 6 || totalAmount === 7){
            rem = "BKN"+ height1;
        }
        else if (totalAmount === 8){
            rem = "OVC"+ height1;
        }
    }
    //Only for CB and TCU 
    if(type1 === "CB" || type1 === "TCU"){
        rem = rem + type1;
    }
    //Only for the special case when there is one cloud layer but two cloud types
    if(observation.cloudHeightLevel1 === undefined && (type1 === "SN" || type1 === "FG")) {
        trace = "FEW";
        rem = "";
    }
    //Only for Sky Clear 
    if(observation.cloudHeightLevel1 === undefined && observation.cloudAmountLevel1 === undefined && observation.cloudTypeLevel1 === "SKC"){
        rem = "SKC";
    }

    trace = amountArr[totalAmount];

    rem = rem + " ";
    var amount2 = parseInt(observation.cloudAmountLevel2);
    var type2 = observation.cloudTypeLevel2;
    var height2 = observation.cloudHeightLevel2/100;
    if (digitsCount(height2) == 2){
        height2 = "0"+height2;
    }
    if (digitsCount(height2) == 1){
        height2 = "00" + height2;
    }
    if (type2 === "SN" || type2 === "FG"){
        totalAmount = totalAmount + amount2;
        rem = rem + "VV" + height2;
    }
    if(type2 === "BR"){
        rem = rem + "VV" + height2;        
    }
    else if (amount2 === -1){
        amount2 =  0;
        totalAmount = totalAmount + amount2;
        rem = rem + trace + height2;
    }
    else{
        totalAmount = totalAmount + amount2;
        if(totalAmount === 0){
            rem = rem + "SKC"+ height2;
        }
        else if(totalAmount === 1 || totalAmount === 2){
            rem = rem + "FEW"+ height2;
        }
        else if(totalAmount === 3 || totalAmount === 4){
            rem = rem + "SCT"+ height2;
        }
        else if(totalAmount === 5 || totalAmount === 6 || totalAmount === 7){
            rem = rem + "BKN"+ height2;
        }
        else if (totalAmount >= 8){
            rem = rem + "OVC"+ height2;
        }
    }
    //Only for CB and TCU 
    if(type2 === "CB" || type2 === "TCU"){
        rem = rem + type2;
    }

    //Only for Sky Clear 
    if(observation.cloudHeightLevel1 === undefined && observation.cloudHeightLevel2  === undefined && observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 < 8 && observation.cloudTypeLevel2){
        rem = "SKC";
    }

    if(totalAmount >= 8){
        trace = amountArr[8];
    }
    else{
        trace = amountArr[totalAmount];
    }

    rem = rem + " ";
    var amount3 = parseInt(observation.cloudAmountLevel3);
    var type3 = observation.cloudTypeLevel3;
    var height3 = observation.cloudHeightLevel3/100;
    if (digitsCount(height3) == 2){
        height3 = "0"+height3;
    }
    if (digitsCount(height3) == 1){
        height3 = "00" + height3;
    }
    if (type3 === "SN" || type3 === "FG" ){
        totalAmount = totalAmount + amount3;
        rem = rem + "VV" + height3;
    }
    if( type3 === "BR" ){
        rem = rem + "VV" + height3;
    }
    else if (amount3 === -1){
        amount3 =  0;
        totalAmount = totalAmount + amount3;
        rem = rem + trace + height3;
    }
    else{
        totalAmount = totalAmount + amount3;
        if(totalAmount === 0){
            rem = rem + "SKC"+ height3;
        }
        else if(totalAmount === 1 || totalAmount === 2){
            rem = rem + "FEW"+ height3;
        }
        else if(totalAmount === 3 || totalAmount === 4){
            rem = rem + "SCT"+ height3;
        }
        else if(totalAmount === 5 || totalAmount === 6 || totalAmount === 7){
            rem = rem + "BKN"+ height3;
        }
        else if (totalAmount >= 8){
            rem = rem + "OVC"+ height3;
        }
    }
    //Only for CB and TCU 
    if(type3 === "CB" || type3 === "TCU"){
        rem = rem + type3;
    }

    if(totalAmount >= 8){
        trace = amountArr[8];
    }
    else{
        trace = amountArr[totalAmount];
    }

    rem = rem + " ";
    var amount4 = parseInt(observation.cloudAmountLevel4);
    var type4 = observation.cloudTypeLevel4;
    var height4 = observation.cloudHeightLevel4/100;
    if (digitsCount(height4) == 2){
        height4 = "0"+height4;
    }
    if (digitsCount(height4) == 1){
        height4 = "00" + height4;
    }
    if (type4 === "SN" || type4 === "FG" ){
        totalAmount = totalAmount + amount4;
        rem = rem + "VV" + height4;
    }
    if(type4 === "BR"){
        rem = rem + "VV" + height4;
    }
    else if (amount4 === -1){
        amount4 =  0;
        totalAmount = totalAmount + amount4;
        rem = rem + trace + height4;
    }
    else{
        totalAmount = totalAmount + amount4;
        if(totalAmount === 0){
            rem = rem + "SKC"+ height4;
        }
        else if(totalAmount === 1 || totalAmount === 2){
            rem = rem + "FEW"+ height4;
        }
        else if(totalAmount === 3 || totalAmount === 4){
            rem = rem + "SCT"+ height4;
        }
        else if(totalAmount === 5 || totalAmount === 6 || totalAmount === 7){
            rem = rem + "BKN"+ height4;
        }
        else if (totalAmount >= 8){
            rem = rem + "OVC"+ height4;
        }
    }
    //Only for CB and TCU 
    if(type4 === "CB" || type4 === "TCU"){
        rem = rem + type4;
    }

    return rem;
}

function cloudTypeAmtCalc(observation){
    let type1 = observation.cloudTypeLevel1;
    let opacity1 = observation.cloudOpacityLevel1;
    let type2 = observation.cloudTypeLevel2;
    let opacity2 = observation.cloudOpacityLevel2;
    let type3 = observation.cloudTypeLevel3;
    let opacity3 = observation.cloudOpacityLevel3;
    let type4 = observation.cloudTypeLevel4;
    let opacity4 = observation.cloudOpacityLevel4;
    var rem="";
    const typeArr = ["CB","TCU"];
    //let negOpacity = [];
    if (type1 === "BR"){type1 = "FG";}
    if (type2 === "BR"){type2 = "FG";}
    if (type3 === "BR"){type3 = "FG";}
    if (type4 === "BR"){type4 = "FG";}
    //if (opacity1 === "-1"){negOpacity.push(opacity1);} 
    //if (opacity2 === "-1"){negOpacity.push(opacity2);}
    //if (opacity3 === "-1"){negOpacity.push(opacity3);}
    //if (opacity4 === "-1"){negOpacity.push(opacity4)}
    if (type1 && opacity1){
        if(type1 === "SKC"){
            rem = "";
        }
        else{
            rem = type1+ Math.abs(opacity1);
        }
    }
    if (type2 && opacity2){
        if((observation.cloudHeightLevel1 && observation.cloudHeightLevel2) && observation.cloudHeightLevel1 === observation.cloudHeightLevel2 && (typeArr.includes(type1) || typeArr.includes(type2))){
            var dominantCloud =  type1 === "CB"? "CB" : (type2 === "CB" ? "CB" : "TCU" );
            rem = dominantCloud + Math.abs((parseInt(opacity1)) + Math.abs(parseInt(opacity2)));
        }
        else{
            rem = rem +type2+ Math.abs(opacity2);
        }
    }
    if (type3 && opacity3){
        if((observation.cloudHeightLevel2 && observation.cloudHeightLevel3) && observation.cloudHeightLevel2 === observation.cloudHeightLevel3 && (typeArr.includes(type2) || typeArr.includes(type3))){
            var dominantCloud =  type2 === "CB"? "CB" : (type3 === "CB" ? "CB" : "TCU" );
            rem = type1+opacity1 + dominantCloud + Math.abs((parseInt(opacity2))) + Math.abs(parseInt(opacity3));
        }
        else{
            rem = rem +type3+ Math.abs(opacity3);
        }
    }
    if (type4  && opacity4){
        if((observation.cloudHeightLevel3 && observation.cloudHeightLevel4) && observation.cloudHeightLevel3 === observation.cloudHeightLevel4 && (typeArr.includes(type3) || typeArr.includes(type4))){
            var dominantCloud =  type3 === "CB"? "CB" : (type4 === "CB" ? "CB" : "TCU" );
            rem = type1+opacity1 + type2+opacity2 + dominantCloud + Math.abs((parseInt(opacity3))) + Math.abs(parseInt(opacity4));
        }
        else{
            rem = rem +type4+ Math.abs(opacity4);
        }
    }
    return rem;
}

function totalCloudOpacity(observation){
    let opacity1 = observation.cloudOpacityLevel1;
    let opacity2 = observation.cloudOpacityLevel2;
    let opacity3 = observation.cloudOpacityLevel3;
    let opacity4 = observation.cloudOpacityLevel4;
    var total = 0;
    if (opacity1 === "-1"){opacity1 = "0";}
    if (opacity2 === "-1"){opacity2 = "0";}
    if (opacity3 === "-1"){opacity3 = "0";}
    if (opacity4 === "-1"){opacity4 = "0";}
    if (opacity1){
        total = parseInt(opacity1);
        if((observation.cloudTypeLevel1 === "FG" || observation.cloudTypeLevel1 === "SN") && total === 8){
            total = 9;
        }
    }
    if (opacity2){
        total = parseInt(opacity1) + parseInt(opacity2);
    }
    if (opacity3){
        total = parseInt(opacity1) + parseInt(opacity2) + parseInt(opacity3);
    }
    if (opacity4){
        total = parseInt(opacity1) + parseInt(opacity2) + parseInt(opacity3) + parseInt(opacity4);
    }
    return total;
}

function totalAmountCloud(observation){
    let amount1 = observation.cloudAmountLevel1;
    let amount2 = observation.cloudAmountLevel2;
    let amount3 = observation.cloudAmountLevel3;
    let amount4 = observation.cloudAmountLevel4;
    var total = 0;
    if (amount1 === "-1"){amount1 = "0";}
    if (amount2 === "-1"){amount2 = "0";}
    if (amount3 === "-1"){amount3 = "0";}
    if (amount4 === "-1"){amount4 = "0";}
    if (amount1){
        total = parseInt(amount1);
        if((observation.cloudTypeLevel1 === "FG" || observation.cloudTypeLevel1 === "SN") && total === 8){
            total = 9;
        }
    }
    if (amount2){
        total = parseInt(amount1) + parseInt(amount2);
    }
    if (amount3){
        total = parseInt(amount1) + parseInt(amount2) + parseInt(amount3);
    }
    if (amount4){
        total = parseInt(amount1) + parseInt(amount2) + parseInt(amount3) + parseInt(amount4);
    }
    return total;
}

function lowestCloudCover(observation){
    const height1 = observation.cloudHeightLevel1;
    const height2 = observation.cloudHeightLevel2;
    const height3 = observation.cloudHeightLevel3;
    const height4 = observation.cloudHeightLevel4;
    var total = 0;
    // When the low clouds are present 
    if (height1 && (height1 > 0 && height1 <= 6500)){
        total = total + parseInt(observation.cloudAmountLevel1);
    }
    if (height2 && (height2 > 0 && height2 <= 6500)){
        total = total + parseInt(observation.cloudAmountLevel2);
    }
    if (height3 && (height3 > 0 && height3 <= 6500)){
        total = total + parseInt(observation.cloudAmountLevel3);
    }
    if (height4 && (height4 > 0 && height4 <= 6500)){
        total = total + parseInt(observation.cloudAmountLevel4);
    }

    // When there are no low clouds present
    // The first if condition is an additional check which wont allow to affect the lowest cloud amount if height 2,3,4 become
    // greter than 6500
    if (height1 > 6500){
        if (height1 && (height1 > 6500 && height1 <= 20000)){
            total = total + parseInt(observation.cloudAmountLevel1);
        }
        if (height2 && (height2 > 6500 && height2 <= 20000)){
            total = total + parseInt(observation.cloudAmountLevel2);
        }
        if (height3 && (height3 > 6500 && height3 <= 20000)){
            total = total + parseInt(observation.cloudAmountLevel3);
        }
        if (height4 && (height4 > 6500 && height4 <= 20000)){
            total = total + parseInt(observation.cloudAmountLevel4);
        }
    }
    return total;
}

// In order of required appearance when multiple codes provided
const wxIntensities = {
    "-": 3, // Light
    "+": 1, // Heavy
    "VC": 4 // In the Vicinity (used when present weather within 5-10 statue miles of point of observation)
}; // Moderate intensity denoted by no entry/symbol (will have a value of 2 in the order of decreasing intensity)

const wxDescriptors = {
    "MI": 3, // Shallow
    "PR": 3, // Partial
    "BC": 3, // Patches
    "DR": 3, // Low Drifting
    "BL": 3, // Blowing
    "SH": 3, // Shower(s)
    "TS": 2, // Thunderstorm
    "FZ": 3 // Freezing
};

const wxPhenomena = {
    "FC": 1, // Funnel Cloud, Tornado, Waterspout (Funnel Cloud & Waterspouts always coded as +FC)
    "SH": 3, // Showers
    "RA": 3, // Rain
    "DZ": 3, // Drizzle
    "SN": 4, // Snow
    "SG": 4, // Snow Grains
    "IC": 5, // Ice Crystals
    "PL": 5, // Ice Pellets
    "GR": 5, // Hail > 5mm diameter
    "GS": 5, // Small Hail and/or Snow Pellets
    "UP": 6, // Unknown Precipitation
    "BR": 7, // Mist
    "FG": 7, // Fog
    "FU": 7, // Smoke
    "VA": 7, // Volcanic Ash
    "DU": 7, // Widespread Dust
    "SA": 7, // Sand
    "HZ": 7, // Haze
    "PY": 7, // Spray
    "PO": 8, // Well-developed Dust/Sand Whirls
    "SQ": 8, // Squalls
    "SS": 8, // Sandstorm
    "DS": 8  // Duststorm
};

const wxPrecipitations = [
    "DZ",
    "RA",
    "SN",
    "SG",
    "IC",
    "PL",
    "GR",
    "GS"
];

const wxObscurations = [
    "BR",
    "FG",
    "FU",
    "VA",
    "DU",
    "SA",
    "HZ",
    "PY",
    "SN",
    "GS",
    "SG",
    "DZ",
    "FZDZ",
    "MIFG",
    "BCFG",
    "VCFG"
]

const observationParameters = {
    blank_manmar: {
        inputType: 'blank',
    },
    blank_metar: {
        inputType: 'blank',
    },
    twoMinuteMeanWindSpeed: {
        inputType: 'number',
        name: 'Speed (2 min mean)',
        minValue: 0,
        maxValue: 200,
        checks: {
            '0 to 200 kts': rangeCheck.bind(this, 0, 200),
            'Gusts must be at least 5 kts more than winds': (windSpeed, observation) => { 
                if ('twoMinuteGustSpeed' in observation) {
                    if(observation.twoMinuteGustSpeed === undefined){
                        return true;
                    }
                    const windSpeedValue = parseInt(windSpeed);
                    const gustValue = parseInt(observation.twoMinuteGustSpeed);
                    return (windSpeedValue + 5) <= gustValue;
                }                
                return true;
            },
        },
        required: true
    },
    twoMinuteMeanWindDirection: {
        inputType: 'text',
        name: 'Direction (2 min mean)',
        minValue: 0,
        maxValue: 360,
        checks: {
            '000° to 360°':(windDirection, observation)=>{
                if(windDirection === "VRB"){
                    return true;
                }
                if(rangeCheck(0, 360, windDirection) === true)
                    return true;
                else{
                    return false;
                }
            },
            'Value must contain three digits': (windDirection, observation) => {
                if (windDirection === "VRB"){
                    return true;
                }
                if(precisionCheck(/^\d{3}/, (windDirection)))
                    return true;
                else{
                    return false;
                }
            },
            'Direction must be a multiple of 10': (windDirection,observation, value) =>{
                if (windDirection === "VRB") {
                    return true;
                }
                if(windDirection % 10 === 0) {
                    return true;
                 }
                else{ 
                    return false;
                }
            },
            'Winds < 3kts are considered calm': (windDirection, observation) => {
                if (windDirection === "VRB") {
                    if (observation.twoMinuteMeanWindSpeed < 3) {
                        return false;
                    }
                }
                return true;
            },
            'Winds must be 000 when speeds are <= 2': (windDirection, observation) => {

                if ('twoMinuteMeanWindDirection' in observation && windDirection !=="VRB") {
                    const windSpeedValue = parseInt(observation.twoMinuteMeanWindSpeed);
                    const windDirectionValue = parseInt(windDirection);
                    if (windSpeedValue <= 2) {
                        return windDirectionValue === 0;
                    }
                }
                return true;
            }
        },
        warnings: {
            'Wind shift not reported in SPECI': (windDirection, observation, previousObservations) => {
                if (previousObservations.length != 0 && observation.windDirection !== "VRB") {
                    const latestPreviousObservation = previousObservations[0];
                    const speciReport = moment.utc(observation.dateTime).minute() === 0 ? false : true;
                    if (!speciReport && windShift(observation, latestPreviousObservation)) {
                        return false;
                    }
                }
                return true;
            }
        },
        required: true
    },
    windEstimation: {
        inputType: 'checkbox',
        name: 'Wind Estimated',
        parameterRelationships: {
            observerRemarks:(windEstimation,observation) => {
                var temp = observation.observerRemarks;
                if (windEstimation){
                    if(temp){
                        return "WND ESTD" + " " + temp;
                    }
                    return "WND ESTD";
                }
                else if (!windEstimation){
                    temp = observation.observerRemarks.split(' ');
                    var finalString = "";
                    for (var i  =  0; i < temp.length; i++){
                        if(temp[i] !== "WND" && temp[i] !== "ESTD"){
                            finalString = finalString + temp[i];
                        }
                    }
                    return finalString;
                }
            },
            twoMinuteMeanWindSpeed:(windEstimation,observation) => {
                if (windEstimation){
                    return Math.round(observation.twoMinuteMeanWindSpeed/5)*5;
                }
                else if (!windEstimation){
                    return observation.twoMinuteMeanWindSpeed;
                }
            },  
            tenMinuteMeanWindSpeed:(windEstimation,observation) => {
                if (windEstimation){
                    return Math.round(observation.tenMinuteMeanWindSpeed/5)*5;
                }
                else if (!windEstimation){
                    return observation.tenMinuteMeanWindSpeed;
                }
            },         
        }
    },
    twoMinuteVariableWindDirection: {
        inputType: 'text',
        name: 'Wind Direction Range',
        checks: {
            'Winds < 3kts are considered calm': (windDirection, observation) => {
                if (windDirection === "VRB") {
                    if (observation.twoMinuteMeanWindSpeed < 3) {
                        return false;
                    }
                }
                return true;
            },
        },
    },
    twoMinuteGustSpeed: {
        inputType: 'number',
        name: 'Gust',
        minValue: 15,
        maxValue: 250,
        checks: {
            '15 to 250 kts': rangeCheck.bind(this, 15, 250),
            'Incorrect precision - must be a whole number': precisionCheck.bind(this, /^\d{1,3}$/),
            'Gusts must be at least 5 kts more then winds': (gustSpeed, observation) => {
                if ('twoMinuteMeanWindSpeed' in observation) {
                    const windSpeedValue = parseInt(observation.twoMinuteMeanWindSpeed);
                    const gustValue = parseInt(gustSpeed);
                    return (windSpeedValue + 5) <= gustValue;
                }
                return true;
            }
        },
        displayPrecision: 0
    },
    tenMinuteMeanWindSpeed: {
        inputType: 'number',
        name: 'Speed (10 minute mean)',
        minValue: 0,
        maxValue: 200,
        checks: {
            '0 to 200 kts': rangeCheck.bind(this, 0, 200),
        },
        required: true
    },
    tenMinuteMeanWindDirection: {
        inputType: 'text',
        name: 'Direction (10 min mean)',
        minValue: 0,
        maxValue: 360,
        checks: {
            '000° to 360°': (windDirection, observation) => {
                if (windDirection === "VRB") {
                    return true;
                }
                if (rangeCheck(0, 360, windDirection) === true)
                    return true;
                else {
                    return false;
                }
            },
            'Direction must be a multiple of 10' : (windDirection,observation, value) =>{
                if (windDirection === "VRB") {
                    return true;
                }
                if (windDirection % 10 !== 0) {
                    return false;
                }
                return true;
            },
            'VRB cannot be reported if speed is greater than 5kts': (windDirection, observation) => {
                if (windDirection === "VRB") {
                    if (observation.tenMinuteMeanWindSpeed > 5) {
                        return false;
                    }
                }
                return true;
            },
        },
        required: true
    },
    cloudHeightLevel1: {
        inputType: 'number',
        name: 'Cloud Height',
        minValue: 100,
        maxValue: 39000,
        checks: {
            '100 ft to 39000 ft': rangeCheck.bind(this, 100, 39000),
        },
        parameterRelationships: {
            baseLowestCloud: (cloudHeightLevel1,observation) =>{
                let height = observation.cloudHeightLevel1;
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
                else{
                    if (height <= 160){
                        return '0';
                    }
                    else if (height > 160 && height <= 330){
                        return '1';
                    }
                    else if (height > 330 && height <= 650){
                        return '2';
                    }
                    else if (height > 650 && height <= 1000){
                        return '3';
                    }
                    else if (height > 1000 && height <= 2000){
                        return '4';
                    }
                    else if (height > 2000 && height <= 3300){
                        return '5';
                    }
                    else if (height > 3300 && height <= 4900){
                        return '6';
                    }
                    else if (height > 4900 && height <= 6600){
                        return '7';
                    }
                    else if (height > 6600 && height <= 8200){
                        return '8';
                    }
                    else if (height > 8200){
                        return '9';
                    }                    
                }
            },
            cloudHeightAmt : (cloudHeightLevel1,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            totalCloudCover: (cloudHeightLevel1,observation) =>{
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return "9";
                }
            },
        },
        required: false,
    },
    cloudTypeLevel1: {
        inputType: 'select',
        name: 'Cloud Type',
        options: [
            ['CI','Cirrus - CI'],
            ['CC','Cirrocumulus - CC'],
            ['CS','Cirrostratus - CS'],
            ['AC','Altocumulus - AC'],
            ['ACC','Altocumulus Castellanus - ACC'],
            ['AS','Altostratus - AS'],
            ['NS','Nimbostratus - NS'],
            ['ST','Stratus - ST'],
            ['SF','Stratus Fractus - SF'],
            ['SC','Stratocumulus - SC'],
            ['CU','Cumulus - CU'],
            ['CF','Cumulus Fractus - CF'],
            ['TCU','Towering Cumulus - TCU'],
            ['CB','Cumulonimbus - CB'],
            ['FG','Fog - FG'],
            ['BR','Mist - BR'],
            ['SN','Snow - SN'],
            ['SKC','Sky Clear - SKC']
        ],
        checks: {
            'Invalid Selection': (cloudType, observation) => {
                const cloudHeight = observation.cloudHeightLevel1;
                if(cloudType === "SKC" && cloudHeight === undefined){
                    return true;
                }
                if (cloudType === "SN" && cloudHeight === undefined){
                    return true;
                }
                if (cloudType === "FG" && cloudHeight === undefined){
                    return true;
                }
                if (cloudType === "FG" || cloudType === "SN" || cloudType === "BR"){
                    if(cloudHeight >= 0 && cloudHeight <= 10000){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 0 && cloudHeight <= 6500){
                    if(cloudType === "CB" || cloudType === "TCU" || cloudType === "CF" || cloudType === "CU"|| cloudType === "SC"|| cloudType === "SF"|| cloudType === "ST" ){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 6500 && cloudHeight <= 20000){
                    if(cloudType === "NS" || cloudType === "AS" || cloudType === "ACC" || cloudType === "AC"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 20000 && cloudHeight <= 39000){
                    if(cloudType === "CS" || cloudType === "CC" || cloudType === "CI"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            'Weather and Obstruction field does not match the Cloud Type': (weather, observation) =>{
                const type = observation.cloudTypeLevel1;
                var temp ="";
                if(observation.metarWeather){
                    temp = observation.metarWeather.split(" ");
                }
                if ('cloudTypeLevel1' in observation){
                    if(temp.includes('FG') || temp.includes('SN')){
                        if (temp.includes(type)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                }
                return true;
            },
        },
        required: false,
        parameterRelationships: {
            cloudHeightAmt : (cloudTypeLevel1,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudTypeLevel1,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
            cloudCover : (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
            },
            baseLowestCloud: (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "9";
                }
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
                else{
                    let height = observation.cloudHeightLevel1;
                    if (height <= 160){
                        return '0';
                    }
                    else if (height > 160 && height <= 330){
                        return '1';
                    }
                    else if (height > 330 && height <= 650){
                        return '2';
                    }
                    else if (height > 650 && height <= 1000){
                        return '3';
                    }
                    else if (height > 1000 && height <= 2000){
                        return '4';
                    }
                    else if (height > 2000 && height <= 3300){
                        return '5';
                    }
                    else if (height > 3300 && height <= 4900){
                        return '6';
                    }
                    else if (height > 4900 && height <= 6600){
                        return '7';
                    }
                    else if (height > 6600 && height <= 8200){
                        return '8';
                    }
                    else if (height > 8200){
                        return '9';
                    }                    
                }
            },
            totalCloudCover: (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
                if (cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return "9";
                }
            },
            lowCloudType: (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
                if (cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
            mediumCloudType: (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
                if (cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
            highCloudType: (cloudTypeLevel1,observation) =>{
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
                if (cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
            cloudAmountLevel1: (cloudTypeLevel1, observation) => {
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
            },
            cloudOpacityLevel1: (cloudTypeLevel1, observation) => {
                if(cloudTypeLevel1 === "SKC"){
                    return "0";
                }
            },
        },
    },
    cloudAmountLevel1: {
        inputType: 'select',
        name: 'Cloud Amount',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        required: false,
        parameterRelationships: {
            cloudHeightAmt : (cloudAmountLevel1,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudAmountLevel1,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
            cloudCover: (cloudAmountLevel1,observation) =>{
                let ct = observation.cloudTypeLevel1;
                let amount = totalAmountCloud(observation);
                if (amount === 0){
                    return "0";
                }
                else if (amount === 1){
                    return "1";
                }
                else if (amount === 2){
                    return "2";
                }
                else if (amount === 3){
                    return "3";
                }
                else if (amount === 4){
                    return "4";
                }
                else if (amount === 5){
                    return "5";
                }
                else if (amount === 6){
                    return "6";
                }
                else if (amount === 7){
                    return "7";
                }
                else if (amount === 8){
                    return "8";
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
            },
            totalCloudCover: (cloudAmountLevel1,observation) =>{
                const amount = lowestCloudCover(observation);
                const ct = observation.cloudTypeLevel1;
                if (amount === 0){
                    return '0';
                }
                else if (amount === 1){
                    return '1';
                }
                else if (amount === 2){
                    return '2';
                }
                else if (amount === 3){
                    return '3';
                }
                else if (amount === 4){
                    return '4';
                }
                else if (amount === 5){
                    return '5';
                }
                else if (amount === 6){
                    return '6';
                }
                else if (amount === 7){
                    return '7';
                }
                else if (amount === 8){
                    if (ct === "FG"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
            },
            baseLowestCloud: (cloudAmountLevel1,observation) =>{
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
                else{
                    let height = observation.cloudHeightLevel1;
                    if (height <= 160){
                        return '0';
                    }
                    else if (height > 160 && height <= 330){
                        return '1';
                    }
                    else if (height > 330 && height <= 650){
                        return '2';
                    }
                    else if (height > 650 && height <= 1000){
                        return '3';
                    }
                    else if (height > 1000 && height <= 2000){
                        return '4';
                    }
                    else if (height > 2000 && height <= 3300){
                        return '5';
                    }
                    else if (height > 3300 && height <= 4900){
                        return '6';
                    }
                    else if (height > 4900 && height <= 6600){
                        return '7';
                    }
                    else if (height > 6600 && height <= 8200){
                        return '8';
                    }
                    else if (height > 8200){
                        return '9';
                    }                    
                }
            },
            lowCloudType: (cloudAmountLevel1,observation) =>{
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
            mediumCloudType: (cloudAmountLevel1,observation) =>{
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
            highCloudType: (cloudAmountLevel1,observation) =>{
                if(observation.cloudTypeLevel1 === "FG" && observation.cloudAmountLevel1 === "8"){
                    return '/';
                }
            },
        },
        /*checks: {
            'Cloud Amount Total cannot be greater than 8': (cloudAmountLevel1,observation) =>{
                const total = totalAmountCloud(observation);
                if (total > 8){
                    if (observation.cloudTypeLevel1 === "FG" || observation.cloudTypeLevel1 === "SN") {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
                return true; 
            }
        },*/ 
    },
    cloudOpacityLevel1: {
        inputType: 'select',
        name: 'Cloud Opacity',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        checks: {
            'Cloud Opacity cannot exceed the Cloud Amount' : (cloudOpacityLevel1, observation) => {
                if (cloudOpacityLevel1 > observation.cloudAmountLevel1){
                    return false;
                }
                return true;
            },
            'Cloud Opacity Total cannot be greater than 8': (cloudOpacityLevel1,observation) =>{
                const total = totalCloudOpacity(observation);
                if (total > 8){
                    if (observation.cloudTypeLevel1 === "FG" || observation.cloudTypeLevel1 === "SN") {
                        return true;
                    }
                    else{
                        return false;
                    }
                } 
                return true; 
            }
        },
        parameterRelationships: {
            cloudTypeAmt : (cloudOpacityLevel1,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        }
    },
    cloudHeightLevel2: {
        inputType: 'number',
        name: 'Cloud Height',
        minValue: 0,
        maxValue: 39000,
        checks: {
            '100 ft to 39000 ft': rangeCheck.bind(this, 100, 39000),
        },
        required: false,
        parameterRelationships: {
            cloudHeightAmt : (cloudHeightLevel2,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
        },
    },
    cloudTypeLevel2: {
        inputType: 'select',
        name: 'Cloud Type',
        options: [
            ['CI','Cirrus - CI'],
            ['CC','Cirrocumulus - CC'],
            ['CS','Cirrostratus - CS'],
            ['AC','Altocumulus - AC'],
            ['ACC','Altocumulus Castellanus - ACC'],
            ['AS','Altostratus - AS'],
            ['NS','Nimbostratus - NS'],
            ['ST','Stratus - ST'],
            ['SF','Stratus Fractus - SF'],
            ['SC','Stratocumulus - SC'],
            ['CU','Cumulus - CU'],
            ['CF','Cumulus Fractus - CF'],
            ['TCU','Towering Cumulus - TCU'],
            ['CB','Cumulonimbus - CB'],
            ['FG','Fog - FG'],
            ['BR','Mist - BR'],
            ['SN','Snow - SN'],
            ['SKC','Sky Clear - SKC']
        ],
        checks: {
            'Invalid Selection': (cloudType, observation) => {
                const cloudHeight = observation.cloudHeightLevel2;
                if(cloudType === "SKC" && cloudHeight === undefined){
                    return true;
                }
                if (cloudType === "FG" || cloudType === "SN" || cloudType === "BR"){
                    if(cloudHeight >= 0 && cloudHeight <= 10000){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 0 && cloudHeight <= 6500){
                    if(cloudType === "CB" || cloudType === "TCU" || cloudType === "CF" || cloudType === "CU"|| cloudType === "SC"|| cloudType === "SF"|| cloudType === "ST" ){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 6500 && cloudHeight <= 20000){
                    if(cloudType === "NS" || cloudType === "AS" || cloudType === "ACC" || cloudType === "AC"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 20000 && cloudHeight <= 39000){
                    if(cloudType === "CS" || cloudType === "CC" || cloudType === "CI"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
        },
        parameterRelationships: {
            cloudHeightAmt : (cloudTypeLevel2,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudTypeLevel2,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
        required: false,
    },
    cloudAmountLevel2: {
        inputType: 'select',
        name: 'Cloud Amount',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        parameterRelationships: {
            cloudHeightAmt : (cloudAmountLevel2,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudAmountLevel2,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
            cloudCover: (cloudAmountLevel2,observation) =>{
                let ct = observation.cloudTypeLevel2;
                let amount = totalAmountCloud(observation);
                if (amount === 0){
                    return "0";
                }
                else if (amount === 1){
                    return "1";
                }
                else if (amount === 2){
                    return "2";
                }
                else if (amount === 3){
                    return "3";
                }
                else if (amount === 4){
                    return "4";
                }
                else if (amount === 5){
                    return "5";
                }
                else if (amount === 6){
                    return "6";
                }
                else if (amount === 7){
                    return "7";
                }
                else if (amount === 8){
                    return "8";
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return "9";
                    }
                    else{
                        return "8";
                    }
                }
            },
            totalCloudCover: (cloudAmountLevel2,observation) =>{
                const ct = observation.cloudTypeLevel2;
                const amount = lowestCloudCover(observation);
                if (amount === 0){
                    return '0';
                }
                else if (amount === 1){
                    return '1';
                }
                else if (amount === 2){
                    return '2';
                }
                else if (amount === 3){
                    return '3';
                }
                else if (amount === 4){
                    return '4';
                }
                else if (amount === 5){
                    return '5';
                }
                else if (amount === 6){
                    return '6';
                }
                else if (amount === 7){
                    return '7';
                }
                else if (amount === 8){
                    return '8';
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
            },
        },
 /*       checks: {
            'Cloud Amount Total cannot be greater than 8': (cloudAmountLevel2,observation) =>{
                const total = totalAmountCloud(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },*/
        required: false,
    },
    cloudOpacityLevel2: {
        inputType: 'select',
        name: 'Cloud Opacity',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        checks: {
            'Cloud Opacity cannot exceed the Cloud Amount' : (cloudOpacityLevel2, observation) => {
                if (cloudOpacityLevel2 > observation.cloudAmountLevel2){
                    return false;
                }
                return true;
            },
            'Cloud Opacity Total cannot be greater than 8': (cloudOpacityLevel2,observation) =>{
                const total = totalCloudOpacity(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },
        parameterRelationships: {
            cloudTypeAmt : (cloudOpacityLevel2,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
    },
    cloudHeightLevel3: {
        inputType: 'number',
        name: 'Cloud Height',
        minValue: 0,
        maxValue: 39000,
        checks: {
            '100 ft to 39000 ft': rangeCheck.bind(this, 100, 39000),
        },
        parameterRelationships: {
            cloudHeightAmt : (cloudHeightLevel3,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            }
        },
        required: false,
    },
    cloudTypeLevel3: {
        inputType: 'select',
        name: 'Cloud Type',
        options: [
            ['CI','Cirrus - CI'],
            ['CC','Cirrocumulus - CC'],
            ['CS','Cirrostratus - CS'],
            ['AC','Altocumulus - AC'],
            ['ACC','Altocumulus Castellanus - ACC'],
            ['AS','Altostratus - AS'],
            ['NS','Nimbostratus - NS'],
            ['ST','Stratus - ST'],
            ['SF','Stratus Fractus - SF'],
            ['SC','Stratocumulus - SC'],
            ['CU','Cumulus - CU'],
            ['CF','Cumulus Fractus - CF'],
            ['TCU','Towering Cumulus - TCU'],
            ['CB','Cumulonimbus - CB'],
            ['FG','Fog - FG'],
            ['BR','Mist - BR'],
            ['SN','Snow - SN'],
        ],
        checks: {
            'Invalid Selection': (cloudType, observation) => {
                const cloudHeight = observation.cloudHeightLevel3;
                if (cloudType === "FG" || cloudType === "SN" || cloudType === "BR"){
                    if(cloudHeight >= 0 && cloudHeight <= 10000){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 0 && cloudHeight <= 6500){
                    if(cloudType === "CB" || cloudType === "TCU" || cloudType === "CF" || cloudType === "CU"|| cloudType === "SC"|| cloudType === "SF"|| cloudType === "ST" ){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 6500 && cloudHeight <= 20000){
                    if(cloudType === "NS" || cloudType === "AS" || cloudType === "ACC" || cloudType === "AC"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 20000 && cloudHeight <= 39000){
                    if(cloudType === "CS" || cloudType === "CC" || cloudType === "CI"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
        },
        parameterRelationships: {
            cloudHeightAmt : (cloudTypeLevel3,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudTypeLevel3,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
        required: false,
    },
    cloudAmountLevel3: {
        inputType: 'select',
        name: 'Cloud Amount',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        parameterRelationships: {
            cloudHeightAmt : (cloudAmountLevel3,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudAmountLevel3,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
            cloudCover: (cloudAmountLevel3,observation) =>{
                let ct = observation.cloudTypeLevel3;
                let amount = totalAmountCloud(observation);
                if (amount === 0){
                    return "0";
                }
                else if (amount === 1){
                    return "1";
                }
                else if (amount === 2){
                    return "2";
                }
                else if (amount === 3){
                    return "3";
                }
                else if (amount === 4){
                    return "4";
                }
                else if (amount === 5){
                    return "5";
                }
                else if (amount === 6){
                    return "6";
                }
                else if (amount === 7){
                    return "7";
                }
                else if (amount === 8){
                    return "8";
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return "9";
                    }
                    else{
                        return "8";
                    }
                }
            },
            totalCloudCover: (cloudAmountLevel3,observation) =>{
                const ct = observation.cloudTypeLevel3;
                const amount = lowestCloudCover(observation);
                if (amount === 0){
                    return '0';
                }
                else if (amount === 1){
                    return '1';
                }
                else if (amount === 2){
                    return '2';
                }
                else if (amount === 3){
                    return '3';
                }
                else if (amount === 4){
                    return '4';
                }
                else if (amount === 5){
                    return '5';
                }
                else if (amount === 6){
                    return '6';
                }
                else if (amount === 7){
                    return '7';
                }
                else if (amount === 8){
                    return '8';
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
            },
        },
        /*
        checks: {
            'Cloud Amount Total cannot be greater than 8': (cloudAmountLevel3,observation) =>{
                const total = totalAmountCloud(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },*/
        required: false,
    },
    cloudOpacityLevel3: {
        inputType: 'select',
        name: 'Cloud Opacity',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        checks: {
            'Cloud Opacity cannot exceed the Cloud Amount' : (cloudOpacityLevel3, observation) => {
                if (cloudOpacityLevel3 > observation.cloudAmountLevel3){
                    return false;
                }
                return true;
            },
            'Cloud Opacity Total cannot be greater than 8': (cloudOpacityLevel3,observation) =>{
                const total = totalCloudOpacity(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },
        parameterRelationships: {
            cloudTypeAmt : (cloudOpacityLevel3,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
    },
    cloudHeightLevel4: {
        inputType: 'number',
        name: 'Cloud Height',
        minValue: 0,
        maxValue: 39000,
        checks: {
            '100 ft to 39000 ft': rangeCheck.bind(this, 100, 39000),
        },
        parameterRelationships: {
            cloudHeightAmt : (cloudHeightLevel4,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            }
        },
        required: false,
    },
    cloudTypeLevel4: {
        inputType: 'select',
        name: 'Cloud Type',
        options: [
            ['CI','Cirrus - CI'],
            ['CC','Cirrocumulus - CC'],
            ['CS','Cirrostratus - CS'],
            ['AC','Altocumulus - AC'],
            ['ACC','Altocumulus Castellanus - ACC'],
            ['AS','Altostratus - AS'],
            ['NS','Nimbostratus - NS'],
            ['ST','Stratus - ST'],
            ['SF','Stratus Fractus - SF'],
            ['SC','Stratocumulus - SC'],
            ['CU','Cumulus - CU'],
            ['CF','Cumulus Fractus - CF'],
            ['TCU','Towering Cumulus - TCU'],
            ['CB','Cumulonimbus - CB'],
            ['FG','Fog - FG'],
            ['BR','Mist - BR'],
            ['SN','Snow - SN'],
        ],
        checks: {
            'Invalid Selection': (cloudType, observation) => {
                const cloudHeight = observation.cloudHeightLevel4;
                if (cloudType === "FG" || cloudType === "SN" || cloudType === "BR"){
                    if(cloudHeight >= 0 && cloudHeight <= 10000){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 0 && cloudHeight <= 6500){
                    if(cloudType === "CB" || cloudType === "TCU" || cloudType === "CF" || cloudType === "CU"|| cloudType === "SC"|| cloudType === "SF"|| cloudType === "ST" ){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 6500 && cloudHeight <= 20000){
                    if(cloudType === "NS" || cloudType === "AS" || cloudType === "ACC" || cloudType === "AC"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                if (cloudHeight > 20000 && cloudHeight <= 39000){
                    if(cloudType === "CS" || cloudType === "CC" || cloudType === "CI"){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
        },
        parameterRelationships: {
            cloudHeightAmt : (cloudTypeLevel4,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudTypeLevel4,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
        required: false,
    },
    cloudAmountLevel4: {
        inputType: 'select',
        name: 'Cloud Amount',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        parameterRelationships: {
            cloudHeightAmt : (cloudAmountLevel4,observation) =>{
                const rem = cloudHeightAmtCalc(observation);
                return rem;
            },
            cloudTypeAmt : (cloudAmountLevel4,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
            cloudCover: (cloudAmountLevel4,observation) =>{
                let ct = observation.cloudTypeLevel4;
                let amount = totalAmountCloud(observation);
                if (amount === 0){
                    return "0";
                }
                else if (amount === 1){
                    return "1";
                }
                else if (amount === 2){
                    return "2";
                }
                else if (amount === 3){
                    return "3";
                }
                else if (amount === 4){
                    return "4";
                }
                else if (amount === 5){
                    return "5";
                }
                else if (amount === 6){
                    return "6";
                }
                else if (amount === 7){
                    return "7";
                }
                else if (amount === 8){
                    return "8";
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return "9";
                    }
                    else{
                        return "8";
                    }
                }
            },
            totalCloudCover: (cloudAmountLevel4,observation) =>{
                const ct = observation.cloudTypeLevel4;
                const amount = lowestCloudCover(observation);
                if (amount === 0){
                    return '0';
                }
                else if (amount === 1){
                    return '1';
                }
                else if (amount === 2){
                    return '2';
                }
                else if (amount === 3){
                    return '3';
                }
                else if (amount === 4){
                    return '4';
                }
                else if (amount === 5){
                    return '5';
                }
                else if (amount === 6){
                    return '6';
                }
                else if (amount === 7){
                    return '7';
                }
                else if (amount === 8){
                    return '8';
                }
                else if (amount > 8){
                    if (ct === "FG" || ct === "SN" || ct === "BR"){
                        return '9';
                    }
                    else{
                        return '8';
                    }
                }
            },
        },
        /*
        checks: {
            'Cloud Amount Total cannot be greater than 8': (cloudAmountLevel4,observation) =>{
                const total = totalAmountCloud(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },*/
        required: false,
    },
    cloudOpacityLevel4: {
        inputType: 'select',
        name: 'Cloud Opacity',
        options: [
            [-1, 'Trace'],
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
        ],
        checks: {
            'Cloud Opacity cannot exceed the Cloud Amount' : (cloudOpacityLevel4, observation) => {
                if (cloudOpacityLevel4 > observation.cloudAmountLevel4){
                    return false;
                }
                return true;
            },
            'Cloud Opacity Total cannot be greater than 8': (cloudOpacityLevel4,observation) =>{
                const total = totalCloudOpacity(observation);
                if (total > 8){
                    return false;
                }
                return true;
            }
        },
        parameterRelationships: {
            cloudTypeAmt : (cloudOpacityLevel1,observation) =>{
                const rem = cloudTypeAmtCalc(observation);
                return rem;
            },
        },
    },
    observerRemarks: {
        inputType: 'text',
        name: 'Remarks'
    },
    airTemperature: {
        inputType: 'text',
        name: 'Air Temperature',
        minValue: -55,
        maxValue: 40,
        checks: {
            '-55.0 to 40.0 °C': rangeCheck.bind(this, -55, 40),
            'T must be greater than or equal to Td': (airTemperature, observation) => {
                if ('dewpointTemperature' in observation) {
                    const airTemperatureValue = parseFloat(airTemperature);
                    const dewpointTemperatureValue = parseFloat(observation.dewpointTemperature);
                    return airTemperatureValue >= dewpointTemperatureValue;
                }
                return true;
            }
        },
        warnings: {
            'Large temperature change from previous observation requires SPECI': (airTemperature, observation, previousObservations) => {
                if (previousObservations.length > 0) {
                    const speciReport = moment.utc(observation.dateTime).minute() === 0 ? false : true;
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('airTemperature')) { return true; }
                    if (parseFloat(latestPreviousObservation.airTemperature) >= 20 && !speciReport && flagLargeValueChange('airTemperature', '5', null, observation, latestPreviousObservation)) {
                        return false;
                    }
                }
                return true;
            }
        },
        parameterRelationships: {
            meanSeaLevelPressure: (airTemperature, observation) => {
                const stationPressureValue = parseFloat(observation.stationPressure);
                const stationBarometerValue = parseFloat(observation.barometerHeight);
                const airTemperatureValue = parseFloat(airTemperature);
                const airTemperatureTwelveHoursAgoValue = parseFloat(observation.airTemperatureTwelveHoursAgo);
                const latitudeValue = parseFloat(observation.latitude);
                if (isNumber(stationPressureValue) && isNumber(stationBarometerValue) && isNumber(airTemperatureValue) && isNumber(latitudeValue)) {
                    return calculateMeanSeaLevelPressure(
                        stationPressureValue,
                        airTemperatureValue,
                        airTemperatureTwelveHoursAgoValue,
                        stationBarometerValue,
                        latitudeValue
                   );
                }
                return;
            },
            relativeHumidity: (airTemperature, observation) => {
                const airTemperatureValue = parseFloat(airTemperature);
                const wetbulbTemperatureValue = parseFloat(observation.wetbulbTemperature);
                const meanSeaLevelPressureValue = parseFloat(observation.meanSeaLevelPressure);
                if (isNumber(airTemperatureValue) && isNumber(wetbulbTemperatureValue) && isNumber(meanSeaLevelPressureValue)) {
                    return calculateRelativeHumidity(airTemperatureValue, wetbulbTemperatureValue, meanSeaLevelPressureValue);
                }
            }
        },
        required: true,
        displayPrecision: 1
    },
    airTemperatureTwelveHoursAgo: {
        inputType: 'number',
        name: 'Air Temperature (T-12)',
        minValue: -55,
        maxValue: 40,
        checks: {
            '-55.0 to 40.0 °C': rangeCheck.bind(this, -55, 40),
        },
        parameterRelationships: {
            meanSeaLevelPressure: (airTemperatureTwelveHoursAgo, observation) => {
                let stationPressureValue = parseFloat(observation.stationPressure);
                let stationHeightValue = parseFloat(observation.barometerHeight);
                let airTemperatureValue = parseFloat(observation.airTemperature);
                let airTemperatureTwelveHoursAgoValue = parseFloat(airTemperatureTwelveHoursAgo);
                let latitudeValue = parseFloat(observation.latitude);
                if (isNumber(stationPressureValue) && isNumber(stationHeightValue) && isNumber(airTemperatureValue) && isNumber(latitudeValue)) {
                    return calculateMeanSeaLevelPressure(
                        stationPressureValue,
                        airTemperatureValue,
                        airTemperatureTwelveHoursAgoValue,
                        stationHeightValue,
                        latitudeValue
                    );
                }
                return;
            }
        },
        required: true,
        displayPrecision: 1
    },
    relativeHumidity: {
        inputType: 'number',
        name: 'Relative Humidity',
        minValue: 0,
        maxValue: 100,
        parameterRelationships: {
            dewpointTemperature: (relativeHumidity, observation) => {
                const airTemperatureValue = isNumber(parseFloat(observation.airTemperature)) ? parseFloat(observation.airTemperature) : null;
                const relativeHumidityValue = parseFloat(relativeHumidity);
                if (isNumber(airTemperatureValue) && isNumber(relativeHumidityValue)) {
                    return calculateDewpointCelsius(relativeHumidityValue, airTemperatureValue);
                }
            }
        },
        displayPrecision: 1,
        derived: true
    },
    dewpointTemperature: {
        inputType: 'number',
        name: 'Dewpoint Temperature',
        minValue: -45,
        maxValue: 25,
        checks: {
            '-45.0 to 25.0 °C': rangeCheck.bind(this, -45, 25),
            'T must be greater than or equal to Td': (dewpointTemperature, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    const dewpointTemperatureValue = parseFloat(dewpointTemperature);
                    return airTemperatureValue >= dewpointTemperatureValue;
                }
                return true;
            }
        },
        required: true,
        displayPrecision: 1,
        derived: true
    },
    wetbulbTemperature: {
        inputType: 'text',
        name: 'Wetbulb Temperature',
        minValue: -45,
        maxValue: 40,
        checks: {
            '-45.0 to 40.0 °C': rangeCheck.bind(this, -45, 40),
        },
        parameterRelationships: {
            relativeHumidity: (wetbulbTemperature, observation) => {
                const wetbulbTemperatureValue = parseFloat(wetbulbTemperature);
                const airTemperatureValue = parseFloat(observation.airTemperature);
                const meanSeaLevelPressureValue = parseFloat(observation.meanSeaLevelPressure);
                if (isNumber(airTemperatureValue) && isNumber(wetbulbTemperatureValue) && isNumber(meanSeaLevelPressureValue)) {
                    return calculateRelativeHumidity(airTemperatureValue, wetbulbTemperatureValue, meanSeaLevelPressureValue);
                }
            },
            wetbulbTemperatureSign: (wetbulbTemperature, observation) => {
                if (wetbulbTemperature >= 0){
                    return "0";
                }
                else{
                    return "1";
                }
            }
        },
        required: true,
        displayPrecision: 1,
        derived: false
    },
    wetbulbTemperatureSign: {
        inputType: 'select',
        name: 'Wet Bulb Temperature Sign',
        options: [
            [0, '0 - Positive or zero '],
            [1, '1 - Negative measured wet-bulb temperature'],
            [2, '2 - Iced bulb measured wet-bulb temperature'],
            [3, '3 - Not used'],
            [4, '4 - Not used'],
            [5, '5 - Positive or zero computed wet-bulb temperature'],
            [6, '6 - Negative computed wet-bulb temperature'],
            [7, '7 - Iced bulb computed wet-bulb temperature']
        ],
        required: true,
        derived: true,
    },
    waterTemperature: {
        inputType: 'number',
        name: 'Sea Surface Temperature',
        minValue: -2,
        maxValue: 40,
        checks: {
            '-2.0 to 40.0 °C': rangeCheck.bind(this, -2, 40),
        },
        parameterRelationships:{
            waterTemperatureSign: (waterTemperature,observation) => {
                if (waterTemperature >= 0){
                    return "6";
                }
                else {
                    return "7";
                }
            },
        },
        displayPrecision: 1,
        required: false
    },
    waterTemperatureSign: {
        inputType: 'select',
        name: 'Sea Surface Temperature Sign',
        options: [
            [0, '0 - Positive or zero - intake'],
            [1, '1 - Negative - intake'],
            [2, '2 - Positive or zero - bucket'],
            [3, '3 - Negative - bucket'],
            [4, '4 - Positive or zero - hull contact sensor'],
            [5, '5 - Negative - hull contact sensor'],
            [6, '6 - Positive or zero - other'],
            [7, '7 - Negative - other']
        ],
        required: true,
        derived: true,
    },
    baseLowestCloud: {
        inputType: 'select',
        name: 'Lowest Cloud Height (h)',
        options: [
            ['0', '0 - 160 or less feet'],
            ['1', '1 - 160 to 330 feet'],
            ['2', '2 - 330 to 650 feet'],
            ['3', '3 - 650 to 1000 feet'],
            ['4', '4 - 1000 to 2000 feet'],
            ['5', '5 - 2000 to 3300 feet'],
            ['6', '6 - 3300 to 4900 feet'],
            ['7', '7 - 4900 to 6600 feet'],
            ['8', '8 - 6600 to 8200 feet'],
            ['9', '9 - Greater than 8200 feet or no clouds'],
            ['/', '/ - Sky obscured by fog, snow, clouds or cannot be seen'],
        ],
        required: true
    },
    cloudCover: {
        inputType: 'select',
        name: 'Total Amount of Cloud (N)',
        options: [
            [0, '0 - 0 Oktas'],
            [1, '1 - 1 Oktas'],
            [2, '2 - 2 Oktas'],
            [3, '3 - 3 Oktas'],
            [4, '4 - 4 Oktas'],
            [5, '5 - 5 Oktas'],
            [6, '6 - 6 Oktas'],
            [7, '7 - 7 Oktas'],
            [8, '8 - 8 Oktas'],
            [9, '9 - Sky obscured by fog and/or other meteorological phenomena'],
            ['/', '/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made. '],
        ],
        required: true,
        crossParameterChecks: {
            'N must be greater than Nh': (cloudCover, observation) => {
                if ('totalCloudCover' in observation) {
                    const totalCloudCoverValue = parseInt(observation.totalCloudCover);
                    const cloudCoverValue = parseInt(cloudCover);
                    if (!isNaN(totalCloudCoverValue) && !isNaN(cloudCoverValue)) {
                        return cloudCoverValue >= totalCloudCoverValue;
                    }
                    return true; // Assume that clouds are obscured
                }
                return true;
            }
        }
    },
    visibility: {
        inputType: 'select',
        name: 'Visibility (VV)',
        options: [
            [0, '0'],
            [0.125, '1/8'],
            [0.25, '1/4'],
            [0.375, '3/8'],
            [0.5, '1/2'],
            [0.625, '5/8'],
            [0.75, '3/4'],
            [1.0, '1'],
            [1.25, '1 1/4'],
            [1.5, '1 1/2'],
            [1.75, '1 3/4'],
            [2, '2'],
            [2.25, '2 1/4'],
            [2.5, ' 2 1/2'],
            [3, '3'],
            [4, '4'],
            [5, '5'],
            [6, '6'],
            [7, '7'],
            [8, '8'],
            [9, '9'],
            [10, '10'],
            [11, '11'],
            [12, '12'],
            [13, '13'],
            [14, '14'],
            [15, '15']
        ],
        checks: {
            'Visibility less than 6NM needs obstruction to vision' : (visibility, observation) => {
                const visibilityValue = parseFloat(visibility);
                const temp = observation.metarWeather;
                const type = String(observation.observationType);
                if(visibilityValue<6 && (temp === '' || temp ===undefined) && type === 'metar'){
                    return false;
                }
                return true;
            },
            'Visibility should be 5/8 mi or more': (visibility,observation) =>{
                const visibilityValue = parseFloat(visibility);
                
                if((observation.metarWeather === '-SN' || observation.metarWeather === '-SHSN' || observation.metarWeather === '-SG' || observation.metarWeather === '-GS' ||observation.metarWeather === '-FZDZ' ) && visibilityValue < 0.625){
                    return false;
                }
                return true;
            },
            'Visibility should be 1/2 mi or 3/8 mi': (visibility,observation) =>{
                const visibilityValue = parseFloat(visibility);
                //if((observation.metarWeather === '+SN' || observation.metarWeather === '+SHSN' || observation.metarWeather === '+SG' || observation.metarWeather === '+GS' || observation.metarWeather === '+FZDZ') ){
                if((observation.metarWeather === 'SN' || observation.metarWeather === 'SHSN' || observation.metarWeather === 'SG' || observation.metarWeather === 'GS' || observation.metarWeather === 'FZDZ') ){
                    if ((visibilityValue > 0.5 || visibilityValue < 0.375) ){
                        return false;
                    }
                }
                return true;
            },
            'Visibility should be 1/4 mi, 1/8 mi or 0': (visibility,observation) =>{
                const visibilityValue = parseFloat(visibility);
                if((observation.metarWeather === '+SN' || observation.metarWeather === '+SHSN' || observation.metarWeather === '+SG' || observation.metarWeather === '+GS' || observation.metarWeather === '+FZDZ') ){
                    if (visibilityValue > 0.25 ){
                        return false;
                    }
                }
                return true;
            },
        },
        warnings: {
            'Visibility change may require SPECI report': (visibility, observation, previousObservations) => {
                if (previousObservations.length > 0) {
                    const speciReport = moment.utc(observation.dateTime).minute() === 0 ? false : true;
                    const latestPreviousObservation = previousObservations[0];
                    if (!speciReport && visibilitySpeciRequired(visibility, latestPreviousObservation)) {
                        return false;
                    }
                }
                return true;
            }
        },
        required: true
    },
    cloudHeightAmt: {
        inputType: 'text',
        name: 'Cloud Height-Amount',
        warnings: {
            'SPECI change may be required for ceiling change': (value, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const isCeiling = (value.includes("BKN") || value.includes("OVC") || value.includes("VV")) ? true : false;
                    const speciReport = moment.utc(observation.dateTime).minute() === 0 ? false : true;
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('cloudHeightAmt')) { return true; }
                    
                    if (isCeiling && ceilingSpeciRequired(value, latestPreviousObservation) && !speciReport) {
                        return false;
                    }
                }
                return true;
            }
        },
        required: true,
        derived:true
    },
    cloudTypeAmt: {
        inputType: 'text',
        name: 'Cloud Type/Opacity',
        derived:true
    },
    metarWeather: {
        inputType: 'text',
        name: 'Weather and Obstructions',
        checks: {
            'Weather and Obstruction field does not match the Cloud Type': (weather, observation) =>{
                const type = observation.cloudTypeLevel1;
                const temp = observation.metarWeather.split(" ");
                if ('cloudTypeLevel1' in observation){
                    if(temp.includes('FG') || temp.includes('SN')){
                        if (temp.includes(type)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                }
                return true;
            },
            'FZFG cannot be reported with Ta >= 0.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('FZFG') && airTemperatureValue > 0);
                }
                return true;
            },
            'FG or SN cannot be reported if visibility >1/2 mile': (weather, observation) => {
                if ('visibility' in observation) {
                    const visibilityValue = parseFloat(observation.visibility);
                    const temp = weather.split(" ");
                    if (temp.length > 1){
                        if((temp.includes('FG') || temp.includes('SN')|| temp.includes('+SHSN') || temp.includes("+BLSN") || temp.includes('FZFG')) && visibilityValue > 0.5){
                            return false;
                        }
                    }
                    else{
                        if(((weather ==='FG' || weather ==='SN' || weather ==='+SHSN' || weather==='+BLSN' || weather==='FZFG') && visibilityValue > 0.5)){
                            return false;
                        }
                    }
                    //The commented code is kept just to revert back to the original code if Chris doesnt approve this.
                    //return !(!!weather.match(/(FG|SN)/) && visibilityValue > 0.5 && !weather.match(/(-SHSN|-BLSN)/) && !weather.match(/(FZFG)/));
                }
                return true;
            },
            'Visibility <1/2 NM requires FG or SN in Weather and Obstruction Field': (weather, observation) => {
                const visibilityValue = parseFloat(observation.visibility);
                if (visibilityValue <= 0.5) {
                    if (weather.includes('FG') || weather.includes('SN') || weather.includes('SHSN')|| weather.includes('SG') || weather.includes('GS') || weather.includes('DZ') || weather.includes('FZDZ')){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return true;
            },
            'Visibility > 6NM cannot have BR, SN or FG': (weather, observation) => {
                const visibilityValue = parseFloat(observation.visibility);
                if (visibilityValue > 6) {
                    if (weather === 'FG' || weather === 'SN' || weather ==='BR'){
                        return false;
                    }
                    else{
                        return true;
                    }
                }
                return true;
            },
            'Liquid precipitation cannot be reported with Ta < -2.0 °C': (weather, observation) => {
                let isValid = true;
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);

                    if (airTemperatureValue < -2) {
                        // Get the collection of weather phenomena
                        let weatherCollection = weather.trim().split(" ");
                        let wxLength, wxPhenomenon, wxDescriptor;

                        weatherCollection.forEach((wxCode) => {

                            wxLength = wxCode.length;

                            // Wx Phenomenon is last two characters of the wx Code
                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                // Wx Descriptor is two characters before the wx Code
                                if (wxLength >= 4) {
                                    wxDescriptor = wxCode.substring(wxLength - 4, wxLength - 2);
                                }

                                // If it's freezing precipitation, let it go through to be validated by the Freezing precipitation check
                                // Check for liquid precipitation
                                if ((wxDescriptor != 'FZ') && (wxPhenomenon == 'RA' || wxPhenomenon == 'DZ')) {
                                    isValid = false;
                                    return;
                                }
                            }
                        });
                    }
                }
                return isValid;
            },
            'Freezing precipitation cannot be reported with Ta > 3.0 °C': (weather, observation) => {
                let isValid = true;
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);

                    if (airTemperatureValue > 3) {
                        // Get the collection of weather phenomena
                        let weatherCollection = weather.trim().split(" ");
                        let wxLength, wxPhenomenon, wxDescriptor;

                        weatherCollection.forEach((wxCode) => {

                            wxLength = wxCode.length;

                            // Wx Phenomenon is last two characters of the wx Code
                            // Wx Descriptor is two characters before the wx Code
                            if (wxLength >= 4) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);
                                wxDescriptor = wxCode.substring(wxLength - 4, wxLength - 2);

                                if (wxDescriptor == 'FZ' && (wxPhenomenon == 'RA' || wxPhenomenon == 'DZ')) { // Check for Freezing precipitation
                                    isValid = false;
                                    return;
                                }
                            }
                        });
                    }
                }
                return isValid;
            },
            'Frozen precipitation not normally reported for Ta > 3.0 °C': (weather, observation) => {
                let isValid = true;
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);

                    if (airTemperatureValue > 3) {
                        // Get the collection of weather phenomena
                        let weatherCollection = weather.trim().split(" ");
                        let wxLength, wxPhenomenon;

                        weatherCollection.forEach((wxCode) => {

                            wxLength = wxCode.length;

                            // Wx Phenomenon is last two characters of the wxCode
                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                let frozenPrecipitation = [
                                    'IC', // Ice Crystals
                                    'PL', // Ice Pellets
                                    'GR', // Hail
                                    'GS'  // Small Hail and/or Snow Pellets
                                ];

                                if (frozenPrecipitation.includes(wxPhenomenon)) {
                                    isValid = false;
                                    return;
                                }
                            }
                        });
                    }
                }
                return isValid;
            },
            'BLSN cannot be reported with Ta > 4.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('BLSN') && airTemperatureValue > 4); // Blowing Snow
                }
                return true;
            },
            'FZDZ cannot be reported with Ta < -12.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('FZDZ') && airTemperatureValue < -12); // Freezing Drizzle
                }
                return true;
            },
            'FZRA cannot be reported with Ta < -7.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('FZRA') && airTemperatureValue < -7); // Freezing Rain
                }
                return true;
            },
            'GR cannot be reported with Ta < -4.0 °C': (weather, observation) => {
                let isValid = true;
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);

                    if (airTemperatureValue < -4) {
                        // Get the collection of weather phenomena
                        let weatherCollection = weather.trim().split(" ");
                        let wxLength, wxPhenomenon;

                        weatherCollection.forEach((wxCode) => {

                            wxLength = wxCode.length;

                            // Wx Phenomenon is last two characters of the wx Code
                            if (wxLength >= 2 && wxLength) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                if (wxPhenomenon == 'GR') { // Hail
                                    isValid = false;
                                    return;
                                }
                            }
                        });
                    }
                }
                return isValid;
            },
            'TS cannot be reported with Ta < -7.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('TS') && airTemperatureValue < -7); // Thunderstorm
                }
                return true;
            },
            'FG cannot be reported with Ta < 0.0 °C and >= -30.0 °C': (weather, observation) => {
                if ('airTemperature' in observation) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    return !(!!weather.match('FG') && !weather.match('FZFG') && !weather.match('MIFG') && !weather.match('BCFG') && !weather.match('VCFG') && (airTemperatureValue < 0 && airTemperatureValue >= -30)); // Fog can be reported at temps < -30 °C
                }
                return true;
            },
            'BL cannot be reported with wind speed < 10 kts': (weather, observation) => {
                if ('twoMinuteMeanWindSpeed' in observation) {
                    const twoMinuteMeanWindSpeedValue = parseFloat(observation.twoMinuteMeanWindSpeed);
                    return !(!!weather.match('BL') && twoMinuteMeanWindSpeedValue < 10); // Blowing Phenomena
                }
                return true;
            },
            'Blowing obstructions not normally reported with wind speed < 10 kts': (weather, observation) => {
                let isValid = true;
                if ('twoMinuteMeanWindSpeed' in observation) {
                    const twoMinuteMeanWindSpeedValue = parseFloat(observation.twoMinuteMeanWindSpeed);

                    if (twoMinuteMeanWindSpeedValue < 10) {
                        let blowingObstructions = [
                            'BLDU', // Blowing Widespread Dust
                            'BLSA', // Blowing Sand
                            'BLSN', // Blowing Snow
                            'DS',   // Duststorm
                            'SS'    // Sandstorm
                        ]

                        blowingObstructions.forEach((obstruction) => {
                            if (!!weather.match(obstruction)) {
                                isValid = false;
                                return;
                            }
                        });
                    }
                }
                return isValid;
            },
            'Incorrect/invalid format provided': (weather) => {
                let isValid = true;
                // Get the collection of weather phenomena
                let weatherCollection = weather.trim().split(" ");

                weatherCollection.forEach((wxCode) => {
                    // Determine whether the format is invalid based on valid length possibilities
                    let wxLength = wxCode.length;

                    if (wxLength == 1) {
                        isValid = false;
                        return;
                    }
                    else if (wxLength > 6) {
                        // RASN - mix of rain and snow, is the only exception to the format/length, as there could be a length up to 8 with a possible value of VCSHRASN (in the vicinty, rain and snow showers)
                        if (!(!!wxCode.match('RASN'))){
                            isValid = false;
                            return;
                        }
                    }
                });

                return isValid;
            },
            'Invalid/unknown weather intensity provided': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");

                weatherCollection.forEach((wxCode) => {
                    // Determine whether there is an intensity and/or descriptor provided
                    // Intensity could be one or two characters; descriptor and Wx phenomenon are two characters
                    let wxLength = wxCode.length;
                    let wxIntensity, wxIntensityFirstCharacter;

                    if (wxLength > 2) {
                        // There is no intensity to validate unless the length is greater than 2
                        if (wxLength % 2 == 1) {
                            // If the length is an odd number, there is an intensity provided
                            wxIntensity = wxCode.substring(0, 1);
                            if (!validWeatherCodeCheck(wxIntensity, wxIntensities)) {
                                isValid = false;
                                return;
                            }
                        }
                        else {
                            // If length is even-numbered, a descriptor may not be provided (which means intensity is 'moderate') or intensity could be 'VC' - 'In the vicinity'
                            wxIntensity = wxCode.substring(0, 2);
                            wxIntensityFirstCharacter = wxCode.substring(0, 1);

                            // There could be a valid intensity provided but the user has included an invalid weather code (i.e. 3 characters long)
                            // If a single character intensity is provided is valid (i.e. +, -), the weather phenomena code will be wrong so let it pass through intensity validation
                            if (validWeatherCodeCheck(wxIntensityFirstCharacter, wxIntensities)) {
                                isValid = true;
                                return;
                            }
                            else {
                                if (wxLength >= 6) { // The longest possible length is 6, or 8 with the exception of RASN
                                    if (wxIntensity != 'VC' && !(!!wxCode.match('SHRASN'))) {
                                        isValid = false;
                                        return;
                                    }
                                }
                                else if (wxLength == 4) {
                                    // If length is 4, intensity could be 'VC' OR descriptor, combined with Wx phenomenon
                                    // A valid exception is moderate continuous mix of rain and snow (RASN)
                                    if (wxIntensity != 'VC' && !validWeatherCodeCheck(wxIntensity, wxDescriptors) && wxCode != 'RASN') {
                                        isValid = false;
                                        return;
                                    }
                                }
                            }
                        }
                    }
                });

                return isValid;
            },
            'Invalid/unknown weather descriptor provided': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");

                weatherCollection.forEach((wxCode) => {
                    // Determine whether there is an intensity and/or descriptor provided
                    // Weather descriptors are the first two characters of the weather code after the intensity code (if any)
                    // Weather descriptors cannot exist alone in the weather code (with the exception of TS - Thunderstorms), but weather phenomena can. E.g. RA = moderate rain (valid), TS = moderate thunderstorms (valid), +SH = heavy showers (invalid)
                    let wxLength = wxCode.length;
                    let wxDescriptor, wxPrecipException;

                    // There is no descriptor to validate unless the length is at least 2 characters (must be TS) or greater than or equal to 4, as a descriptor cannot stand alone (except TS)
                    // Weather descriptors are the optional two characters of the weather code before the weather phenomenon code (which is the last two characters of the weather code)

                    // Length < 4 will be validated for weather phenomena
                    if (wxLength == 4) {
                        // If the length is 4, the only options are an intensity of 'VC' plus weather phenomenon, or no intensity (i.e. moderate) with a descriptor and weather phenomenon
                        // The exception of RASN is also valid (i.e. moderate mix of rain and snow)
                        wxDescriptor = wxCode.substring(0, 2);
                        if (wxCode != 'RASN') {
                            if (wxDescriptor != 'VC' && !validWeatherCodeCheck(wxDescriptor, wxDescriptors)) {
                                isValid = false;
                                return;
                            }
                        }
                    }
                    else if (wxLength > 4) {
                        // If the length is greater than 4 (and properly formatted), we can assume an intensity, descriptor and weather phenomenon was provided
                        // Again, the exception of RASN - mix of rain and snow (two precip types) must also be addressed
                        wxDescriptor = wxCode.substring(wxLength - 4, wxLength - 2);
                        wxPrecipException = wxCode.substring(wxLength - 4, wxLength); // Check if the last four characters match 'RASN'

                        if (wxPrecipException == 'RASN') {
                            if (wxLength == 6) {
                                // If length is 6, there has to be a descriptor of 'SH' (the only valid descriptor for RASN) or an intensity of 'VC'
                                wxDescriptor = wxCode.substring(0, 2);
                                if (wxDescriptor != 'VC' && wxDescriptor != 'SH') {
                                    isValid = false;
                                    return;
                                }
                            }
                            else if (wxLength > 6 && wxCode != 'VCSHRASN') {
                                // If the length is > 6, there could be an intensity and/or descriptor provided
                                // 'SH' is the only valid descriptor for RASN - mix of rain and snow
                                wxDescriptor = wxCode.substring(wxLength - 6, wxLength - 4);
                                if (wxDescriptor != 'SH') {
                                    isValid = false;
                                    return;
                                }
                            }
                        }
                        else {
                            if (!validWeatherCodeCheck(wxDescriptor, wxDescriptors)) {
                                isValid = false;
                                return;
                            }
                        }
                    }
                });

                return isValid;
            },
            'Invalid/unknown weather phenomenon provided': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");

                weatherCollection.forEach((wxCode) => {
                    // Weather phenomena are the last two characters of the weather code
                    let wxLength = wxCode.length;
                    let wxPhenomenon;

                    if (wxLength >= 2) {
                        wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                        // There is no phenomenon to validate unless the length is greater than or equal to 2
                        // The only valid stand-alone descriptor is TS
                        // RASN - mix of rain snow, is a valid weather phenomenon, which combines two individual weather phenomena
                        if (wxPhenomenon != 'TS' && !(!!wxCode.match('RASN')) && !validWeatherCodeCheck(wxPhenomenon, wxPhenomena)) {
                            isValid = false;
                            return;
                        }
                    }
                });

                return isValid;
            },
            'Duplicate weather/obstructions are not allowed': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let map = {};

                // For each wxCode, check for exact duplicates (a combination is allowed)
                weatherCollection.forEach((wxCode) => {

                    let wxLength = wxCode.length;

                    // Don't validate inadvertent spaces in-between
                    if (wxLength > 0) {

                        if (wxCode in map) {
                            isValid = false;
                            return;
                        }

                        // Add the Wx Code into the collection as key
                        map[wxCode] = true;
                    }

                });


                return isValid;
            },
            'Weather/obstructions to vision not in correct order': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let currentPosition = 0, previousPosition = 0, currentIntensityPosition = 0, previousIntensityPosition = 0;

                // For each wxCode, validate its position in the order
                weatherCollection.forEach((wxCode) => {
                    let wxLength = wxCode.length;
                    let wxPhenomenon, wxIntensity;

                    // It's not a valid code if the length is less than 2
                    if (wxLength >= 2) {

                        // The weather phenomenon is the last two characters
                        wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                        // If it's a Tornado or Waterspout (both coded as +FC), it must appear first in the order
                        if (wxCode == '+FC') {
                            currentPosition = 0;
                        }
                        else if ((!!wxCode.match('RASN'))) {
                            currentPosition = 3; // Rain and drizzle are position 3, snow is position 4. We'll stick 'RASN' (mix of rain and snow) in at 3
                        }
                        else {
                            currentPosition = wxPhenomenon == 'TS' ? wxDescriptors[wxPhenomenon] : wxPhenomena[wxPhenomenon];
                        }

                        // Determine the intensity rank, as we may need it later
                        if (wxLength % 2 == 1) {
                            // If the length is an odd number, there is an intensity provided
                            wxIntensity = wxCode.substring(0, 1);
                            currentIntensityPosition = wxIntensities[wxIntensity];
                        }
                        else if (wxCode.substring(0, 2) == 'VC') {
                            // All Recent and in the Vicinity phenomena should be last
                            currentIntensityPosition = 4;
                            currentPosition = 10;
                        }
                        else {
                            currentIntensityPosition = 2; // If it's an even number and the intensity isn't 'VC' then no intensity was provided (i.e. moderate)
                        }


                        if (currentPosition < previousPosition) {
                            isValid = false;
                            return;
                        }
                        // If the ranked positions are the same, we need to evaluate the intensity
                        else if (currentPosition == previousPosition) {

                            if (currentIntensityPosition < previousIntensityPosition) {
                                isValid = false;
                                return;
                            }
                        }

                        previousPosition = currentPosition;
                        previousIntensityPosition = currentIntensityPosition;
                    }
                });

                return isValid;
            },
            'No more than three weather groups shall be used to report weather phenomena': (weather) => {

                // Get the collection of weather codes, ignoring leading or trailing spaces
                let weatherCollection = weather.trim().split(" ");
                return weatherCollection.length <= 3;
            },
            'Reduced visibility reported without obstruction to vision': (weather, observation) => {
                let isValid = true;

                if ('visibility' in observation) {
                    const visibilityValue = parseFloat(observation.visibility);

                    // Reduced visibility is considered to be under 6 NM
                    if (visibilityValue < 6 && visibilityValue > 0.5) {
                        isValid = false; // Default to false and set to true/valid if at least one obscuration is found
                        // Get the collection of weather codes
                        let weatherCollection = weather.trim().split(" ");

                        // For each wxCode, check for obscurations
                        weatherCollection.forEach((wxCode) => {
                            // Weather phenomena are the last two characters of the weather code
                            let wxLength = wxCode.length;
                            let wxPhenomenon;

                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                // Check for obscuration
                                if (wxObscurations.includes(wxPhenomenon)) {
                                    isValid = true;
                                    return;
                                }
                            }

                        });
                    }
                }

                return isValid;
            },
            'More than one moderate or heavy precipitation should not be reported together': (weather) => {
                let isValid = true;
                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let precipCount = 0; // We are only counting the number of moderate or heavy precipitation

                // For each wxCode, check for duplicates
                weatherCollection.forEach((wxCode) => {
                    // Weather phenomena are the last two characters of the weather code
                    let wxLength = wxCode.length;
                    let wxPhenomenon;

                    if (wxLength >= 2) {
                        wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                        // Check for precipitation
                        if (wxPrecipitations.includes(wxPhenomenon) || (!!wxCode.match('RASN'))) {
                            // Check the intensity for moderate (no symbol) or heavy (+). Ignore light (-) and In the Vicinity (VC)
                            if (!!!wxCode.match(/(?:VC|-)/)) {
                                precipCount++;
                            }
                        }
                    }
                });

                if (precipCount > 1) {
                    isValid = false;
                }

                return isValid;
            },
            'Continuous and showery precipitation should not be reported together': (weather) => {
                let isValid = true, hasContinuousPrecip = false, hasShoweryPrecip = false;

                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let wxLength, wxPhenomenon, wxDescriptor;

                // For each wxCode, check for concurrent precipitation and showery precipitation
                weatherCollection.forEach((wxCode) => {
                    wxLength = wxCode.length;

                    // Wx Phenomenon is last two characters of the wx Code
                    if (wxLength >= 2) {
                        wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                        // Check for precipitation and only evaluate if it's there
                        // Don't forget to check for the precipitation exception mix of rain and snow - RASN
                        if (wxPrecipitations.includes(wxPhenomenon) || (!!wxCode.match('RASN'))) {

                            // Wx Descriptor is two characters before the wx Code
                            if (wxLength >= 4) {
                                wxDescriptor = wxCode.substring(wxLength - 4, wxLength - 2);
                                if (wxDescriptor == 'SH' || (!!wxCode.match('SHRASN'))) { // If the descriptor is Showers, then showery precipitation is true
                                    hasShoweryPrecip = true; // We don't want to break out of the loop as we need to validate all Wx Phenomena
                                }
                                else if (wxCode == 'RASN') {
                                    hasContinuousPrecip = true;
                                }
                            } else {
                                // If no descriptor then it's continuous precipitation
                                hasContinuousPrecip = true;
                            }
                        }
                    }
                });

                if ((hasContinuousPrecip == true) && (hasShoweryPrecip == true)) {
                    isValid = false;
                }

                return isValid;
            },
            'TS and +TS should not be reported together': (weather) => {
                let isValid = true;

                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let moderateTS = false, heavyTS = false;

                // For each wxCode, check for TS and +TS
                weatherCollection.forEach((wxCode) => {
                    if (wxCode == 'TS') {
                        moderateTS = true;
                    }
                    else if (wxCode == '+TS') {
                        heavyTS = true;
                    }
                });

                if (moderateTS && heavyTS) {
                    isValid = false;
                }

                return isValid;
            },
            'FG and FZFG should not be reported together': (weather) => {
                let isValid = true;

                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let fog = false, freezingFog = false;

                // For each wxCode, check for FG and FZFG
                weatherCollection.forEach((wxCode) => {

                    // Weather phenomena are the last two characters of the weather code
                    let wxLength = wxCode.length;
                    let wxPhenomenon;

                    if (!!wxCode.match('FZFG')) {
                        freezingFog = true;
                    }else if (wxLength >= 2) {
                        wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                        if (wxPhenomenon == 'FG') {
                            fog = true;
                        }
                    }
                });

                if (fog && freezingFog) {
                    isValid = false;
                }

                return isValid;
            },
            'DRSN and BLSN should not be reported together': (weather) => {
                return !(!!weather.match('DRSN') && !!weather.match('BLSN')); // Drifting Snow & Blowing Snow
            },
            'Two or more of MIFG, BCFG and PRFG should not be reported together': (weather) => {
                let isValid = true;

                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let fogVariationCount = 0, fogVariations =[
                "MIFG",
                "BCFG",
                "PRFG"
                ];

                // For each wxCode, check for fog variations
                weatherCollection.forEach((wxCode) => {
                    let wxLength = wxCode.length;

                    // Don't validate inadvertent spaces in-between
                    if (wxLength > 0) {
                        fogVariations.forEach((variation) => {
                            if (!!wxCode.match(variation)) {
                                fogVariationCount++;
                            }
                        });
                    }
                });

                if (fogVariationCount > 1) {
                    isValid = false;
                }

                return isValid;
            },
            'MIFG, BCFG and VCFG should not be reported with FG': (weather) => {
                let isValid = true;

                // Get the collection of weather codes
                let weatherCollection = weather.trim().split(" ");
                let fog = false, fogVariation = false;

                // For each wxCode, check for liquid precipitation
                weatherCollection.forEach((wxCode) => {

                    if (!!wxCode.match(/([MIBCVC]FG)/)) {
                        fogVariation = true;
                    }
                    else {

                        // Weather phenomena are the last two characters of the weather code
                        let wxLength = wxCode.length;
                        let wxPhenomenon;

                        if (wxLength >= 2) {
                            wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                            if (wxPhenomenon == 'FG') {
                                fog = true;
                            }
                        }
                    }
                });

                if (fog && fogVariation) {
                    isValid = false;
                }

                return isValid;
            },
            'TS cannot be reported without CB in RMK': (weather, observation) => {
                let isValid = true;

                // Check if Thunderstorm is being reported
                if (!!weather.match('TS')) {

                    if ('cloudTypeAmt' in observation) {
                        const cloudTypeAmtValue = observation.cloudTypeAmt;

                        // Thunderstorm must have cumulonimbus clouds in RMK
                        if (!!!cloudTypeAmtValue.match('CB')) {
                            isValid = false;
                        }
                    }
                    else {
                        // No remarks makes it invalid
                        isValid = false;
                    }
                }

                return isValid;
            },
            'Cloud or obscuring phenomena cannot be reported with CLR sky coverage': (weather, observation) => {
                let isValid = true;

                if ('cloudHeightAmt' in observation) {
                    const cloudHeightAmtValue = observation.cloudHeightAmt;

                    // If the sky is reported to be clear, check for reports of weather obscurations
                    if (!!cloudHeightAmtValue.match('CLR')) {
                        // Get the collection of weather codes
                        let weatherCollection = weather.trim().split(" ");

                        // For each wxCode, check for obscurations
                        weatherCollection.forEach((wxCode) => {
                            // Weather phenomena are the last two characters of the weather code
                            let wxLength = wxCode.length;
                            let wxPhenomenon;

                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                // Check for cloud or weather obscurations
                                if (wxPhenomenon == 'FC' || wxObscurations.includes(wxPhenomenon)) {
                                    isValid = false;
                                    return;
                                }
                            }
                        });
                    }
                }

                return isValid;
            }
        },
        warnings: {
            'Visibility change may require SPECI report': (visibility, observation, previousObservations) => {
                if (previousObservations.length > 0) {
                    const speciReport = moment.utc(observation.dateTime).minute() === 0 ? false : true;
                    const latestPreviousObservation = previousObservations[0];
                    if (!speciReport && visibilitySpeciRequired(visibility, latestPreviousObservation)) {
                        return false;
                    }
                }
                return true;
            },
            'FG not normally reported with (Ta - Td) > 2 and no liquid precipitation': (weather, observation) => {
                let isValid = true;

                // Short-circuit check if no fog
                if (!weather.match('FG')) {
                    return isValid;
                }

                if (('dewpointTemperature' in observation) && ('airTemperature' in observation)) {
                    const airTemperatureValue = parseFloat(observation.airTemperature);
                    const dewpointTemperatureValue  = parseFloat(observation.dewpointTemperature)

                    // Check for liquid precipitation if Ta-Td > 2
                    if (airTemperatureValue - dewpointTemperatureValue > 2) {

                        // Get the collection of weather codes
                        let weatherCollection = weather.trim().split(" ");
                        let hasLiquidPrecipitation = false;

                        // For each wxCode, check for liquid precipitation
                        weatherCollection.forEach((wxCode) => {

                            // Weather phenomena are the last two characters of the weather code
                            let wxLength = wxCode.length;
                            let wxPhenomenon;

                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);

                                if ((wxPhenomenon == 'DZ') || (wxPhenomenon == 'RA')) {
                                    hasLiquidPrecipitation = true;
                                }
                            }
                        });

                        isValid = hasLiquidPrecipitation;
                    }
                }

                return isValid;
            },
            'Warning - Rainfall < heavy will not usually reduce visibility under 2 miles': (weather, observation) => {
                let isValid = true;

                if ('visibility' in observation) {
                    const visibilityValue = parseFloat(observation.visibility);

                    if (visibilityValue < 2) {
                        // Get the collection of weather codes
                        let weatherCollection = weather.trim().split(" ");
                        let checkRain = false;
                        let checkFog = false;

                        // For each wxCode, check for less than heavy rainfall
                        weatherCollection.forEach((wxCode) => {
                            // Weather phenomena are the last two characters of the weather code
                            let wxLength = wxCode.length;
                            let wxPhenomenon, wxDescriptorFirstCharacter;

                            if (wxLength >= 2) {
                                wxPhenomenon = wxCode.substring(wxLength - 2, wxLength);
                                wxDescriptorFirstCharacter = wxCode.substring(0, 1);

                                // Check for Rainfall
                                // If there is rain - also check for fog (FG) - if no fog - return false
                                if (wxPhenomenon == 'RA') {
                                    checkRain = true;
                                    if (wxDescriptorFirstCharacter != '+') {
                                        isValid = false;
                                    }
                                }

                                if (wxObscurations.includes(wxPhenomenon)){
                                    isValid = true;
                                }

                                if (wxPhenomenon == 'FG' && checkRain) {
                                    checkFog = true;
                                }
                            }

                        });

                        isValid = checkFog ? checkFog : isValid;

                    }

                }
                return isValid;
            }
        }
    },
    manmarPresentWeather: {
        inputType: 'optgroup',
        name: 'Present Weather (ww)',
        optgroups: {
            '00 to 03 — Characteristic change in the state of the sky during the past hour. No weather except clouds.': [
                [0, "00 — Cloud development not observed or not observable"],
                [1, "01 — Clouds generally dissolving or becoming less developed during the past hour"],
                [2, "02 — State of the sky as a whole unchanged during the past hour"],
                [3, "03 — Clouds generally forming or becoming more developed during the past hour"]
            ],
            '04 to 10 — Smoke, haze, dust, spray, mist': [
                [4, "04 — Visibility reduced by smoke, e.g. forest fire, industrial smoke, or volcanic ashes. Smoke from your own or other ships is not to be considered."],
                [5, "05 — Visibility reduced by haze"],
                [6, "06 — Visibility reduced by widespread dust in suspension in the air. "],
                [7, "07 — Blowing spray at the ship"],
                [8, "08 — Dust whirls. These will never be observed at sea."],
                [9, "09 — Dust storm or sandstorm observed during the past hour. This is not observed at sea except possibly in the Red Sea."],
                [10, "10 — Mist. Report this code figure only if mist is present and the visibility is 0.5 SM or more."]
            ],
            '11 to 13 - Shallow Fog': [
                [11, "11 — Patches of shallow fog at the ship as described above"],
                [12, "12 — More or less continuous shallow fog at the ship, as described above"],
                [13, "13 — Lightning seen at the time of observation, or within 15 min preceding the scheduled time of observation, but no thunder is heard."]
            ],
            '14 to 16 - Precipitation within sight but not occurring at the ship': [
                [14, "14 — Precipitation in sight but not reaching the sea surface. This phenomena, where the precipitation evaporates before reaching the surface, is called Virga."],
                [15, "15 — Precipitation within sight, that reaches the sea surface, and is more than 3 SMfrom the ship."],
                [16, "16 — Precipitation within sight, that reaches the sea surface, and is 3 SM or less from the ship, but not at the ship."]
            ],
            '17 to 19 - Precipitation heard or recently observed': [
                [17, "17 — Thunder heard at the time of observation, or within 15 min preceding the scheduled time of observation, but no precipitation at the ship."],
                [18, "18 — Squalls, occurring during the past hour or at the time of observation. Do not report this code figure if precipitation has occurred with the squall(s)."],
                [19, "19 — Waterspout(s) (called funnel cloud(s) on land) observed during the past hour or at the time of observation."]
            ],
            '20 to 29 — Precipitation, fog or thunderstorm': [
                [20, "20 — Drizzle (not freezing) or snow grains during the past hour."],
                [21, "21 — Rain (not freezing) during the past hour."],
                [22, "22 — Snow during the past hour."],
                [23, "23 — Rain and snow mixed during the past hour."],
                [24, "24 — Freezing drizzle or freezing rain during the past hour."],
                [25, "25 — Shower(s) of rain during the past hour."],
                [26, "26 — Shower(s) of snow, or of rain and snow mixed during the past hour."],
                [27, "27 — Shower(s) of hail, or of hail and snow mixed during the past hour. "],
                [28, "28 — Fog with visibility less than 0.5 SM, during the past hour."],
                [29, "29 — Thunderstorm, with or without precipitation, has occurred at the ship during the past hour, but neither thunder nor precipitation is occurring at the time of observation."]
            ],
            '30 to 39 — Duststorm, sandstorm, or drifting and blowing snow': [
                [30, "30 — Slight or moderate duststorm or sandstorm that has decreased during the past hour."],
                [31, "31 — Slight or moderate duststorm or sandstorm that has no appreciable change during the past hour."],
                [32, "32 — Slight or moderate duststorm or sandstorm that has increased during the past hour."],
                [33, "33 — Severe duststorm or sandstorm that has decreased during the past hour."],
                [34, "34 — Severe duststorm or sandstorm that has shown no appreciable change during the past hour."],
                [35, "35 — Severe duststorm or sandstorm that has increased during the past hour."],
                [36, "36 — Slight or moderate drifting snow. Visibility at eye level not affected."],
                [37, "37 — Heavy drifting snow. Visibility at eye level not affected."],
                [38, "38 — Slight or moderate blowing snow. Visibility 5/16 SM or more."],
                [39, "39 — Heavy blowing snow. Visibility less than 5/16 SM."]
            ],
            '40 to 49 — Fog at the time of observation': [
                [40, "40 — Fog bank at a distance at the time of observation, but not at the ship during the past hour. The fog extending to a level above that of the observer."],
                [41, "41 — Fog in patches."],
                [42, "42 — Fog, sky visible, has become thinner during the past hour."],
                [43, "43 — Fog, sky invisible, has become thinner during the past hour."],
                [44, "44 — Fog, sky visible, no appreciable change in density during the past hour."],
                [45, "45 — Fog, sky invisible, no appreciable change in density during the past hour."],
                [46, "46 — Fog, sky visible, has begun or become thicker during the past hour."],
                [47, "47 — Fog, sky invisible, has begun or become thicker during the past hour."],
                [48, "48 — Fog, depositing rime, sky visible."],
                [49, "49 — Fog, depositing rime, sky invisible."]
            ],
            '50 to 59 — Drizzle': [
                [50, "50 — Slight intermittent drizzle, not freezing."],
                [51, "51 — Slight continuous drizzle, not freezing."],
                [52, "52 — Moderate intermittent drizzle, not freezing."],
                [53, "53 — Moderate continuous drizzle, not freezing."],
                [54, "54 — Heavy intermittent drizzle, not freezing."],
                [55, "55 — Heavy continuous drizzle, not freezing."],
                [56, "56 — Slight drizzle, freezing."],
                [57, "57 — Moderate or heavy drizzle, freezing."],
                [58, "58 — Drizzle and rain mixed, both slight."],
                [59, "59 — Drizzle and rain mixed, either or both moderate or heavy."]
            ],
            '60 to 69 — Rain': [
                [60, "60 — Slight intermittent rain, not freezing."],
                [61, "61 — Slight continuous rain, not freezing."],
                [62, "62 — Moderate intermittent rain, not freezing."],
                [63, "63 — Moderate continuous rain, not freezing."],
                [64, "64 — Heavy intermittent rain, not freezing."],
                [65, "65 — Heavy continuous rain, not freezing."],
                [66, "66 — Slight rain, freezing."],
                [67, "67 — Moderate or heavy rain, freezing."],
                [68, "68 — Rain or drizzle with snow, both slight."],
                [69, "69 — Rain or drizzle with snow, either or both moderate or heavy."]
            ],
            '70 to 79 — Solid precipitation not in showers': [
                [70, "70 — Slight intermittent fall of snowflakes."],
                [71, "71 — Slight continuous fall of snowflakes."],
                [72, "72 — Moderate intermittent fall of snowflakes."],
                [73, "73 — Moderate continuous fall of snowflakes."],
                [74, "74 — Heavy intermittent fall of snowflakes."],
                [75, "75 — Heavy continuous fall of snowflakes."],
                [76, "76 — Diamond dust, with or without fog."],
                [77, "77 — Snow grains, with or without fog."],
                [78, "78 — Isolated star-like snow crystals, with or without fog."],
                [79, "79 — Ice pellets type (a)."]
            ],
            '80 to 90 — Precipitation in the form of showers, occurring at the time of observation': [
                [80, "80 — Slight rain shower."],
                [81, "81 — Moderate or heavy rain shower."],
                [82, "82 — Exceptionally heavy or torrential rain shower. Such showers occur mostly in tropical regions."],
                [83, "83 — Shower of rain and snow mixed, both slight."],
                [84, "84 — Shower of rain and snow mixed, either or both moderate or heavy."],
                [85, "85 — Slight snow shower."],
                [86, "86 — Moderate or heavy snow shower."],
                [87, "87 — Shower of slight snow pellets or light ice pellets type (b) with or without rain or snow."],
                [88, "88 — Shower of moderate or heavy snow pellets, or moderate or heavy ice pellets type (b), with or without rain or snow."],
                [89, "89 — Shower of hail, with or without rain or snow, and without thunder."],
                [90, "90 — Shower of moderate or heavy hail, with or without rain or snow, and without thunder."]
            ],
            '91 to 94 — Precipitation at the time of observation and thunder has been heard': [
                [91, "91 — Slight rain at the time of observation."],
                [92, "92 — Moderate or heavy rain."],
                [93, "93 — Slight snow, or rain and snow mixed or hail, or snow pellets, or ice pellets type (b). The precipitation type(s) are slight."]
            ],
            '95 to 99 — Thunderstorm with precipitation at the time of observation': [
                [95, "95 — Thunderstorm with rain and/or snow but without hail, snow pellets, or ice pellets at the time of observation."],
                [96, "96 — Thunderstorm with hail, snow pellets, or ice pellets type (b) at the time of observation. Rain or snow may also be occurring."],
                [97, "97 — Heavy thunderstorm, with rain and/or snow but without hail, snow pellets or ice pellets at the time of observation."],
                [98, "98 — Thunderstorm combined with duststorm or sandstorm at the time of observation (this occurrence is very unlikely at sea)."],
                [99, "99 — Heavy thunderstorm with hail, snow pellets, or ice pellets type (b) at the time of observation. Rain or snow may also be occurring."]
            ]
        },
        minValue: 0,
        maxValue: 99,
        rangeCheckLabel: '0 to 99.',
        displayPrecision: 0,
        required: true
    },
    manmarPastWeatherCodeOne: {
        inputType: 'select',
        name: 'Past Weather - (W1)',
        options: [
            [0, '0 - Cloud covering ½ or less of the sky throughout the appropriate period'],
            [1, '1 - Cloud covering more than ½ of the sky during part of the appropriate period and covering ½ or less during part of the period'],
            [2, '2 - Cloud covering more than ½ of the sky throughout the appropriate period'],
            [3, '3 - Sandstorm, duststorm or blowing snow (prevailing visibility less than 5/8 NM)'],
            [4, '4 - Fog, or freezing fog, or thick haze (prevailing visibility less than 5/8 NM)'],
            [5, '5 - Drizzle or freezing drizzle'],
            [6, '6 - Rain or freezing rain'],
            [7, '7 - Snow or rain and snow mixed (i.e., SN, RASN, SG, PL, IC)'],
            [8, '8 - Shower(s) (i.e., SHRA, SHSN, SHPL, SHGS, SHGR)'],
            [9, '9 - Thunderstorm(s) with or without precipitation'],
        ],
        required: true
    },
    manmarPastWeatherCodeTwo: {
        inputType: 'select',
        name: 'Past Weather - (W2)',
        options: [
            [0, '0 - Cloud covering ½ or less of the sky throughout the appropriate period'],
            [1, '1 - Cloud covering more than ½ of the sky during part of the appropriate period and covering ½ or less during part of the period'],
            [2, '2 - Cloud covering more than ½ of the sky throughout the appropriate period'],
            [3, '3 - Sandstorm, duststorm or blowing snow (prevailing visibility less than 5/8 NM)'],
            [4, '4 - Fog, or freezing fog, or thick haze (prevailing visibility less than 5/8 NM)'],
            [5, '5 - Drizzle or freezing drizzle'],
            [6, '6 - Rain or freezing rain'],
            [7, '7 - Snow or rain and snow mixed (i.e., SN, RASN, SG, PL, IC)'],
            [8, '8 - Shower(s) (i.e., SHRA, SHSN, SHPL, SHGS, SHGR)'],
            [9, '9 - Thunderstorm(s) with or without precipitation'],
        ],
        checks: {
            'W1 must be greater than W2': (manmarPastWeatherCodeTwo, observation) =>{
                const W1 = parseInt(observation.manmarPastWeatherCodeOne);
                const W2 = parseInt(manmarPastWeatherCodeTwo);
                if(W2 > W1){
                    return false;
                }
                else{
                    return true;
                }
            }
        },
        required: true
    },
    totalCloudCover: {
        inputType: 'select',
        name: 'Lowest Cloud Amount (Nh)',
        options: [
            ['0', '0 - 0 Oktas'],
            ['1', '1 - 1 Oktas'],
            ['2', '2 - 2 Oktas'],
            ['3', '3 - 3 Oktas'],
            ['4', '4 - 4 Oktas'],
            ['5', '5 - 5 Oktas'],
            ['6', '6 - 6 Oktas'],
            ['7', '7 - 7 Oktas'],
            ['8', '8 - 8 Oktas'],
            ['9', '9 - Sky obscured by fog and/or other meteorological phenomena'],
            ['/', '/ - Cloud cover is indiscernible for reasons other than fog or other meteorological phenomena, or observation is not made. '],
        ],
        required: true,
        crossParameterChecks: {
            'N must be greater than Nh': (totalCloudCover, observation) => {
                if ('cloudCover' in observation) {
                    const totalCloudCoverValue = totalCloudCover;
                    const cloudCoverValue = parseInt(observation.cloudCover);
                    if (!isNaN(totalCloudCoverValue) && !isNaN(cloudCoverValue)) {
                        return cloudCoverValue >= totalCloudCoverValue;
                    }
                    return true; // Assume that clouds are obscured
                }
                return true;
            }
        }
    },
    lowCloudType: {
        inputType: 'select',
        name: 'Low Cloud Type (CL)',
        options: [
            ['0', '0 - No Stratocumulus, Stratus, Cumulus, or Cumulonimbus'],
            ['1', '1 - Cumulus with little vertical extent and seemingly flattened or ragged Cumulus other than of bad weather or both'],
            ['2', '2 - Cumulus of moderate or strong vertical extent, generally with protuberances in form of domes or towers, either accompanied or not by other Cumulus or by Stratocumulus, all having their bases at the same level'],
            ['3', '3 - Cumulonimbus the summits of which, at least partially, lack sharp outlines, but are clearly fibrous(cirriform) nor in the form of an anvil. Cumulus, Stratocumulus or Stratus may also be present'],
            ['4', '4 - Stratocumulus formed by the spreading out of Cumulus. Cumulus may also be present'],
            ['5', '5 - Stratocumulus not formed from the spreading out of Cumulus'],
            ['6', '6 - Stratus in a more or less continuous sheet or layer, or in ragged shreds, or both; but no ragged Stratus of bad weather'],
            ['7', '7 - Ragged Stratus of bad weather or ragged Cumulus of bad weather, or both; usually occur below Altostratus or Nimbostratus'],
            ['8', '8 - Cumulus and Stratocumulus other than formed by the spreading out of Cumulus; the base of the Cumulus is at a different level from that of the Stratocumulus'],
            ['9', '9 - Cumulonimbus, the upper part of which is clearly fibrous (cirriform), often in the form of an anvil, either accompanied or not by Cumulonimbus without anvil or fibrous upper part, by Cumulus, Stratocumulus or Stratus'],
            ['/', '/ - Stratocumulus, Stratus, Cumulus, or Cumulonimbus are invisible owing to darkness, or cannot be seen(e.g.on an oil drilling rig at night due to glare of lights).']
        ],
        required: true
    },
    mediumCloudType: {
        inputType: 'select',
        name: 'Medium Cloud Type (CM)',
        options: [
            ['0', '0 - No Altocumulus, Altostratus, or Nimbostratus present'],
            ['1', '1 - Altostratus, the greater part of which is semi-transparent; through this part the sun or moon may be weakly visible as through ground glass '],
            ['2', '2 - Altostratus the greater part of which is sufficiently dense to hide the sun or moon or Nimbostratus'],
            ['3', '3 - Altocumulus the greater part of which is semi-transparent; the various elements of the cloud change only slowly and are all at a single level'],
            ['4', '4 - Patches (often in the form of almonds or fishes) of Altocumulus, the greater part of which are semi-transparent; the clouds appear at one or more levels and the elements are constantly changing in appearance'],
            ['5', '5 - Semi-transparent Altocumulus in bands; or Altocumulus in one or more fairly continuous layers(semi-transparent or opaque), progressively invading the sky; these Altocumulus clouds generally thicken as a whole'],
            ['6', '6 - Altocumulus resulting from the spreading out of Cumulus or Cumulonimbus'],
            ['7', '7 - Altocumulus in two or more layers, usually opaque in places, and not progressively invading the sky; or opaque layer of Altocumulus not progressively invading the sky; or Altocumulus together with Altostratus, or Nimbostratus'],
            ['8', '8 - Altocumulus with the sproutings of small towers or battlements, or Altocumulus having the appearance of cumulus - shaped tufts'],
            ['9', '9 - Altocumulus of a chaotic sky, generally at several levels '],
            ['/', '/ - Altocumulus, Altostratus or Nimbostratus are invisible owing to darkness, or cannot be seen']
        ],
        required: true
    },
    highCloudType: {
        inputType: 'select',
        name: 'High Cloud Type (CH)',
        options: [
            ['0', '0 - No Cirrus, Cirrocumulus or Cirrostratus present'],
            ['1', '1 - Cirrus in the form of filaments, strands or hooks; not progressively invading the sky'],
            ['2', '2 - Dense cirrus, in patches or entangled sheaves, which usually do not increase and sometimes seem to be the remains of the upper part of a Cumulonimbus; or Cirrus with sproutings in the form of small turrets or battlements, or Cirrus having the appearance of cumulus- shaped tufts'],
            ['3', '3 - Dense Cirrus, often in the form of an anvil, being the remains of the upper part of Cumulonimbus'],
            ['4', '4 - Cirrus in the form of hooks or filaments, or both, progressively invading the sky; they generally become denser as a whole'],
            ['5', '5 - Cirrus (often in bands converging towards one point or two opposite points of the horizon) and Cirrostratus; or Cirrostratus alone.In either case they are progressively invading the sky, and generally grow denser as a whole, but the continuous veil does not reach 45° above the horizon'],
            ['6', '6 - Cirrus and Cirrostratus; or Cirrostratus alone as in 5 above, except that the continuous veil extends more than 45° above the horizon without the sky being totally covered'],
            ['7', '7 - Veil of Cirrostratus covering the celestial dome completely'],
            ['8', '8 - Cirrostratus not progressively invading the sky and not completely covering the celestial dome '],
            ['9', '9 - Cirrocumulus alone; or Cirrocumulus accompanied by Cirrus or Cirrostratus or both, but Cirrocumulus is predominant'],
            ['/', '/ - Cirrus, Cirrocumulus, and Cirrostratus are invisible owing to darkness or cannot be seen']
        ],
        required: true
    },
    manmarWeather: {
        inputType: 'text',
        name: 'MANMAR Weather',
        required: true
    },
    stationPressure: {
        inputType: 'number',
        name: 'Station Pressure',
        minValue: 940,
        maxValue: 1060,
        checks: {
            '940.0 to 1060.0 hPa': rangeCheck.bind(this, 940, 1060),
        },
        parameterRelationships: {
            altimeter: (stationPressure, observation) => {
                const stationPressureValue = parseFloat(stationPressure);
                const stationHeightValue = parseFloat(observation.stationHeight);
                if (isNumber(stationPressureValue) && isNumber(stationHeightValue)) {
                    return 0.029530 * (stationPressureValue ** 0.19026 + 8.41710 * (10 ** -5) * stationHeightValue) ** 5.2559;
                }
                return;
            },
            meanSeaLevelPressure: (stationPressure, observation) => {
                let stationPressureValue = parseFloat(stationPressure);
                let stationHeightValue = parseFloat(observation.barometerHeight);
                let airTemperatureValue = parseFloat(observation.airTemperature);
                let airTemperatureTwelveHoursAgoValue = parseFloat(observation.airTemperatureTwelveHoursAgo);
                let latitudeValue = parseFloat(observation.latitude);
                if (isNumber(stationPressureValue) && isNumber(stationHeightValue) && isNumber(airTemperatureValue) && isNumber(latitudeValue)) {
                    return  calculateMeanSeaLevelPressure(
                        stationPressureValue,
                        airTemperatureValue,
                        airTemperatureTwelveHoursAgoValue,
                        stationHeightValue,
                        latitudeValue
                    );
                }
                return;
            },
            threeHourPressureChange: (stationPressure, observation) => {
                const currentPressureValue = parseFloat(stationPressure);
                if (observation.pressureThreehoursAgo === undefined){
                    return "///";
                }
                else{
                    const threeHourPressureValue = parseFloat(observation.pressureThreehoursAgo);
                    return (currentPressureValue - threeHourPressureValue);
                }
            },
            observerRemarks: (stationPressure, observation) => {
                let currentPressureValue = parseFloat(stationPressure);
                let latestPressureValue = parseFloat(observation.pressureLatestObs);
                let rateChange = ((currentPressureValue - latestPressureValue)/ observation.hoursRate);
                var temp =  observation.observerRemarks;
                if(temp){
                    temp = temp.replace(/(PRESRR |PRESFR )/, "");
                }
                else{
                    temp = "";
                }
                if (rateChange > 0 && Math.abs(rateChange) >= 2) {
            
                    return "PRESRR " + temp;
                }
                else if (rateChange < 0 && Math.abs(rateChange) >= 2){
                    return "PRESFR " + temp;
                }
                else{
                    return temp;
                }
            }
        },
        displayPrecision: 1,
        required: true
    },
    meanSeaLevelPressure: {
        inputType: 'number',
        name: 'Mean Sea Level Pressure',
        parameterRelationships: {
            relativeHumidity: (meanSeaLevelPressure, observation) => {
                const airTemperatureValue = parseFloat(observation.airTemperature);
                const wetbulbTemperatureValue = parseFloat(observation.wetbulbTemperature);
                const meanSeaLevelPressureValue = parseFloat(meanSeaLevelPressure);
                if (isNumber(airTemperatureValue) && isNumber(wetbulbTemperatureValue) && isNumber(meanSeaLevelPressureValue)) {
                    return calculateRelativeHumidity(airTemperatureValue, wetbulbTemperatureValue, meanSeaLevelPressureValue);
                }
            }
        },
        displayPrecision: 1,
        derived: true
    },
    altimeter: {
        inputType: 'number',
        name: 'Altimeter',
        displayPrecision: 2,
        derived: true
    },
    threeHourPressureChange: {
        inputType: 'text',
        name: 'Three Hour Pressure Change',
        minValue: 0,
        maxValue: 20,
        checks: {
            '0.0 to 20.0 hPa': (pressureChange, observation) => {
                //This is to satisfy the MANOBS requirement of returning  '///' when the three hour pressure is missing
                if (observation.pressureThreehoursago === undefined){
                    return true;
                }
                else{
                    if (pressureChange <= 0 && pressureChange >= 20){
                        return false;
                    }
                }
            },
            'Must be 0.0 if pressure tendency characteristic is 4': (pressureChange, observation) => {
                if ('threeHourPressureCharacter' in observation) {
                    const threeHourPressureCharacter = parseInt(observation.threeHourPressureCharacter);
                    return !(threeHourPressureCharacter === 4 && parseFloat(pressureChange) !== 0);
                }
                return true;
            },
            'Must be > 0.0 as pressure tendency characteristic indicates change': (pressureChange, observation) => {
                if ('threeHourPressureCharacter' in observation) {
                    const threeHourPressureCharacter = parseInt(observation.threeHourPressureCharacter);
                    return !(parseFloat(pressureChange) === 0 && (threeHourPressureCharacter !== 0 && threeHourPressureCharacter !== 4 && threeHourPressureCharacter !== 5));
                }
                return true;
            },
            'Must be 0.0 as previous pressure tendency characteristic was 4': (pressureChange, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('threeHourPressureCharacter')) { return true;}
                    const previousObservationCharacter = parseInt(latestPreviousObservation.threeHourPressureCharacter);
                    if (previousObservationCharacter === 4 && parseFloat(pressureChange) !== 0) { return false; }
                }
                return true;
            },
            'Must be > 0.0 as previous pressure tendency characteristic indicates a change': (pressureChange, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('threeHourPressureCharacter')) { return true; }
                    const previousObservationCharacter = parseInt(latestPreviousObservation.threeHourPressureCharacter);
                    const increasingCharacters = [1, 2, 3, 6, 7, 8];
                    if (increasingCharacters.includes(previousObservationCharacter) && parseFloat(pressureChange) === 0) { return false; }
                }
                return true;
            }

        },
        displayPrecision: 1,
        required: true
    },
    threeHourPressureCharacter: {
        inputType: 'select',
        name: 'Three Hour Pressure Character (a)',
        options: [
            [0, '0 - Increasing, then decreasing'],
            [1, '1 - Increasing then steady, or increasing then increasing more slowly'],
            [2, '2 - Increasing steadily or unsteadily'],
            [3, '3 - Decreasing or steady, then increasing; or increasing then increasing more rapidly'],
            [4, '4 - Steady'],
            [5, '5 - Decreasing, then increasing'],
            [6, '6 - Decreasing then steady, or decreasing then decreasing more slowly'],
            [7, '7 - Decreasing steadily or unsteadily'],
            [8, '8 - Steady or increasing then decreasing; or decreasing, then decreasing more rapidly'],
            ["/", '/ - Previous three hour pressure value missing']
        ],
        checks: {
            /* I asked Chris about removing these checks and he said for now we can remove it. However, if it causes any problems later, I can modify and add it back again.
            'Must be consisent with previous pressure tendency characteristic which is 4': (pressureCharacter, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('threeHourPressureCharacter')) { return true; }
                    const previousObservationCharacter = parseInt(latestPreviousObservation.threeHourPressureCharacter);
                    if (previousObservationCharacter === 4 && (parseInt(pressureCharacter) !== 0 && parseInt(pressureCharacter) !== 4 && parseInt(pressureCharacter) !== 5)) { return false; }
                }
                return true;
            },
            'Must be consisent with previous pressure tendency characteristic which indicates an increase': (pressureCharacter, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('threeHourPressureCharacter')) { return true; }
                    const previousObservationCharacter = parseInt(latestPreviousObservation.threeHourPressureCharacter);
                    const increasingCharacters = [0, 1, 2, 3];

                    if (increasingCharacters.includes(previousObservationCharacter) && !increasingCharacters.includes(parseInt(pressureCharacter))) { return false; }
                }
                return true;
            },
            'Must be consisent with previous pressure tendency characteristic which indicates a decrease': (pressureCharacter, observation, previousObservations) => {
                if (previousObservations.length != 0) {
                    const latestPreviousObservation = previousObservations[0];
                    if (!latestPreviousObservation.hasOwnProperty('threeHourPressureCharacter')) { return true; }
                    const previousObservationCharacter = parseInt(latestPreviousObservation.threeHourPressureCharacter);
                    const decreasingCharacters = [5,6,7,8]; 

                    if (decreasingCharacters.includes(previousObservationCharacter) && !decreasingCharacters.includes(parseInt(pressureCharacter))) { return false; }
                }
                return true;
            }*/
            'Invalid pressure character': (threeHourPressureCharacter,observation) => {
                let pressureChange = observation.threeHourPressureChange;
                if (pressureChange > 0){
                    if (threeHourPressureCharacter >= 0 && threeHourPressureCharacter <= 3){
                        return true;
                    }
                }
                if (pressureChange == 0){
                    if ((threeHourPressureCharacter >=4 && threeHourPressureCharacter <= 5) || threeHourPressureCharacter == 0){
                        return true;
                    }
                }
                else if (pressureChange < 0){
                    if (threeHourPressureCharacter >= 5 && threeHourPressureCharacter <= 8){
                        return true;
                    }
                }
                else if (pressureChange === "///"){
                    if (threeHourPressureCharacter === "/"){
                        return true;
                    }
                }
            },
        }, 
        required: true
    },
    significantWaveHeight: {
        inputType: 'number',
        name: 'Significant Wave Height',
        minValue: 0,
        maxValue: 25,
        checks: {
            '0.0 to 25.0 m': rangeCheck.bind(this, 0, 25)
        },
        parameterRelationships: {
            windWaveHeight: (significantWaveHeight, observation) => {
                const significantWaveHeightValue = parseFloat(significantWaveHeight);
                const primarySwellHeightValue = parseFloat(observation.primarySwellHeight);
                const secondarySwellHeightValue = parseFloat(observation.secondarySwellHeight);
                return calculateWindWaveHeight(significantWaveHeightValue, primarySwellHeightValue, secondarySwellHeightValue );
            }
        },
        displayPrecision: 1
    },
    maximumWaveHeight: {
        inputType: 'number',
        name: 'Maximum Wave Height',
        minValue: 0,
        maxValue: 25,
        checks: {
            '0.0 to 25.0 m': rangeCheck.bind(this, 0, 25)
        },
        displayPrecision: 1
    },
    meanPeriod: {
        inputType: 'number',
        name: 'Mean Wave Period',
        minValue: 0,
        maxValue: 20,
        checks: {
            '0 to 20 s': rangeCheck.bind(this, 0, 20)
        },
        displayPrecision: 0
    },
    windWavePeriod: {
        inputType: 'number',
        name: 'Wind Wave Period',
        minValue: 0,
        maxValue: 20,
        checks: {
            '0 to 20 s': rangeCheck.bind(this, 0, 20)
        },
        displayPrecision: 0,
        required: true
    },
    windWaveHeight: {
        inputType: 'number',
        name: 'Wind Wave Height',
        minValue: 0,
        maxValue: 25,
        checks: {
            'Negative wave height is invalid': (windWaveHeight, observation) => {
                let primarySwellHeightValue = observation.primarySwellHeight;
                let secondarySwellHeightValue = observation.secondarySwellHeight;
                let significantWaveHeightValue = observation.significantWaveHeight;
                let result = (Math.pow(significantWaveHeightValue,2)) - (Math.pow(primarySwellHeightValue,2)) - (Math.pow(secondarySwellHeightValue,2))
                if (result < 0){
                    return false;
                }
                else {
                    return true;
                }
            },
            '0.0 to 25.0 m': rangeCheck.bind(this, 0, 25),
        },
        displayPrecision: 1,
        required: true
    },
    primarySwellHeight: {
        inputType: 'number',
        name: 'Primary Swell Height',
        minValue: 0,
        maxValue: 25,
        checks: {
            '0.0 to 25.0 m': rangeCheck.bind(this, 0, 25),
            'Must input all primary swell elements': (primarySwellHeight, observation) => {
                const primarySwellHeightValue = parseFloat(primarySwellHeight);
                if (isNumber(primarySwellHeightValue)) {
                    return (
                        'primarySwellDirection' in observation && 'primarySwellPeriod' in observation &&
                        isNumber(parseInt(observation.primarySwellDirection)) && isNumber(parseInt(observation.primarySwellPeriod))
                    );
                }
                return true;
            }
        },
        parameterRelationships: {
            windWaveHeight: (primarySwellHeight, observation) => {
                const significantWaveHeightValue = parseFloat(observation.significantWaveHeight);
                const primarySwellHeightValue = parseFloat(primarySwellHeight);
                const secondarySwellHeightValue = parseFloat(observation.secondarySwellHeight);
                return calculateWindWaveHeight(significantWaveHeightValue, primarySwellHeightValue, secondarySwellHeightValue );
            }
        },
        displayPrecision: 1
    },
    primarySwellDirection: {
        inputType: 'text',
        name: 'Primary Swell Direction',
        minValue: 0,
        maxValue: 360,
        checks: {
            '000° to 360°': rangeCheck.bind(this, 0, 360),
            'Value must contain three digits': precisionCheck.bind(this, /^\d{3}$/),
            'Direction must be a multiple of 10': (value) => parseInt(value) % 10 === 0,
            'Must input all primary swell elements': (primarySwellDirection, observation) => {
                const primarySwellDirectionValue = parseFloat(primarySwellDirection);
                if (isNumber(primarySwellDirectionValue)) {
                    return (
                        'primarySwellHeight' in observation && 'primarySwellPeriod' in observation &&
                        isNumber(parseInt(observation.primarySwellHeight)) && isNumber(parseInt(observation.primarySwellPeriod))
                    );
                }
                return true;
            }
        }
    },
    primarySwellPeriod: {
        inputType: 'number',
        name: 'Primary Swell Period',
        minValue: 0,
        maxValue: 20,
        checks: {
            '0 to 20 s': rangeCheck.bind(this, 0, 20),
            'Must input all primary swell elements': (primarySwellPeriod, observation) => {
                const primarySwellPeriodValue = parseFloat(primarySwellPeriod);
                if (isNumber(primarySwellPeriodValue)) {
                    return (
                        'primarySwellHeight' in observation && 'primarySwellDirection' in observation &&
                        isNumber(parseInt(observation.primarySwellHeight)) && isNumber(parseInt(observation.primarySwellDirection))
                    );
                }
                return true;
            }
        },
        displayPrecision: 0
    },
    secondarySwellHeight: {
        inputType: 'number',
        name: 'Secondary Swell Height',
        minValue: 0,
        maxValue: 25,
        checks: {
            '0.0 to 25.0 m': rangeCheck.bind(this, 0, 25),
            'Secondary swell height must be lower then or equal to primary': (secondarySwellHeight, observation) => {
                if ('primarySwellHeight' in observation) {
                    const primarySwellHeightValue = parseFloat(observation.primarySwellHeight);
                    const secondarySwellHeightValue = parseFloat(secondarySwellHeight);
                    if (isNumber(primarySwellHeightValue) && isNumber(secondarySwellHeightValue)) {
                        return primarySwellHeightValue >= secondarySwellHeightValue;
                    }
                }
                return true;
            },
            'Must input all secondary swell elements': (secondarySwellHeight, observation) => {
                const secondarySwellHeightValue = parseFloat(secondarySwellHeight);
                if (isNumber(secondarySwellHeightValue)) {
                    return (
                        'secondarySwellPeriod' in observation && 'secondarySwellDirection' in observation &&
                        isNumber(parseInt(observation.secondarySwellPeriod)) && isNumber(parseInt(observation.secondarySwellPeriod))
                    );
                }
                return true;
            }
        },
        parameterRelationships: {
            windWaveHeight: (secondarySwellHeight, observation) => {
                const significantWaveHeightValue = parseFloat(observation.significantWaveHeight);
                const primarySwellHeightValue = parseFloat(observation.primarySwellHeight);
                const secondarySwellHeightValue = parseFloat(secondarySwellHeight);
                return calculateWindWaveHeight(significantWaveHeightValue, primarySwellHeightValue, secondarySwellHeightValue );
            }
        },
        displayPrecision: 1
    },
    secondarySwellDirection: {
        inputType: 'text',
        name: 'Secondary Swell Direction',
        minValue: 0,
        maxValue: 360,
        checks: {
            '000° to 360°': rangeCheck.bind(this, 0, 360),
            'Value must contain three digits': precisionCheck.bind(this, /^\d{3}$/),
            'Direction must be a multiple of 10': (value) => parseInt(value) % 10 === 0,
            'Must input all secondary swell elements': (secondarySwellDirection, observation) => {
                const secondarySwellDirectionValue = parseFloat(secondarySwellDirection);
                if (isNumber(secondarySwellDirectionValue)) {
                    return (
                        'secondarySwellPeriod' in observation && 'secondarySwellHeight' in observation &&
                        isNumber(parseInt(observation.secondarySwellPeriod)) && isNumber(parseInt(observation.secondarySwellHeight))
                    );
                }
                return true;
            }
        }
    },
    secondarySwellPeriod: {
        inputType: 'number',
        name: 'Secondary Swell Period',
        minValue: 0,
        maxValue: 20,
        checks: {
            '0 to 20 s': rangeCheck.bind(this, 0, 20),
            'Must input all secondary swell elements': (secondarySwellPeriod, observation) => {
                const secondarySwellPeriodValue = parseFloat(secondarySwellPeriod);
                if (isNumber(secondarySwellPeriodValue)) {
                    return (
                        'secondarySwellDirection' in observation && 'secondarySwellHeight' in observation &&
                        isNumber(parseInt(observation.secondarySwellDirection)) && isNumber(parseInt(observation.secondarySwellHeight))
                    );
                }
                return true;
            }
        }
    },
    iceAccretionType: {
        inputType: 'select',
        name: 'Ice Accretion Cause',
        options: [
            [1, '1 - Icing from ocean spray'],
            [2, '2 - Icing from fog'],
            [3, '3 - Icing from spray and fog'],
            [4, '4 - Icing from precipitation'],
            [5, '5 - Icing from spray and precipitation']
        ]
    },
    iceAccretionThickness: {
        inputType: 'select',
        name: 'Ice Accretion Thickness',
        options: [
            [0, '0 - Less than ¼'],
            [1, '1 - ¼ or ½'],
            [2, '2 - ¾'],
            [3, '3 - 1 or 1¼'],
            [4, '4 - 1½ or 1¾'],
            [5, '5 - 2'],
            [6, '6 - 2¼ or 2½'],
            [7, '7 - 2¾'],
            [8, '8 - 3 or 3¼'],
            [9, '9 - 3½'],
            [10, '10 - 3¾ or 4'],
            [11, '11 - 4¼ or 4½'],
            [12, '12 - 4¾'],
            [13, '13 - 5 or 5¼'],
            [14, '14 - 5½'],
            [15, '15 - 5¾ or 6'],
            [16, '16 - 6¼'],
            [17, '17 - 6½ or 6¾'],
            [18, '18 - 7 or 7¼'],
            [19, '19 - 7½'],
            [20, '20 - 7¾ or 8'],
            [21, '21 - 8¼'],
            [22, '22 - 8½ or 8¾'],
            [23, '23 - 9 or 9¼']
        ]
    },
    iceAccretionRate: {
        inputType: 'select',
        name: 'Ice Accretion Rate',
        options: [
            [1, '1 - Ice not building up'],
            [2, '2 - Ice building up slowly'],
            [3, '3 - Ice building up rapidly'],
            [4, '4 - Ice melting or breaking slowly'],
            [5, '5 - Ice melting or breaking rapidly']
        ]
    },
    seaIceConcentration: {
        inputType: 'select',
        name: 'Sea Ice Concentration',
        options: [
            ['0', '0 - No sea ice in sight'],
            ['1', '1 - Ship in open lead more than 1 NM wide, or ship in fast ice with boundary beyond limit of visibility'],
            ['2', '2 - Sea ice present in concentrations less than 3 / 10 (3 / 8 open water or very open pack ice'],
            ['3', '3 - 4/10 to 6/10 (3/8 to less than 6/8) open pack ice'],
            ['4', '4 - 7/10 to 8/10 (6/8 to less than 7/8) close pack ice'],
            ['5', '5 - 9/10 or more but not 10/10 (7/8 to less than 8/8) very close pack ice'],
            ['6', '6 - Strips and patches of pack ice with open water between'],
            ['7', '7 - Strips and patches of close, or very close pack ice with area of lesser concentrations between'],
            ['8', '8 - Fast ice with open water very open, or open pack ice to seaward of the ice boundary'],
            ['9', '9 - Fast ice with close or very close pack ice to seaward of the ice boundary'],
            ['/', '/ - Unable to report because of darkness lack of visibility, or because the ship is more than 0.5 NM away from the ice edge.']
        ]
    },
    seaIceStageOfDevelopment: {
        inputType: 'select',
        name: 'Sea Ice Stage of Development',
        options: [
            ['0', '0 - New ice only (frazil ice, grease ice, slush, shuga)'],
            ['1', '1 - Nilas or ice rind, less than 10 cm thick'],
            ['2', '2 - Young ice (gray ice, gray-white ice, 10–30 cm thick)'],
            ['3', '3 - Predominately new and/or young ice with some first-year ice'],
            ['4', '4 - Predominately thin first-year ice with some new and/or young ice'],
            ['5', '5 - All thin first-year ice (30–70 cm thick)'],
            ['6', '6 - Predominately medium first-year ice (70–120 cm thick) and thick-first year ice (more than 120 cm thick) with some thinner (younger) first-year ice'],
            ['7', '7 - All medium and thick first-year ice'],
            ['8', '8 - Predominately medium and thick first-year ice with some old ice (usually more than 2 m thick)'],
            ['9', '9 - Predominately old ice / Unable to report because of darkness, lack of visibility, or because only ice of land origin is visible, or because ship is more than 0.5 NM away from ice edge'],
            ['/', '/ - Unable to report because of darkness, lack of visibility, or because only ice of land origin is visible, or because ship is more than 0.5 NM away from ice edge']
        ]
    },
    seaIceOrigin: {
        inputType: 'select',
        name: 'Sea Ice Origin',
        options: [
            ['0', '0 - No ice of land origin'],
            ['1', '1 - 1–5 icebergs, no growlers or bergy bits'],
            ['2', '2 - 6-10 icebergs, no growlers or bergy bits'],
            ['3', '3 - 11-20 icebergs, no growlers or bergy bits'],
            ['4', '4 - Up to and including 10 growlers and bergy bits — no icebergs'],
            ['5', '5 - More than 10 growlers and bergy bits — no icebergs'],
            ['6', '6 - 1–5 icebergs with growlers and bergy bits'],
            ['7', '7 - 6-10 icebergs with growlers and bergy bits'],
            ['8', '8 - 11-20 icebergs with growlers and bergy bits'],
            ['9', '9 - More than 20 icebergs with growlers and bergy bits — a major hazard to navigation'],
            ['/', '/ - Unable to report because of darkness, lack of visibility, or because only sea ice is visible']
        ]
    },
    seaIceBearing: {
        inputType: 'select',
        name: 'Sea Ice Bearing',
        options: [
            ['0', '0 - Ship inshore of flaw lead'],
            ['1', '1 - Principal ice edge towards NE'],
            ['2', '2 - Principal ice edge towards E'],
            ['3', '3 - Principal ice edge towards SE'],
            ['4', '4 - Principal ice edge towards S'],
            ['5', '5 - Principal ice edge towards SW'],
            ['6', '6 - Principal ice edge towards W'],
            ['7', '7 - Principal ice edge towards NW'],
            ['8', '8 - Principal ice edge towards N'],
            ['9', '9 - Not determined (ship in ice)'],
            ['/', '/ - Unable to report because of darkness, lack of visibility, or because only ice of land origin is visible']
        ]
    },
    seaIceThreeHourSituation: {
        inputType: 'select',
        name: 'Sea Ice Situation',
        options: [
            ['0', '0 - Ship in open water with floating in sight'],
            ['1', '1 - Ship in easily penetrable ice, conditions improving'],
            ['2', '2 - Ship in easily penetrable ice, conditions not changing'],
            ['3', '3 - Ship in easily penetrable ice, conditions worsening'],
            ['4', '4 - Ship in ice difficult to penetrate, conditions improving'],
            ['5', '5 - Ship in ice difficult to penetrate, conditions not changing'],
            ['6', '6 - Ice forming and floes freezing together'],
            ['7', '7 - Ice under slight pressure'],
            ['8', '8 - Ice under moderate or severe pressure'],
            ['9', '9 - Ship beset'],
            ['/', '/ - Unable to report because of darkness or lack of visibility']
        ]
    },
    nationality: {
        inputType: 'text',
        name : 'Nationality (XX)',
    },
    source: {
        inputType: 'number',
        name : 'Source (N)',
    },
    callsign: {
        inputType: 'text',
        name : 'Call Sign (CCCC)',
    },
    platformIdentifier : {
        inputType: 'text',
        name : 'Platform Identifier (PPPP)',
        required: true
    },
    platformType: {
        inputType: 'select',
        name: 'Platform Type (Pt)',
        options: [
            [1, '1- Fixed wing aircraft '],
            [2, '2- Helicopter'],
            [3, '3- Icebreaker including helicopter '],
            [4, '4- Other ship'],
            [5, '5- Oil rig '],
            [6, '6- Shore station '],
            [7, '7- Satellite ']
        ],
        required: true
    },
    consecutiveIcebergNumber: {
        inputType: 'number',
        name : 'Consecutive Iceberg Number (NrNrNrNr)',
        required: true
    },
    dayOfMonth: {
        inputType: 'number',
        name : 'Day Of Month (YY)',
    },
    monthOfYear: {
        inputType: 'number',
        name : 'Month Of Year (MM)',
    },
    lastDigitOfYear: {
        inputType: 'number',
        name : 'Last Digit Of Year (JJ)',
    },
    callsignMeta: {
        inputType: 'text',
        name : 'Call Sign (SSSS)'   
    },
    icebergNumber: {
        inputType: 'number',
        name : 'Iceberg Number (IdIdIdId)',   
        checks: {
            'Incorrect Format': (icebergNumber, observation) => { 
                if(digitsCount(icebergNumber) > 4){
                    return false;
                }
                else{
                    return true;
                }
            },
        }, 
    },
    icebergMobility: {
        inputType: 'select',
        name: 'Iceberg Mobility (I)',
        options: [
                ['D', 'D- Drifting '],
                ['G', 'G- Grounded '],
                ['T', 'T- Under Tow '],
            ],
    },
    icebergObservation: {
        inputType: 'select',
        name: 'Method Of Observation (CI)',
        options: [
            [1, '1- Radar position with visual confirmation '],
            [2, '2- Radar (Side-Looking Airborne Radar/Forward-Looking Airborne Radar) only  '],
            [3, '3- Visual only '],
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
            [6, '6- Satellite- High Confidence'],
            [7, '7- Satellite- Medium Confidence'],
            [8, '8- Satellite- Low Confidence']
        ],
        required: true
    },
    timeInHoursGG: {
        inputType: 'text',
        name : 'Time in Hours (GG)'
    },
    timeInHoursgg: {
        inputType: 'text',
        name : 'Time in Minutes (gg)'
    },
    icebergLatitude: {
        inputType: 'text',
        name: 'Iceberg Latitude (LaLaLaLaLa) (DDMM.M)',
        checks: {
            'Incorrect Format': (icebergLatitude, observation) => { 
                if (icebergLatitude.length !== 6 ){
                    return false;
                }
                else{
                    return true;
                }
            },
        },
        required: true
    },
    icebergLongitude: {
        inputType: 'text',
        name: 'Iceberg Longitude (LoLoLoLoLo) (DDMM.M)',
        checks: {
            'Incorrect Format': (icebergLongitude, observation) => { 
                if (icebergLongitude.length !== 6 ){
                    return false;
                }
                else{
                    return true;
                }
            },
        },
        required: true
    },
    concentration: {
        inputType: 'select',
        name: 'Concentration of Sea Ice (Ci)',
        options: [
                [0, '0- No Sea Ice '],
                ['/', '/- Trace of Sea Ice '],
                [1, '1- 1/10 '],
                [2, '2- 2/10 '],
                [3, '3- 3/10 '],
                [4, '4- 4/10 '],
                [5, '5- 5/10 '],
                [6, '6- 6/10 '],
                [7, '7- 7/10 '],
                [8, '8- 8/10 '],
                [9, '9- 9/10, 9+/10 or 10/10 '],
                ['X', 'X- Undetermined '],
            ],
            required: true
    },
    icebergSize: {
        inputType: 'select',
        name: 'Iceberg Size (Si)',
        options: [
                [1, '1- Growler '],
                [2, '2- Bergy Bit '],
                [3, '3- Small Iceberg '],
                [4, '4- Medium Iceberg '],
                [5, '5- Large Iceberg '],
                [6, '6- Very Large Iceberg '],
                [7, '7- Not Specified '],
                ['X', 'X- Radar Target']
            ],
            required: true
    },
    icebergShape: {
        inputType: 'select',
        name: 'Iceberg Shape (Sh)',
        options: [
            [1, '1- Tabular '],
            [2, '2- Non-Tabular '],
            [3, '3- Domed '],
            [4, '4- Pinnacled '],
            [5, '5- Wedged '],
            [6, '6- Drydocked '],
            [7, '7- Ice Island '],
            [0, '0- Not Specified'],
            ['X', 'X- Undetermined(Radar) ']
        ],
        required: true

    },
    icebergLength: {
        inputType: 'select',
        name: 'Length Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ],
    },
    length: {
        inputType: 'number',
        name: 'Length (m) (LEN)',
        checks: {
            'Needs to be three characters': (length, observation) => { 
                if(digitsCount(length) > 3){
                    return false;
                }
                return true;
            },
        },
    },
    icebergWidth: {
        inputType: 'select',
        name: 'Width Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ]
    },
    width: {
        inputType: 'number',
        name: 'Width (m) (WID)',
        checks: {
            'Needs to be three characters': (width, observation) => { 
                if(digitsCount(width) > 3){
                    return false;
                }
                return true;
            },
        }
    },
    icebergHeight: {
        inputType: 'select',
        name: 'Height Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ]
    },
    height: {
        inputType: 'number',
        name: 'Height (m) (HEI)',
        checks: {
            'Needs to be three characters': (height, observation) => { 
                if(digitsCount(height) > 3){
                    return false;
                }
                return true;
            },
        }
    },
    icebergDraft: {
        inputType: 'select',
        name: 'Draft Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ]
    },
    draft: {
        inputType: 'number',
        name: 'Draft (m) (DRA)',
        checks: {
            'Needs to be three characters': (draft, observation) => { 
                if(digitsCount(draft) > 3){
                    return false;
                }
                return true;
            },
        }
    },
    icebergDirection: {
        inputType: 'select',
        name: 'Direction Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ]
    },
    direction: {
        inputType: 'number',
        name: 'Direction (toward) (DIR)',
        checks: {
            'Needs to be three characters': (direction, observation) => { 
                if(digitsCount(direction) > 3){
                    return false;
                }
                return true;
            },
        }
    },
    icebergSpeed: {
        inputType: 'select',
        name: 'Speed Confidence Level (Cl)',
        options: [
            [4, '4- Measured (only used in iceberg dimension)'],
            [5, '5- Estimated (only used in iceberg dimension)'],
        ]
    },
    speed: {
        inputType: 'number',
        name: 'Speed (knots) (SPE)',
        checks: {
            'Needs to be three characters': (speed, observation) => { 
                if(digitsCount(speed) > 3){
                    return false;
                }
                return true;
            },
        }
    },
    maniceRemarks: {
        inputType: 'text',
        name: 'Remarks',
    },

};

export default observationParameters;