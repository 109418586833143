const metarReport = {
    name: 'METAR',
    numberOfPreviousReports: 6
};

const manmarReport = {
    name: 'MANMAR',
    numberOfPreviousReports: 8
};

const maniceReport = {
    name: 'MANICE',
    numberOfPreviousReports: 24
};

export default {
    all: [metarReport, manmarReport, maniceReport],
    metar: [metarReport],
    speci: [metarReport],
    manmar: [manmarReport],
    metarAndManmar: [metarReport, manmarReport],
    manice: [maniceReport]
};