import React, { Component } from 'react';
import { ObservationInput } from './ObservationInput';
import './ObservationEditor.css';

const parameterKeyHeaderMap = {
    winds: 'Wind',
    visibilityAndWeather: 'Visibility and Weather',
    skyCondition: 'Sky Condition',
    pressure: 'Pressure',
    temperatures: 'Temperature',
    waves: 'Waves',
    windWaves: 'Wind Waves',
    swells: 'Swells',
    significantAndMaxWaveHeight: 'Significant Wave Height',
    iceAccretion: 'Ice Accretion',
    seaIce: 'Sea Ice',
    icebergCharacteristics: 'Iceberg Characteristics',
    icebergHeader: 'Iceberg Header',
    individualObservation: 'Individual Observation',
    icebergMetaData: 'Iceberg Metadata',
    additionalRemarks: 'Additional Remarks'
};

export class ObservationEditorGroup extends Component {
    displayName = ObservationEditorGroup.name;

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="observation-editor__column" style={{"gridRow": `span ${Math.ceil(this.props.parameterGroup.length/2)}` }}>
                <h4>{parameterKeyHeaderMap[this.props.parameterKey]}</h4>
                {
                    this.props.parameterGroup.map((key) =>
                        <ObservationInput
                            key={key}
                            observation={this.props.observation}
                            parameter={key}
                            previousObservations={this.props.previousObservations}
                            location={this.props.location}
                            value={this.props.observation[key]}
                        />
                    )
                }
            </div>
        );
    }
}