function digits_count(n) {
    var count = 0;
    if (n >= 1) ++count;
  
    while (n / 10 >= 1) {
      n /= 10;
      ++count;
    }
  
    return count;
  }
const maniceSections = {
    metadata: {
        inputs: ['nationality','source'],
        encoder: (nationality,source) => {
            return `IB${nationality}${source}`;
        },
        decoder: (match) => {
            return {};
        }
    },
    callsign: {
        inputs: ['callsign'],
        encoder: (call) => {
            return `${call}`;
        },
        decoder: (match) =>{

        }
    },
    header:{
        inputs: ['dayOfMonth', 'timeInHoursGG', 'timeInHoursgg'],
        encoder: (dayOfMonth, timeInHoursGG, timeInHoursgg) => {
            const digits_GG = digits_count(timeInHoursGG);
            const digits_gg = digits_count(timeInHoursgg);
            const digits_YY = digits_count(dayOfMonth);
            if (digits_GG === 1 && digits_gg === 1){
                if(digits_YY === 1){
                    return `0${dayOfMonth}0${timeInHoursGG}0${timeInHoursgg}`;
                }
                else{
                    return `${dayOfMonth}0${timeInHoursGG}0${timeInHoursgg}`;
                }
            }
            if (digits_GG === 1){
                if(digits_YY === 1){
                    return `0${dayOfMonth}0${timeInHoursGG}${timeInHoursgg}`;
                }
                else{
                    return `${dayOfMonth}0${timeInHoursGG}${timeInHoursgg}`;
                }
            }
            if (digits_gg === 1){
                if(digits_YY === 1){
                    return `0${dayOfMonth}${timeInHoursGG}0${timeInHoursgg}`;
                }
                else{
                    return `${dayOfMonth}${timeInHoursGG}0${timeInHoursgg}`;
                }
            }
            if(digits_YY === 1){
                return `0${dayOfMonth}${timeInHoursGG}${timeInHoursgg}`;
            }
            else{
                return `${dayOfMonth}${timeInHoursGG}${timeInHoursgg}`;
            }
        },
        decoder: (match) =>{

        }
    },
    platformIdentifier: {
        inputs: ['platformIdentifier'],
        encoder: (platformIdentifier) => {
            if(!platformIdentifier){
                return `\n`+`PPPP`;
            }
            else{
                return `\n`+`${platformIdentifier}`;
            }
        },
        decoder: (match) =>{
            const observation = {};
            observation.platformIdentifier = match.groups.platformIdentifier;
            return observation;
        }
    },
    platformType: {
        inputs: ['platformType', 'consecutiveIcebergNumber'],
        encoder: (platformType, consecutiveIcebergNumber) => {
            if(platformType == null || !consecutiveIcebergNumber){
                return `PtNrNrNrNr`;
            }
            //to append 0's in front of PtNrNrNrNr when they do not fill the total digits criteria for the report.
            const digits = digits_count(consecutiveIcebergNumber);
            if(digits === 1){
                return `${platformType}000${consecutiveIcebergNumber}`;
            }
            if(digits === 2){
                return `${platformType}00${consecutiveIcebergNumber}`;
            }
            if(digits === 3){
                return `${platformType}0${consecutiveIcebergNumber}`;
            }
            return `${platformType}${consecutiveIcebergNumber}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.platformType){
                observation.platformType = match.groups.platformType;
            }
            observation.consecutiveIcebergNumber = match.groups.consecutiveIcebergNumber; 
            return observation;
        }
    },
    day: {
        inputs: ['dayOfMonth','monthOfYear', 'lastDigitOfYear'],
        encoder: (dayOfMonth, monthOfYear, lastDigitOfYear ) => {
            const day_digits = digits_count(dayOfMonth);
            const month_digits = digits_count(monthOfYear);
            if (day_digits === 1 && month_digits === 1){
                return `0${dayOfMonth}0${monthOfYear}${lastDigitOfYear}`;
            }
            if (day_digits === 1){
                return `0${dayOfMonth}${monthOfYear}${lastDigitOfYear}`;
            }
            if (month_digits === 1){
                return `${dayOfMonth}0${monthOfYear}${lastDigitOfYear}`;
            }
            return `${dayOfMonth}${monthOfYear}${lastDigitOfYear}`; 
        },
        decoder: (match) => {
            return {};
        }
    },
    const: {
        inputs: [],
        encoder: () => {
            return `\n`+`11111`;
        }
        ,
        decoder: (match) =>{

        }
    },
    callsignMeta: {
        inputs: ['callsignMeta'],
        encoder: (call) => {
            if(!call){
                return '\n';
            }
            return `\n${call}`;
        },
        decoder: (match) =>{

        }
    },
    icebergNumber : {
        inputs: ['icebergNumber','icebergMobility','callsignMeta'],
        encoder: (icebergNumber, icebergMobility,call) => {
            const digits = digits_count(icebergNumber);
            if(digits === 1){
                if (icebergMobility){
                    return `000${icebergNumber}${icebergMobility}`;
                }
                else{
                    return `000${icebergNumber}`;
                }    
            }
            if(digits === 2){
                if (icebergMobility){
                    return `00${icebergNumber}${icebergMobility}`;
                }
                else{
                    return `00${icebergNumber}`;
                }
            }
            if(digits === 3){
                if(icebergMobility){
                    return `0${icebergNumber}${icebergMobility}`;
                }
                else{
                    return `0${icebergNumber}`;
                }
            }
            if(!icebergNumber && !icebergMobility){
                return;
            }
            else{
                return `${icebergNumber}${icebergMobility}`;
            }
        }, 
        decoder: (match) =>{
            const observation ={};
            observation.icebergNumber = match.groups.icebergNumber;
            if(match.groups.icebergMobility){
                observation.icebergMobility = match.groups.icebergMobility;
            }
            return observation;
        }
    },
    observation: {
        inputs: ['icebergObservation', 'timeInHoursGG', 'timeInHoursgg'],
        encoder: (icebergObservation, timeInHoursGG, timeInHoursgg) => {
            if(!icebergObservation){
                return `CIGGgg`;
            }
            const digits_GG = digits_count(timeInHoursGG);
            const digits_gg = digits_count(timeInHoursgg);
            if (digits_GG === 1 && digits_gg === 1){
                return `${icebergObservation}0${timeInHoursGG}0${timeInHoursgg}`;
            }
            if (digits_GG === 1){
                return `${icebergObservation}0${timeInHoursGG}${timeInHoursgg}`;
            }
            if (digits_gg === 1){
                return `${icebergObservation}${timeInHoursGG}0${timeInHoursgg}`;
            }
            return `${icebergObservation}${timeInHoursGG}${timeInHoursgg}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergObservation){
                observation.icebergObservation = match.groups.icebergObservation;
            }
            observation.timeInHoursGG = match.groups.timeInHoursGG;
            observation.timeInHoursgg = match.groups.timeInHoursgg;
            return observation;
        }
    },
    latitude: {
        inputs: ['icebergLatitude'],
        encoder: (icebergLatitude) => {
            if(!icebergLatitude){
                return `LaLaLaLaLa`;
            }
            const latitudeString = icebergLatitude.toString();
            var array = latitudeString.split('.');
            var array_new = new Array();
            array_new = array;
            var x = parseInt(array_new[0]);
            var y = array_new[1];
            if ( y === undefined){
                y = 0;
            }
            else{
                y =  parseInt(array_new[1]); 
            }
            const x_digits = digits_count(x);
            const iceberg= (x * Math.pow(10, (4 - x_digits))).toString() + y.toString();
            return `${iceberg}`;
        },
        decoder: (match) =>{
            const observation = {};
            observation.icebergLatitude = match.groups.icebergLatitude;
            return observation;
        }
    },
    longitude: {
        inputs: ['icebergLongitude'],
        encoder: (icebergLongitude) => {
            if(!icebergLongitude){
                return `LoLoLoLoLo`;
            }
            const longitudeString = icebergLongitude.toString();
            var array = longitudeString.split('.');
            var array_new = new Array();
            array_new = array;
            var x = parseInt(array_new[0]);
            var y = array_new[1];
            if ( y === undefined){
                y = 0;
            }
            else{
                y =  parseInt(array_new[1]); 
            }
            const x_digits = digits_count(x);
            const iceberg_lon= (x * Math.pow(10, (4 - x_digits))).toString() + y.toString();
            return `${iceberg_lon}`;
        },
        decoder: (match) =>{
            const observation = {};
            observation.icebergLongitude = match.groups.icebergLongitude;
            return observation;
        }
    },
    concentration: {        
        inputs: ['concentration', 'icebergSize', 'icebergShape'],
        encoder: (concentration, icebergSize, icebergShape) => {
            if(!concentration || !icebergSize || !icebergShape){
                return `01CiSiSh`;
            }
            return `01${concentration}${icebergSize}${icebergShape}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.concentration){
                observation.concentration = match.groups.concentration;
            }
            if(match.groups.icebergSize){
                observation.icebergSize = match.groups.icebergSize;
            }
            if(match.groups.icebergShape){
                observation.icebergShape = match.groups.icebergShape;
            }
            return observation;
        }
    },
    length: {
        inputs: ['icebergLength', 'length'],
        encoder: (icebergLength, length) => {
            if(!icebergLength || !length){
                return '';
            }
            if(digits_count(length) === 1){
                return`1${icebergLength}00${length}`;
            }
            if(digits_count(length) === 2){
                return`1${icebergLength}0${length}`;
            }
            return`1${icebergLength}${length}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergLength){
                observation.icebergLength = match.groups.icebergLength;
            }
            observation.length = match.groups.length;
        }   
     },
    width: {
        inputs: ['icebergWidth', 'width'],
        encoder: (icebergWidth, width) => {
            if(!icebergWidth || !width){
                return '';
            }
            if(digits_count(width) === 1){
                return`2${icebergWidth}00${width}`;
            }
            if(digits_count(width) === 2){
                return`2${icebergWidth}0${width}`;
            }
            return`2${icebergWidth}${width}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergWidth){
                observation.icebergWidth = match.groups.icebergWidth;
            }
            observation.width = match.groups.width;
        }
    },
    height: {
        inputs: ['icebergHeight', 'height'],
        encoder: (icebergHeight, height) => {
            if(!icebergHeight || !height){
                return '';
            }
            if(digits_count(height) === 1){
                return`3${icebergHeight}00${height}`;
            }
            if(digits_count(height) === 2){
                return`3${icebergHeight}0${height}`;
            }
            return`3${icebergHeight}${height}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergHeight){
                observation.icebergHeight = match.groups.icebergHeight;
            }
            observation.height = match.groups.height;
        }
    },
    draft: {
        inputs: ['icebergDraft', 'draft'],
        encoder: (icebergDraft, draft) => {
            if(!icebergDraft || !draft){
                return '';
            }
            if(digits_count(draft) === 1){
                return`4${icebergDraft}00${draft}`;
            }
            if(digits_count(draft) === 2){
                return`4${icebergDraft}0${draft}`;
            }
            return`4${icebergDraft}${draft}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergDraft){
                observation.icebergDraft = match.groups.icebergDraft;
            }
            observation.draft = match.groups.draft;
        }
    },
    direction: {
        inputs: ['icebergDirection', 'direction'],
        encoder: (icebergDirection, direction) => {
            if(!icebergDirection || !direction){
                return '';
            }
            if(digits_count(direction) === 1){
                return`5${icebergDirection}00${direction}`;
            }
            if(digits_count(direction) === 2){
                return`5${icebergDirection}0${direction}`;
            }
            return`5${icebergDirection}${direction}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergDirection){
                observation.icebergDirection = match.groups.icebergDirection;
            }
            observation.direction = match.groups.direction;
        }
    },
    speed: {
        inputs: ['icebergSpeed', 'speed'],
        encoder: (icebergSpeed, speed) => {
            if(!icebergSpeed || !speed){
                return '';
            }
            if(digits_count(speed) === 1){
                return`6${icebergSpeed}00${speed}`;
            }
            if(digits_count(speed) === 2){
                return`6${icebergSpeed}0${speed}`;
            }
            return`6${icebergSpeed}${speed}`;
        },
        decoder: (match) =>{
            const observation = {};
            if(match.groups.icebergSpeed){
                observation.icebergSpeed = match.groups.icebergSpeed;
            }
            observation.speed = match.groups.speed;
        }
    },
    end: {
        inputs: ['maniceRemarks'],
        encoder: (maniceRemarks) => {
            if(maniceRemarks){
                return `\n`+`REMARKS\n ${maniceRemarks}`+`\n`+`END`;
            }
            return `\n`+`REMARKS`+`\n`+`END`;
        },
        decoder: (match) =>{

        }
    },
}

export default class ManiceEncoderDecoder {
    encode(observation) {
        let sections = [];
        for (const key in maniceSections) {
            const currentSection = maniceSections[key];
            if (currentSection.disabled) {
                continue;
            }
            let encoder = currentSection.encoder;
            for (const arg of currentSection.inputs) {
                encoder = encoder.bind(this, observation[arg]);
            }
            sections.push(encoder());
        }
        return sections.join(' ').replace(/  +/g, ' ').replace(/( \n |\n | \n)/g, '\n').trim();
    }

    decode(maniceString) {
        const maniceRegex = /(?<MessageStart>IB(?<Nationality>CN|US)(?<Source>\d)) (?<Station>[A-Z,0-9]{4,6}) (?<day_header>[0-9]{2}(?<hours_header>[0-9]{2})(?<minutes_header>[0-9]{2}))\n(?<platform>[A-Z,0-9]{4}) ((?<ptype>[0-9]{1,4})(?<consecutiveIcebergNumber>[0-9]{1,4})) (?<day>[0-9]{2}(?<month>[0-9]{2})(?<year>[0-9]{2}))\n(?<const>[1]{5})\n(?:(?<platformIdentifier>[A-Z,0-9]{4}) )?(?:(?<icebergNumber>[0-9]{4})(?<icebergMobility>[D,G,T]{1}) )?(?<method>[1-8]{1}(?<hours>[0-9]{2})(?<minutes>[0-9]{2})) (?<latitude>[0-9]{5}) (?<longitude>[0-9]{5}) (?<ice_const>[0,1]{2}(?<concentration>[0-9,\,X]{1})(?<size>[0-9,X]{1})(?<shape>[0-9,X]{1}))/;
        const match = maniceString.match(maniceRegex);
        let observations = {};
        for (const key in maniceSections) {
            const currentSection = maniceSections[key];
            if (currentSection.disabled) {
                continue;
            }
            const decoder = currentSection.decoder;
            observations = Object.assign({}, observations, decoder(match));
        }
        return observations;
    }
}